import { destroy, get } from "../../apiClient";

export const fetchPrebidAccount = async ({ queryKey }) => {
  let id = queryKey[1];

  try {
    const { data: prebid_account } = await get(
      "player",
      `/api/v1/prebid_accounts/${id}.json`
    );

    return prebid_account;
  } catch (error) {
    return null;
  }
};

export const deletePrebidAccount = async (id) => {
  try {
    return await destroy("player", `/api/v1/prebid_accounts/${id}`);
  } catch (error) {
    throw error;
  }
};
