import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox, FormControl, Grid, InputLabel,
  LinearProgress, Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useLoaderData, useParams } from "react-router-dom";
import {
  CONTENT_RATING,
  REJECTION_REASONS,
  TableSelect,
  URL_STATUS_PENDING,
  style,
  URL_STATUS_KEY,
  URL_CONTENT_RATING_ID_KEY,
  REJECTION_REASON_KEY,
  action,
  isUrlGoodToSave, fireUpdateEmail
} from "../../../utils/urlsTabsHelper";
import { get } from "../../../apiClient";
import UrlRow from "../../../utils/urlRow";
import TablePagination from "../../../components/TablePagination";

const DEFAULT_PAGINATION = { page: 0, perPage: 20 };
const DEFAULT_SEARCH_RESULT = { records: [], totalCount: 0, ...DEFAULT_PAGINATION };

const urlsTable = ({ campaignId,
                     page,
                     perPage,
                     ...filters }) => {
  return new Promise((resolve, reject) => {
    const params = {
      page,
      perPage,
      ...filters,
    };
    get("managed_plus", `/campaigns/${campaignId}/campaign_urls`, { params })
      .then(({ result, total_count }) => {
        resolve({ records: result, totalCount: total_count, page, perPage })
      })
      .catch(reject)
  });
}

const StyledTableRow = styled(TableRow)(({ theme  }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#1976D214"
  },
}));

const ErrorRow = ({ error }) => {
  return (
    <StyledTableRow>
      <TableCell colSpan={7} sx={{ textAlign: "center" }}>{error}</TableCell>
    </StyledTableRow>
  )
}

const UrlsTable = ({
  searchOptions = {}, refreshMethod, refreshFlag, enableEmailButtonMethod, enableEmailButtonVar,
  onForbiddenError
}) => {
  const [filters, setFilter] = useState({ ...DEFAULT_PAGINATION });
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState(DEFAULT_SEARCH_RESULT);
  const isPendingUrl = searchOptions.status === "pending";
  const { page, perPage, records, error, totalCount } = searchResult;
  const { id: campaignId } = useParams();
  const { uid, account_email, campaign_state } = useLoaderData();
  const [checkedList, setCheckedList] = useState([]);
  const [itemsChecked, setItemsChecked] = useState(false);
  const [openModalBulkEdit, setOpenModalBulkEdit] = useState(false);
  const [bulkEditParams , setBulkEditParams] = useState({});
  const [bulkSaveButtonEnabled, setBulkSaveButtonEnabled] = useState(false);
  const [justSentUpdateEmail, setJustSentUpdateEmail] = useState(false);
  const [emailButtonLabel, setEmailButtonLabel] = useState("SEND EMAIL UPDATE");
  const totalPages = Math.ceil(totalCount / perPage);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (page >= totalPages) {
      setFilter((prevFilters) => ({
        ...prevFilters,
        page: Math.max(totalPages - 1, 0),
      }));
    }
  }, [page, totalPages]);

   async function sendEmailUpdate() {
     try {
       await fireUpdateEmail(campaignId, account_email, uid);
       setJustSentUpdateEmail(true);
       setEmailButtonLabel("EMAIL UPDATE SENT");
     } catch (error) {
       if ("status" in error && error.status === 403) {
         onForbiddenError && onForbiddenError()
         return;
       }

       throw error
     }
  }

  const selectItem = (e) => {
    const { checked } = e.target;
    const collection = [];
    if (checked) {
      records.map(({ id }) => (
        collection.push(id * 1)
      ))
    }
    setCheckedList(collection);
    setItemsChecked(checked);
  };

  const updateURLBundle = async  () => {
    let filteredParams = { ...bulkEditParams };

    if (bulkEditParams[URL_STATUS_KEY] === "live") {
      const { rejection_status, ...paramsWithoutRejectionStatus } = bulkEditParams;
      filteredParams = paramsWithoutRejectionStatus;
    }
    let bulkEditList = [];

    checkedList.forEach(( id ) => {
      bulkEditList.push({
        ...filteredParams,
        id: id
      });
    });
    await action({
      urls_to_be_updated: JSON.stringify(bulkEditList),
      campaignId
    });
    setCheckedList([]);
    setBulkEditParams([]);
    handleClose();
    refreshMethod();
    setItemsChecked(false);
    setReload(true);
  }

  const bulkEditParameterUpdate = (parameter) => (e) => {
    const value = e;
    setBulkEditParams((prevParams) => {
      const updatedParams = {
        ...prevParams,
        [parameter]: value
      };
      if (parameter === URL_STATUS_KEY && value !== "rejected") {
        updatedParams[REJECTION_REASON_KEY] = "";
      }
      setBulkSaveButtonEnabled(isUrlGoodToSave(updatedParams));
      return updatedParams;
    });
  }

  const updateURL = async (urlToEdit) => {
    let params = {
      id: urlToEdit.id,
      url_status_id: urlToEdit.status,
      url_content_rating_id: urlToEdit.url_content_rating_id
    };

    if (urlToEdit.status === "rejected") {
      params = {
        ...params,
        rejection_reason: urlToEdit.rejection_reason
      };
    }
    await action({
      urls_to_be_updated: JSON.stringify([params]),
      campaignId, campaign_state
    });
    setCheckedList(checkedList.filter((item) => item !== urlToEdit.id));
    if(isPendingUrl) refreshMethod();
    setReload(true);
  }

  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedList([...checkedList, value * 1]);
    } else {
      setCheckedList(checkedList.filter((item) => item !== value * 1));
    }
  };

  useEffect(() => {
    if (!isPendingUrl)
      setReload(true);
  },  [isPendingUrl, reload, refreshFlag]);

  useEffect(() => {
    setIsLoading(true);
    if (reload) {
      urlsTable({ campaignId, ...searchOptions, ...filters })
        .then((result) => {
          setSearchResult(result);
          if (isPendingUrl)
            enableEmailButtonMethod(result.totalCount > 0);
        })
        .catch((error) => {
          if (error.status === 403) {
            onForbiddenError && onForbiddenError()
          }

          setSearchResult({
            error: "An error occurred while fetching the data. Please, contact support if the error is persistent."
          });
        });
    }
    setIsLoading(false)
    setReload(false);
    return () => {
    }
  },  [campaignId, filters, searchOptions, refreshFlag, isPendingUrl, enableEmailButtonMethod, reload, onForbiddenError]);

  function handleClose() {
    setOpenModalBulkEdit(false);
  }

  return (
    <>
      <Typography variant="h6">
        {isPendingUrl ?
          "Pending URLs" :
          "Reviewed URLs"
        }</Typography>
      <Grid container sx={{p: 2}}>
        {isPendingUrl ?
          <Button variant="contained" disabled={checkedList.length < 2} onClick={(e) => setOpenModalBulkEdit(true)}>BULK EDIT</Button> :
          <Button variant="contained" disabled={enableEmailButtonVar || justSentUpdateEmail} onClick={(e) => sendEmailUpdate()}>{emailButtonLabel}</Button>
        }
      </Grid>
      <TableContainer>
        {isLoading && <LinearProgress/>}
        <Table size="small">
          <TableHead>
            <TableRow>
              { isPendingUrl & totalCount > 0 ?
              <TableCell  style={{ width: 20 }}
                          align="left">
                <Checkbox style={{ width: 20, height: 20 }}
                          checked={itemsChecked}
                          indeterminate={checkedList.length > 0 && checkedList.length < records.length}
                          onClick={selectItem.bind(this)} />
                            </TableCell> : null
              }
              <TableCell>URL</TableCell>
              <TableCell style={{width: 103, height: 36, padding: "6px 10px" }}>URL Type</TableCell>
              <TableCell style={{width: 103, padding: "6px 10px" }}>Status</TableCell>
              <TableCell style={{width: 322, padding: "6px 16px 6px 10px" }}>{isPendingUrl ? "Content Rating" : "Content Category"}</TableCell>
              <TableCell style={{width: 158, padding: "6px 24px 6px 10px" }}>Update Status</TableCell>
              <TableCell style={{width: 240, padding: "6px 10px" }}>Rejection Reason</TableCell>
              <TableCell style={{width: 57, padding: "6px 10px" }} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              error ?
                <ErrorRow error={error}/> :
                records.map((item) => (
                  <UrlRow onCheck={handleCheckboxClick}
                          onSave={updateURL}
                          checked={checkedList.includes(item.id * 1)}
                          checkable={item.status==="pending"}
                          loading={isLoading}
                          urlInfo={item} key={item.id}/>))}
          </TableBody>
        </Table>
        {!error && (
          <TablePagination
            totalCount={totalCount}
            filters={filters}
            setFilter={setFilter}
          />
        )}
      </TableContainer>
      <div>
        <Modal
          open={openModalBulkEdit}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Grid>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Bulk Edit
              </Typography>
              <Grid item xs={12}>&nbsp;</Grid>
                <Grid container spacing={2} justifyContent="justify-around">
                  <Grid item xs={6} justifyContent="space-around">
                    <FormControl fullWidth>
                      <InputLabel id="content-rating-select-label">Content Rating</InputLabel>
                      <TableSelect items={CONTENT_RATING} style={{width: 220, height: 40}}
                                   onChange={bulkEditParameterUpdate(URL_CONTENT_RATING_ID_KEY)}
                                   key={URL_CONTENT_RATING_ID_KEY}
                                   label={"Content Rating"}/>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} justifyContent="space-around" >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Status</InputLabel>
                      <TableSelect items={URL_STATUS_PENDING} style={{width: 217, height: 40}}
                                   sx={{ width: "100%" }}
                                   onChange={bulkEditParameterUpdate(URL_STATUS_KEY)}
                                   key={"url_status"}
                                   label={"Status"}/>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="space-around">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Rejection Reason</InputLabel>
                      <TableSelect items={REJECTION_REASONS} style={{width: 481, height: 40}}
                                   disabled={(bulkEditParams[URL_STATUS_KEY] !== "rejected")}
                                   onChange={bulkEditParameterUpdate(REJECTION_REASON_KEY)}
                                   startingValue={bulkEditParams[URL_STATUS_KEY] !== "rejected" ? "" : null}
                                   clearField={true}
                                   label={"Rejection Reason"}/>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button variant="text" disabled={!bulkSaveButtonEnabled} onClick={updateURLBundle}>Save</Button>
                    </Grid>
                    <Grid item>
                      <Button variant="text" onClick={handleClose}>Cancel</Button>
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    </>
  )
}

export default UrlsTable;
