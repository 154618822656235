import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";

const TableHeadCell = ({
  onSortOrderChange: handleOnSortOrderChange,
  name,
  orderDirection,
  orderBy,
  title,
  helpTooltip,
}) => {
  const isActive = orderBy === name;
  return (
    <TableCell sortDirection={orderDirection}>
      <TableSortLabel
        active={isActive}
        direction={orderDirection === "asc" || !isActive ? "asc" : "desc"}
        onClick={(event) =>
          handleOnSortOrderChange(event, {
            orderBy: name,
            orderDirection:
              orderDirection === "desc" || !isActive ? "asc" : "desc",
          })
        }
      >
        <Box>
          {title}
          {helpTooltip && (
            <Tooltip title={helpTooltip}>
              <sup>
                <HelpOutline sx={{ fontSize: "18px" }} />
              </sup>
            </Tooltip>
          )}
        </Box>
        <Box component="span" sx={visuallyHidden}>
          {orderDirection === "asc" || !isActive
            ? "sorted ascending"
            : "sorted descending"}
        </Box>
      </TableSortLabel>
    </TableCell>
  );
};

TableHeadCell.propTypes = {
  onSortOrderChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  orderDirection: PropTypes.oneOf(["asc", "desc"]).isRequired,
  title: PropTypes.string.isRequired,
  helpTooltip: PropTypes.string,
};

export default TableHeadCell;
