import {  TablePagination as MuiTablePagination } from "@mui/material";

const TablePagination = ({ totalCount, filters, setFilter }) => {

  const handlePageChange = (_event, newPage) => {
    setFilter((prevFilters) => ({ ...prevFilters, page: newPage }));
  };

  const handleRowsPerPageChange = (event) => {
    const newPerPage = parseInt(event.target.value, 10);
    const newTotalPages = Math.ceil(totalCount / newPerPage);

    setFilter((prevFilters) => {
      const newPage = prevFilters.page >= newTotalPages ? Math.max(newTotalPages - 1, 0) : prevFilters.page;
      return { ...prevFilters, page: newPage, perPage: newPerPage };
    });
  };

  return (
    <MuiTablePagination
      component="div"
      count={totalCount}
      page={filters.page}
      rowsPerPage={filters.perPage}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      rowsPerPageOptions={[10, 20, 50]}
    />
  );
};

export default TablePagination;
