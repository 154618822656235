import { Autocomplete as MUIAutocomplete, Checkbox } from "@mui/material";

import { CheckBox, CheckBoxOutlineBlank, Clear } from "@mui/icons-material";

import { useEffect, useRef, useState } from "react";

const SELECT_ALL_OPTION = { id: "select-all", label: "Select All" };

const getUnselectedOptions = (target, currentOptions) =>
  Array.from(
    target
      .closest("ul")
      .querySelectorAll("[aria-selected='false']:not([data-option-index='0'])")
  ).map((item) =>
    currentOptions.at(Number(item.getAttribute("data-option-index")))
  );

const filterOptions = (selectedOptions, currentOptions) => {
  const idsToFilter = currentOptions.map(({ id }) => id);
  return selectedOptions.filter((option) => !idsToFilter.includes(option.id));
};

const handleSelectAll = (callback, selectAll, target, currentOptions) => {
  if (selectAll) {
    callback((previous) => [
      ...previous,
      ...getUnselectedOptions(target, currentOptions),
    ]);
  } else {
    callback((previous) => filterOptions(previous, currentOptions));
  }
};

const hasUnselectedOptions = (target, currentOptions) => {
  const index = Number(target.getAttribute("data-option-index"));

  const itemsAvailable = target
    .closest("ul")
    .querySelectorAll(
      `[aria-selected='false']:not([data-option-index='0']):not([data-option-index='${index}'])`
    );

  return itemsAvailable.length === 0;
};

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;
const deleteIcon = (
  <Clear fontSize="small" variant="filled" sx={{ color: "#fff !important" }} />
);

const DEFAULT_CHIP_PROPS = {
  sx: { background: "#64B5C5" },
  deleteIcon: deleteIcon,
  variant: "filled",
  color: "primary",
};

const DEFAULT_CHECKBOX_PROPS = { icon, checkedIcon, style: { marginRight: 8 } };

const isAllOptionsSelected = (values, newOptions) => {
  for (let value of values) {
    if (
      value.id !== "select-all" &&
      !newOptions.find(({ id }) => id === value.id)
    ) {
      return false;
    }
  }

  return values.length > 0;
};

const Autocomplete = ({
  defaultValue,
  getServerOptions,
  onValueChange,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [value, setValue] = useState(defaultValue ?? []);
  const optionsRef = useRef([]);
  const valueRef = useRef(defaultValue ?? []);

  const setNewOptions = (options) => {
    setSelectAll(isAllOptionsSelected(options, valueRef.current));
    setOptions(options);
    optionsRef.current = options;
  };

  const handleValueChange = (argOrFn) => {
    setValue((value) => {
      const newValue = typeof argOrFn === "function" ? argOrFn(value) : argOrFn;

      valueRef.current = newValue;
      onValueChange && onValueChange(newValue);

      return newValue;
    });
  };

  useEffect(() => {
    if (open && getServerOptions) {
      setLoading(true);
      getServerOptions(searchTerm)
        .then((result) => {
          const options =
            result.length > 0 ? [SELECT_ALL_OPTION, ...result] : [];
          setNewOptions(options);
        })
        .finally(() => setLoading(false));
    } else {
      setNewOptions([]);
      setLoading(false);
    }
  }, [open, searchTerm, getServerOptions]);

  const handleSelect = (event, newValue, reason, { option }) => {
    const target = event.currentTarget;
    if (option.id === "select-all") {
      handleSelectAll(
        handleValueChange,
        !selectAll,
        target,
        optionsRef.current
      );
      setSelectAll((prev) => !prev);
    } else {
      const isAllSelected =
        reason === "selectOption" &&
        hasUnselectedOptions(target, optionsRef.current);
      handleValueChange(newValue);
      setSelectAll(isAllSelected);
    }
  };
  const handleClear = (...args) => {
    handleValueChange([]);
    setSelectAll(false);
  };

  return (
    <MUIAutocomplete
      options={options}
      open={open}
      loading={isLoading}
      value={value}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disableCloseOnSelect
      filterOptions={(v) => v}
      multiple
      onChange={(event, newValue, reason, details) => {
        const handler = reason === "clear" ? handleClear : handleSelect;
        handler(event, newValue, reason, details);
      }}
      freeSolo
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => {
        const isSelectAll = option.id === "select-all";
        const listProps = isSelectAll ? { "aria-selected": selectAll } : {};

        return (
          <li {...props} {...listProps}>
            <Checkbox
              {...DEFAULT_CHECKBOX_PROPS}
              checked={isSelectAll ? selectAll : selected}
            />
            {option.label}
          </li>
        );
      }}
      inputValue={searchTerm}
      onInputChange={(_event, value, reason) => {
        setSearchTerm(value);
      }}
      ChipProps={DEFAULT_CHIP_PROPS}
      {...props}
    />
  );
};

export default Autocomplete;
