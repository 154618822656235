import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  Typography
} from "@mui/material";

import { NumericFormat } from "react-number-format";

import DeleteIcon from "@mui/icons-material/Delete";

import { listify } from "radash";

import TextField from "./TextField"

import { useOptimizationChangesDispatch } from "../OptimizationsContext";

import OptimizationFieldGroup from "./OptimizationFieldGroup";

import {
  USER_FREQUENCY_CAP_UNIT_OPTIONS,
  RULE_PRIORITIES_OPTIONS,
  FREQUENCY_CAP_SECTION,
  COMPLETION_CAP_SECTION,
  SLOW_DOWN_CAP_SECTION,
  formFieldBuilder
} from "../utils/optimizationFormUtils.js";

const FrequencyCapField = ({ rule, errors = {}, currentPriorities }) => {
  const dispatch = useOptimizationChangesDispatch();
  const { priority, value = { unit: "", value: "" }, editable } = rule;

  const handleRemove = (_event) =>
    dispatch({ action: "rule_removed", section: FREQUENCY_CAP_SECTION, ...rule });

  const handleUpdate = (value) =>
    dispatch({ action: "rule_updated", section: FREQUENCY_CAP_SECTION, ...rule, ...value });

  return (
    <Grid
      container
      alignItems={"center"}
      direction="row"
      justifyContent={"space-between"}
      columnSpacing={2}
      sx={{ mb: 2 }}
    >
      <Grid item xs={4}>
        <TextField
          select
          label="Priority"
          value={priority}
          disabled={!editable}
          fullWidth
          error={"priority" in errors}
          helperText={errors.priority?.join(", ") ?? ""}
          size="small"
          onChange={(event) => handleUpdate({ priority: event.target.value })}
        >
        <MenuItem value="">Select...</MenuItem>
          {(editable ? RULE_PRIORITIES_OPTIONS : [priority]).map(value => <MenuItem key={value} value={value} disabled={currentPriorities.includes(value)}>{value}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item xs={5} container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={5}>
          <Typography component="div" variant="body2">
            Show ad every
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <NumericFormat
            customInput={TextField}
            label="Cap"
            variant="standard"
            size="small"
            disabled={!editable}
            error={"value" in errors}
            thousandSeparator
            helperText={errors.value?.join(", ") ?? ""}
            value={value.value}
            onValueChange={(values, _sourceInfo) => handleUpdate({ value: { ...value, value: values.value } })}
          />
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <TextField
          select
          label="Unit"
          value={value.unit}
          disabled={!editable}
          size="small"
          fullWidth
          error={"unit" in errors}
          helperText={errors.unit?.join(", ") ?? ""}
          onChange={(event) => handleUpdate({ value: { ...value, unit: event.target.value } })}
        >
          <MenuItem value="">Select...</MenuItem>
          {USER_FREQUENCY_CAP_UNIT_OPTIONS.map(({ label, value })=> <MenuItem key={value} value={value}>{label}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item xs={1}>
        {editable && (
          <IconButton aria-label="delete" onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}

const CompletionCapField = ({ rule, errors = {}, currentPriorities }) => {
  const dispatch = useOptimizationChangesDispatch();
  const { priority, value = { offer_max_completions_per_user: "", offer_max_daily_completions_per_user: "" }, editable } = rule;

  const handleRemove = (_event) =>
    dispatch({ action: "rule_removed", section: COMPLETION_CAP_SECTION, ...rule });

  const handleUpdate = (value) =>
    dispatch({ action: "rule_updated", section: COMPLETION_CAP_SECTION, ...rule, ...value });

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      columnSpacing={2}
      sx={{ mb: 2 }}
    >
      <Grid item xs={4}>
        <TextField
          select
          label="Priority"
          value={priority}
          disabled={!editable}
          fullWidth
          size="small"
          error={"priority" in errors}
          helperText={errors.priority?.join(", ") ?? ""}
          onChange={(event) => handleUpdate({ priority: event.target.value })}
        >
        <MenuItem value="">Select...</MenuItem>
          {(editable ? RULE_PRIORITIES_OPTIONS : [priority]).map(value => <MenuItem key={value} value={value} disabled={currentPriorities.includes(value)}>{value}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item container xs={7} spacing={2} direction="row">
        <Grid item xs={6}>
          <NumericFormat
            customInput={TextField}
            label="Max Completions per User"
            variant="standard"
            value={value.offer_max_completions_per_user}
            fullWidth
            size="small"
            disabled={!editable}
            error={"offer_max_completions_per_user" in errors}
            helperText={errors.offer_max_completions_per_user?.join(", ") ?? ""}
            thousandSeparator
            onValueChange={(values, _sourceInfo) => handleUpdate({ value: { ...value, offer_max_completions_per_user: values.value } })}
          />
        </Grid>
        <Grid item xs={6}>
          <NumericFormat
            customInput={TextField}
            thousandSeparator
            label="Max Daily Completions per User"
            variant="standard"
            value={value.offer_max_daily_completions_per_user}
            fullWidth
            size="small"
            disabled={!editable}
            error={"offer_max_daily_completions_per_user" in errors}
            helperText={errors.offer_max_daily_completions_per_user?.join(", ") ?? ""}
            onValueChange={(values, _sourceInfo) => handleUpdate({ value: { ...value, offer_max_daily_completions_per_user: values.value } })}
          />
        </Grid>
      </Grid>
      <Grid item xs={1}>
        {editable && (
          <IconButton aria-label="delete" onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

const SlowDownField = ({ rule, errors = {}, currentPriorities }) => {
  const dispatch = useOptimizationChangesDispatch();
  const { priority, value = { value: "" }, editable } = rule;

  const handleRemove = (_event) =>
    dispatch({ action: "rule_removed", section: SLOW_DOWN_CAP_SECTION, ...rule });

  const handleUpdate = (value) =>
    dispatch({ action: "rule_updated", section: SLOW_DOWN_CAP_SECTION, ...rule, ...value });

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      columnSpacing={2}
      sx={{ mb: 2 }}
    >
      <Grid item xs={4}>
        <TextField
          select
          size="small"
          label="Priority"
          value={priority}
          disabled={!editable}
          fullWidth
          error={"priority" in errors}
          helperText={errors.priority?.join(", ") ?? ""}
          onChange={(event) => handleUpdate({ priority: event.target.value })}
        >
        <MenuItem value="">Select...</MenuItem>
          {(editable ? RULE_PRIORITIES_OPTIONS : [priority]).map(value => <MenuItem key={value} value={value} disabled={currentPriorities.includes(value)}>{value}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item xs={7} sx={{ display: "flex", alignItems: "center" }}>
        <Typography component="span" variant="body2" sx={{ mr: 2 }}>Show ad on every</Typography>
        <NumericFormat
          customInput={TextField}
          label="Enter value here"
          variant="standard"
          value={value.value}
          size="small"
          disabled={!editable}
          error={"value" in errors}
          helperText={errors.value?.join(", ") ?? ""}
          thousandSeparator
          onValueChange={(values, _sourceInfo) => handleUpdate({ value: { ...value, value: values.value } })}
        />
        <Typography component="span" variant="body2" sx={{ ml: 2 }}>Users</Typography>
      </Grid>
      <Grid item xs={1}>
        {editable && (
          <IconButton aria-label="delete" onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

const RampDownSwitch = ({ rampDown }) => {
  const dispatch = useOptimizationChangesDispatch();
  const handleChange = (event) => dispatch({ action: "offer_attribute_changed", "pacing.ramp_down": event.target.checked.toString() });

  return (
    <FormGroup>
      <FormControlLabel
        sx={{ width: 200 }}
        control={<Switch checked={rampDown} onChange={handleChange} />}
        label="Ramp Down"
      />
    </FormGroup>
  )
}

const FIELDS_COMPONENT = {
  ramp_down: (field, rampDown) => formFieldBuilder(RampDownSwitch, field, { rampDown: rampDown === "true" }),
  frequency_capping: (field, rules) => formFieldBuilder(OptimizationFieldGroup, field, { RuleField: FrequencyCapField, rules }),
  completion_capping: (field, rules) => formFieldBuilder(OptimizationFieldGroup, field, { RuleField: CompletionCapField, rules }),
  slow_down: (field, rules) => formFieldBuilder(OptimizationFieldGroup, field, { RuleField: SlowDownField, rules }),
}

const PacingFormSection = (changes) => {
  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>Pacing</Typography>
      {listify(changes, (field, props) => FIELDS_COMPONENT[field](field, props))}
    </Box>
  )
}

export default PacingFormSection;
