import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  json,
  Outlet,
  redirect,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { get, patch } from "../apiClient";
import SegmentForm from "../features/segments/components/SegmentForm";
import formatCPM from "../features/segments/utils/formatCPM";
import humanize from "../features/segments/utils/humanize";
import useTitle from "../hooks/useTitle";
import formatDateTime from "../utils/formatDateTime";
import formatNumber from "../utils/formatNumber";
import parseNaiveDateTime from "../utils/parseNaiveDateTime";

export async function action({ params, request }) {
  const formData = await request.formData();
  const formEntries = Object.fromEntries(formData);

  // We need to explicitly set cpm_in_cents to `null` in case it's an
  // empty string (`""`).
  formEntries["cpm_in_cents"] = formEntries["cpm_in_cents"] || null;

  try {
    const segment = await patch(
      "audience",
      `/v1/segments/${params.id}`,
      formEntries
    );
    return redirect(`/segments/${segment.id}`);
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422:
        return json(error.details.errors, { status: error.status });
      /* istanbul ignore next */
      default:
        throw error;
    }
  }
}

export async function loader({ params, request }) {
  try {
    const segment = await get("audience", `/v1/segments/${params.id}`, {
      signal: request.signal,
    });
    return json(segment);
  } catch (error) {
    switch (error.status) {
      case 404:
        throw new json(
          {
            title: "Segment not found",
            text: `The segment with ID ${params.id} could not be found.`,
          },
          { status: 404 }
        );
      case 422:
        throw new json(
          {
            title: "Invalid segment ID",
            text: `The segment ID ${params.id} is not valid.`,
          },
          { status: 422 }
        );
      /* istanbul ignore next */
      default:
        throw error;
    }
  }
}

const SegmentProperty = ({ name, value }) => {
  return (
    <Grid item xs={6}>
      <Typography color="text.secondary" variant="subtitle2">
        {name}
      </Typography>
      {value}
    </Grid>
  );
};

const Segment = ({ isEditing = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const segment = useRouteLoaderData("segment");
  const segmentTypes = useRouteLoaderData("segmentTypes");
  useTitle(`Segment ${humanize(segment)}`);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ px: 3, py: 2 }}>
            <>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ alignItems: "center", display: "flex" }}>
                  <Typography sx={{ mr: 1 }} variant="h5">
                    {humanize(segment)}
                  </Typography>
                  {segment.active ? (
                    <Typography
                      data-testid="segment-active-status"
                      color="success.main"
                      variant="overline"
                    >
                      Active
                    </Typography>
                  ) : (
                    <Typography
                      data-testid="segment-active-status"
                      color="error"
                      variant="overline"
                    >
                      Inactive
                    </Typography>
                  )}
                </Box>
                <ButtonGroup variant="contained">
                  <Button
                    onClick={() =>
                      navigate(isEditing ? `/segments/${id}` : "edit")
                    }
                  >
                    {isEditing ? "Cancel" : "Edit"}
                  </Button>
                  <Button
                    onClick={() => navigate("associate")}
                    disabled={isEditing}
                  >
                    Add identifiers
                  </Button>
                  <Button
                    onClick={() => navigate("dissociate")}
                    disabled={isEditing}
                  >
                    Remove identifiers
                  </Button>
                  <Button
                    onClick={() => navigate("deactivate")}
                    disabled={isEditing}
                  >
                    Mark for deletion
                  </Button>
                </ButtonGroup>
              </Box>
              <Divider sx={{ my: 2 }} />
              {isEditing ? (
                <SegmentForm
                  action={`/segments/${id}/edit`}
                  isEditing
                  segment={segment}
                  segmentTypes={segmentTypes}
                />
              ) : (
                <Grid container spacing={2}>
                  <SegmentProperty name="Segment key" value={segment.name} />
                  <SegmentProperty
                    name="Segment name"
                    value={segment.human_readable_name}
                  />
                  <SegmentProperty
                    name="ID Type"
                    value={humanize(segment.segment_type)}
                  />
                  <SegmentProperty name="Source" value={segment.source} />
                  <SegmentProperty
                    name="CPM"
                    value={formatCPM(segment.cpm_in_cents)}
                  />
                  <SegmentProperty
                    name="Provider"
                    value={segment.provider ?? "-"}
                  />
                  <SegmentProperty
                    name="Size"
                    value={formatNumber(segment.targeting_count)}
                  />
                  <SegmentProperty
                    name="Created"
                    value={formatDateTime(
                      parseNaiveDateTime(segment.created_at)
                    )}
                  />
                </Grid>
              )}
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6">Events</Typography>
              {segment.events.length > 0 ? (
                <TableContainer sx={{ mt: 1 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ borderBottom: "none", paddingLeft: 0 }}
                        >
                          Type
                        </TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                          Time
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {segment.events.map((event) => {
                        const type = {
                          associate_targetings: "Added identifiers",
                          deactivate_segment: "Marked for deletion",
                          dissociate_targetings: "Removed identifiers",
                        }[event.event];
                        return (
                          <TableRow key={event.id}>
                            <TableCell
                              style={{ borderBottom: "none", paddingLeft: 0 }}
                            >
                              {type}
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              {formatDateTime(
                                parseNaiveDateTime(event.created_at)
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box sx={{ mt: 1 }}>No events yet.</Box>
              )}
              <Outlet />
            </>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Segment;
