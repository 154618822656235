import { Grid, TextField } from "@mui/material";
import { debounce } from "radash";
import { useEffect, useMemo, useState } from "react";
import { Form, useNavigation, useSubmit } from "react-router-dom";
import { SEARCH_DELAY } from "../constants";
import humanize from "../utils/humanize";

const SegmentFiltering = ({ segmentTypes, filters }) => {
  const submit = useSubmit();
  const submitDebounced = useMemo(
    () => debounce({ delay: SEARCH_DELAY }, submit),
    [submit]
  );
  const navigation = useNavigation();
  const [searchTerm, setSearchTerm] = useState(filters.searchTerm ?? "");
  const [segmentTypeId, setSegmentTypeId] = useState(
    filters.segmentTypeId ?? ""
  );

  useEffect(() => {
    if (navigation.state === "loading") {
      const searchParams = new URLSearchParams(navigation.location.search);
      setSearchTerm(searchParams.get("searchTerm") ?? "");
      setSegmentTypeId(searchParams.get("segmentTypeId") ?? "");
    }
  }, [navigation.state, navigation.location]);

  return (
    <Form
      method="get"
      onChange={(e) => {
        const isFirstSearch =
          filters.searchTerm === undefined &&
          filters.segmentTypeId === undefined;

        e.target.name === "searchTerm"
          ? submitDebounced(e.currentTarget, { replace: !isFirstSearch })
          : submit(e.currentTarget, { replace: !isFirstSearch });
      }}
    >
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={8}>
          <TextField
            value={searchTerm}
            fullWidth
            name="searchTerm"
            label="Search Segments"
            onChange={(e) => setSearchTerm(e.target.value)}
            type="search"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={segmentTypeId}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Filter by ID Type"
            name="segmentTypeId"
            SelectProps={{ native: true }}
            onChange={(e) => setSegmentTypeId(e.target.value)}
            required
            select
          >
            <option value="">None</option>
            {segmentTypes.map((segmentType) => {
              return (
                <option key={segmentType.id} value={segmentType.id}>
                  {humanize(segmentType)}
                </option>
              );
            })}
          </TextField>
        </Grid>
      </Grid>
    </Form>
  );
};

export default SegmentFiltering;
