import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { Form, useRouteLoaderData } from "react-router-dom";
import DateRangeInput from "./DateRangeInput";

const CreditsFilter = ({ filters, onFilterChange }) => {
  const accounts = useRouteLoaderData("accountsList");
  const handleStartDateChange = (newValue) => {
    if (newValue === null || !newValue.invalid) {
      onFilterChange({ startDate: newValue?.toSQLDate() ?? "" });
    }
  };
  const handleEndDateChange = (newValue) => {
    if (newValue === null || !newValue.invalid) {
      onFilterChange({ endDate: newValue?.toSQLDate() ?? "" });
    }
  };

  return (
    <Form role={"searchform"}>
      <Grid container sx={{ pt: 4 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControl sx={{ width: 240 }} size={"small"}>
            <TextField
              size="small"
              label="Account"
              name="accountId"
              select
              onChange={(e) => onFilterChange({ accountId: e.target.value })}
              value={!filters.accountId ? "all" : filters.accountId}
            >
              <MenuItem value="all">ALL</MenuItem>
              {accounts.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <DateRangeInput
            sx={{ pl: 3 }}
            startProps={{
              onChange: handleStartDateChange,
              value: filters.startDate ?? null,
            }}
            endProps={{
              onChange: handleEndDateChange,
              value: filters.endDate ?? null,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: "space-between" }}>
          <FormControl variant="standard">
            <Input
              name="searchTerm"
              value={filters.searchTerm ?? ""}
              onChange={(e) => onFilterChange({ searchTerm: e.target.value })}
              form="search"
              type="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CreditsFilter;
