import { Grid, TextField } from "@mui/material";
import { debounce } from "radash";
import { useMemo, useState } from "react";
import { Form, useSubmit } from "react-router-dom";

const PlacementPrebidConfigurationFiltering = ({ filters }) => {
  const submit = useSubmit();
  const submitDebounced = useMemo(
    () => debounce({ delay: 300 }, submit),
    [submit]
  );
  const [searchTerm, setSearchTerm] = useState(filters.searchTerm ?? "");

  return (
    <Form
      method="get"
      onChange={(e) => {
        const isFirstSearch = filters.searchTerm === undefined;

        submitDebounced(e.currentTarget, { replace: !isFirstSearch });
      }}
    >
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <TextField
            value={searchTerm}
            fullWidth
            name="search"
            label="Search by Placement ID"
            onChange={(e) => setSearchTerm(e.target.value)}
            type="search"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default PlacementPrebidConfigurationFiltering;
