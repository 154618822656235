import {
  Grid,
} from "@mui/material";

import OptimizationBox from "./OptimizationBox";
import OptimizationBoxItemsList from "./OptimizationBoxItemsList";

import formatNumber from "../../../utils/formatNumber";

const frequencyCappingRulesInfo = (rules) =>
  rules.flatMap(({ id, priority, value: { value, unit } }) => {
    return [
      { key: `${id}_priority`, text: `Priority: ${priority}` },
      { key: `${id}_info`, text: `${formatNumber(value)} ${unit}`, sx: { pl: 2.75 } },
    ];
  });

const userCompletionsCappingRulesInfo = (rules) => {
  const userCompletionCappingText = ({ key, id, value }) => {
    const formattedValue = formatNumber(value);
    const text = key === "offer_max_completions_per_user" ? `Max Completions per User: ${formattedValue}` : `Max Daily Completions per User: ${formattedValue}`;

    return { key: `${id}_${key}`, text, sx: { pl: 2.75 } };
  }

  return rules.flatMap(({ id, priority, value }) => {
    return [
      { key: `${id}_priority`, text: `Priority: ${priority}` },
      ...Object.keys(value).map((key) => userCompletionCappingText({ id, key, value: value[key] }))
    ]
  });
}

const slowDownRulesInfo = (rules) =>
  rules.flatMap(({ id, priority, value }) => {
    return [
      { key: `${id}_priority`, text: `Priority: ${priority}`},
      { key: `${id}_info`, text: `Show ad once every ${formatNumber(value.value)} users`, sx: { pl: 2.75 } }
    ];
  });

const rampDownInfo = (ramp_down) => [{ key: "ramp_down", text: ramp_down === "true" ? "On" : "Off" }]

const PacingDetail = ({
  details: {
    ramp_down,
    frequency_capping = [],
    completion_capping = [],
    slow_down = []
  }
}) => {
  return (
    <OptimizationBox panelId={"pacing"} key={"pacing"} title={"Pacing"}>
      <Grid container direction="column">
        <Grid item>
        <OptimizationBoxItemsList
          title={"Ramp Down"}
          items={rampDownInfo(ramp_down)} />
        </Grid>
        {frequency_capping.length > 0 && (
          <Grid item>
            <OptimizationBoxItemsList
              title={"User Ad View Time Interval"}
              items={frequencyCappingRulesInfo(frequency_capping)}/>
          </Grid>
        )}
        {completion_capping.length > 0 && (
          <Grid item>
            <OptimizationBoxItemsList
              title={"User Frequency Capping (Completions)"}
              items={userCompletionsCappingRulesInfo(completion_capping)}/>
          </Grid>
        )}
        {slow_down.length > 0 && (
          <Grid item>
            <OptimizationBoxItemsList
              title={"Slow Down (Modular Division)"}
              items={slowDownRulesInfo(slow_down)} />
          </Grid>
        )}
      </Grid>
    </OptimizationBox>
  );
}

export default PacingDetail;
