const RULE_PRIORITIES_OPTIONS = [
  0,
  100,
  200,
  250,
  300,
  350,
  400,
  450,
  475,
  1000
];

const DEFAULT_RULE_TITLES = {
  frequency_capping: "User Ad View Time Interval",
  completion_capping: "User Frequency Capping (Completions)",
  slow_down: "Slow Down (Modular Division)",
  custom_site_management: "Custom Site Management",
  device_type: "Device Type",
  audience: "3rd Party & Custom Audience"
}

export const formFieldBuilder = (GroupComponent, section, props) => {
  if (["ramp_down", "creative", "bid"].includes(section)) {
    return <GroupComponent key={section} {...props} />
  } else {
    const { rules, ...otherProps } = props;
    const currentPriorities = rules.map(({ priority }) => priority);
    const propsWithDefaults = { title: DEFAULT_RULE_TITLES[section], ...otherProps };

    return <GroupComponent key={section} rules={rules} {...propsWithDefaults} currentPriorities={currentPriorities} />
  }
}

const USER_FREQUENCY_CAP_UNIT_OPTIONS = [
  { label: "Second", value: "second" },
  { label: "Minute", value: "minute" },
  { label: "Hour", value: "hour" },
  { label: "Day", value: "day" },
];

const DEVICE_TYPE_OPTIONS = [
  { label: "Android", value: "android" },
  { label: "iPhone", value: "iPhone" },
  { label: "iPad", value: "iPad" }
]

const FREQUENCY_CAP_SECTION = "pacing.frequency_capping";
const COMPLETION_CAP_SECTION = "pacing.completion_capping";
const SLOW_DOWN_CAP_SECTION = "pacing.slow_down";
const GEOGRAPHY_SECTION = "geography";
const INVENTORY_DEVICE_TYPE_SECTION = "inventory.device_type";
const INVENTORY_CUSTOM_MANAGEMENT_SECTION = "inventory.custom_site_management";
const AUDIENCE_SECTION = "audience";
const CUSTOM_BID_SECTION = "bid";

export {
  USER_FREQUENCY_CAP_UNIT_OPTIONS,
  RULE_PRIORITIES_OPTIONS,
  DEVICE_TYPE_OPTIONS,
  FREQUENCY_CAP_SECTION,
  COMPLETION_CAP_SECTION,
  SLOW_DOWN_CAP_SECTION,
  GEOGRAPHY_SECTION,
  INVENTORY_CUSTOM_MANAGEMENT_SECTION,
  INVENTORY_DEVICE_TYPE_SECTION,
  AUDIENCE_SECTION,
  CUSTOM_BID_SECTION,
};
