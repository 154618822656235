import {
  Grid,
} from "@mui/material";

import OptimizationBox from "./OptimizationBox";
import OptimizationBoxItemsList from "./OptimizationBoxItemsList";

import { buildTargetingRuleInfoItems } from "../utils/optimizationDetailsUtils";

const AudienceDetail = ({ details: rules }) => {
  return rules.length > 0 && (
    <OptimizationBox panelId={"audience"} title={"Audience"}>
      <Grid container direction="row">
        <Grid item>
          <OptimizationBoxItemsList
            title={"3rd Party & Custom Audience"}
            items={buildTargetingRuleInfoItems(rules)} />
        </Grid>
      </Grid>
    </OptimizationBox>
  );
}

export default AudienceDetail;
