import FilterAltOffRoundedIcon from "@mui/icons-material/FilterAltOffRounded";
import { styled } from "@mui/material/styles";
import { GridPagination } from "@mui/x-data-grid";
import * as apiClient from "../../apiClient";
import formatNumber from "../../utils/formatNumber";
import CreditsFilter from "./components/CreditsFilter";
import { fromSQLToDate } from "./utils/dateFormatUtils.js";
import {
  filterResourceBy,
  filtersFromSearchParams,
  updateFilter,
} from "./utils/searchUtils.js";

import { useNavigate } from "react-router-dom";

import { Box, Button, Grid as MUIGrid, Link as MUILink } from "@mui/material";

import { useCallback, useEffect, useState } from "react";

import { SearchDataGrid } from "./components/SearchDataGrid";

const DEFAULT_RESULT = { result: [], totalAmount: 0, totalCount: 0 };

const Grid = styled(MUIGrid)(
  ({ theme }) => `
  background: ${theme.palette.primary.main};
  color: white;
  letter-spacing: 0.17px;
  height: 32px;

  & .MuiGrid-item {
    height: 32px;
    line-height: 32px;
  }
`
);
const Link = styled(MUILink)(
  () => `
    color: #64B5C5;
  `
);

const currencyFormatter = (value) =>
  formatNumber(value, { style: "currency", currency: "USD" });

const CreditsSearchFooter = ({ totalAmount }) => (
  <>
    <Grid container sx={{ display: "flex" }}>
      <Grid item xs={7} sx={{ paddingLeft: 2, paddingRight: 2 }}>
        Grand Total
      </Grid>
      <Grid item xs={5} sx={{ paddingLeft: 7, paddingRight: 2 }}>
        {currencyFormatter(Number(totalAmount))}
      </Grid>
    </Grid>
    <GridPagination />
  </>
);

const CreditsSearchColumns = [
  {
    headerName: "Credit Date",
    field: "date",
    valueFormatter: ({ value }) => fromSQLToDate(value),
    flex: 1,
  },
  {
    headerName: "Account ID",
    field: "account_id",
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Link
          underline="hover"
          href={`/admin/managed_plus_accounts/${row.account_id}`}
        >
          {row.account_id}
        </Link>
      );
    },
  },
  { headerName: "Account Name", field: "account_name", flex: 1 },
  {
    headerName: "Offer ID",
    field: "offer_id",
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Link
          underline="hover"
          href={`/admin/managed_plus_offers/${row.offer_id}`}
        >
          {row.offer_id}
        </Link>
      );
    },
  },
  {
    headerName: "Credit Amount",
    field: "amount",
    flex: 1,
    valueFormatter: ({ value }) => currencyFormatter(Number(value)),
  },
  { headerName: "Credit Reason", field: "reason", flex: 1 },
];

const DEFAULT_SORTING_MODEL = [{ field: "date", sort: "desc" }];

const onDeleteConfirm = async (params) =>
  apiClient.destroy("managed_plus", `/credits/${params.id}`);

const CreditsTab = () => {
  const [result, setResult] = useState(DEFAULT_RESULT);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(
    filtersFromSearchParams(DEFAULT_SORTING_MODEL)
  );
  const [isLoading, setIsLoading] = useState(true);

  const onEditActionClick = useCallback(
    (params) => {
      navigate(`/managed-plus/credits/${params.id}/edit`);
    },
    [navigate]
  );

  useEffect(() => {
    setIsLoading(true);
    filterResourceBy("credits", filters)
      .onSuccess(
        ({
          result: records,
          total_count: totalCount,
          total_amount: totalAmount,
        }) => {
          setResult({ result: records, totalCount, totalAmount });
        }
      )
      .onError((error) => {
        setResult({
          ...DEFAULT_RESULT,
          error: "Failed to fetch credits from server",
        });
      })
      .done(() => setIsLoading(false))
      .run();
  }, [filters]);

  return (
    <div aria-label="Credits list">
      <CreditsFilter
        filters={filters}
        onFilterChange={(updatedParams) =>
          setFilters((params) => updateFilter(params, updatedParams))
        }
      />
      <Box
        sx={{ height: 640, pt: 4, display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", pb: 2 }}>
          <Button
            variant="contained"
            disabled={selectedRows.length < 2}
            onClick={(event) => {
              event.preventDefault();
              const ids = selectedRows.map((id) => `ids[]=${id}`).join("&");
              navigate(`/managed-plus/credits/bulk-edit?${ids}`);
            }}
          >
            Bulk Edit
          </Button>
          <Link
            underline="none"
            component="button"
            href="#"
            onClick={() =>
              setFilters({ page: 0, perPage: 25, order: DEFAULT_SORTING_MODEL })
            }
            sx={{ pl: 2, display: "flex", alignItems: "center" }}
          >
            {<FilterAltOffRoundedIcon />} Clear All Filters
          </Link>
        </Box>
        <SearchDataGrid
          DataGridProps={{
            onRowSelectionModelChange: (model) => setSelectedRows(model),
            loading: isLoading,
            isRowSelectable: ({ row }) => row.editable,
          }}
          onDeleteComplete={() => {
            // Force filters to change and refresh the credits tab
            setFilters((params) => updateFilter(params, {}));
          }}
          onEditActionClick={onEditActionClick}
          onCopyActionClick={({ row }) => {
            const { reason, amount, offer_id, date } = row;
            const creditCopiedValues = { reason, amount, offer_id, date };
            navigate("/managed-plus/credits/new", {
              state: { creditCopiedValues },
            });
          }}
          sortModel={filters.order}
          onSortChange={(model) =>
            setFilters((params) => ({ ...params, order: model }))
          }
          onPaginationChange={({ pageSize: perPage, page }) => {
            const currentPage = filters.perPage === perPage ? page : 0;
            setFilters((params) => ({ ...params, perPage, page: currentPage }));
          }}
          searchResult={result}
          page={filters.page}
          pageSize={filters.perPage}
          actions={{ edit: true, copy: true, remove: true }}
          onDeleteConfirm={onDeleteConfirm}
          columns={CreditsSearchColumns}
          customFooter={CreditsSearchFooter}
        />
      </Box>
    </div>
  );
};

export { CreditsTab };
