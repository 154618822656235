import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton
} from "@mui/material";

import ErrorIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";

const ErrorBadge = () => (
  <Box
    component="span"
    sx={{
      verticalAlign: "middle",
      display: "inline-flex",
      mb: 0.5,
      transform: "translateX(-25px)"
    }}
  >
    <ErrorIcon color="error" sx={{ width: 20, height: 20 }} />
  </Box>
)

const ForbiddenAlert = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      hideBackdrop
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{ sx: { borderRadius: 2.5 } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="small"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogTitle sx={{ color: "#282828" }}>
          <ErrorBadge /> Access Restricted
        </DialogTitle>
        <DialogContentText sx={{ mt: 1.25, color: "#282828" }}>
          {"You don't have permission to take this action."}
          <br />
          {"Please contact your administrator to review and update your permissions."}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default ForbiddenAlert;
