import { Box, Container, LinearProgress, Toolbar } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import Copyright from "./components/Copyright";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import { TitleContext } from "./hooks/useTitle";

const drawerWidth = 240;

export const App = () => {
  const navigation = useNavigation();
  const [title, setTitle] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    if (title !== "") {
      document.title = `${title} | Player UI`;
    }
  }, [title]);

  const titleContextValue = useMemo(() => {
    return { title, setTitle };
  }, [title, setTitle]);

  return (
    <TitleContext.Provider value={titleContextValue}>
      <Box sx={{ display: "flex" }}>
        <Header
          drawerWidth={drawerWidth}
          isDrawerOpen={isDrawerOpen}
          title={title}
          toggleDrawer={toggleDrawer}
        />
        <Navigation
          drawerWidth={drawerWidth}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
        />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            {navigation.state === "loading" && (
              <LinearProgress data-testid="loading" />
            )}
            <Outlet />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </TitleContext.Provider>
  );
};

export default App;
