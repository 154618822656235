import {
  Box,
  Grid,
  IconButton,
  Typography,
  MenuItem
} from "@mui/material";

import TextField from "./TextField"

import DeleteIcon from "@mui/icons-material/Delete";

import { useOptimizationChangesDispatch } from "../OptimizationsContext";

import { RULE_PRIORITIES_OPTIONS, GEOGRAPHY_SECTION, formFieldBuilder } from "../utils/optimizationFormUtils.js";

import OptimizationFieldGroup from "./OptimizationFieldGroup";

const TargetingCountryField = ({ rule, errors = {}, currentPriorities }) => {
  const dispatch = useOptimizationChangesDispatch();
  const { priority = "", value = { value: [], action: "" }, editable } = rule;
  value.target = "country";

  const handleUpdate = (value) =>
    dispatch({ action: "rule_updated", section: GEOGRAPHY_SECTION, ...rule, ...value });

  const handleRemove = (_event) =>
    dispatch({ action: "rule_removed", section: GEOGRAPHY_SECTION, ...rule });

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      sx={{ mb: 2 }}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        columnSpacing={2}
        sx={{ mb: 1 }}
        direction="row"
      >
        <Grid item xs={4}>
          <TextField
            select
            size="small"
            label="Priority"
            value={priority}
            disabled={!editable}
            fullWidth
            onChange={(event) => handleUpdate({ priority: event.target.value })}
            error={"priority" in errors}
            helperText={errors.priority ?? ""}
          >
          <MenuItem value="">Select...</MenuItem>
            {(editable ? RULE_PRIORITIES_OPTIONS : [priority]).map(value => <MenuItem key={value} value={value} disabled={currentPriorities.includes(value)}>{value}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            size="small"
            label="Target/Block"
            value={value.action ?? ""}
            disabled={!editable}
            fullWidth
            onChange={(event) => handleUpdate({ value: { ...value, action: event.target.value } })}
            error={"action" in errors}
            helperText={errors.action ?? ""}
          >
            <MenuItem value="">Select...</MenuItem>
            <MenuItem value="target" key="target">Target</MenuItem>
            <MenuItem value="block" key="block">Block</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            select
            size="small"
            label="Type"
            value={value.target ?? "country"}
            disabled
            fullWidth
          >
            <MenuItem value="country" key="country">Country</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={1}>
          {editable && (
            <IconButton aria-label="delete" onClick={handleRemove}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <TextField
          fullWidth
          variant="standard"
          label="List"
          size="small"
          disabled={!editable}
          error={"value" in errors}
          helperText={errors.value ?? ""}
          onChange={(event) => {
            const currentValue = event.target.value;
            const newValue = currentValue === "" ? [] : currentValue.split(",")

            handleUpdate({ value: { ...value, value: newValue } })
          }}
          value={value.value.join(",")}
        />
      </Grid>
    </Grid>
  )
}

const GeographyFormSection = ({ rules }) => {
  return rules.length > 0 && (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>Geography</Typography>
      {formFieldBuilder(OptimizationFieldGroup,"geography", { RuleField: TargetingCountryField, rules })}
    </Box>
  )
}

export default GeographyFormSection;
