import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { useCallback } from "react";

const ConfirmDeleteDialog = ({ open, onConfirm, onClose, currentRow }) => {
  const handleConfirm = useCallback(async () => {
    await onConfirm(currentRow);
  }, [onConfirm, currentRow]);

  if (!open || !currentRow) {
    return null;
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this record?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Confirm</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmDeleteDialog };
