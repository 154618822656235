import {
 useEffect, useState
} from "react";
import {styled} from "@mui/material/styles";
import {Checkbox, IconButton, Link, MenuItem, Select, TableCell, TableRow} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {title} from "radash";
import {urlType} from "./urlsTabsHelper";

// Styled component for TableCell to handle long URLs
const StyledTableCell = styled(TableCell)`
  max-width: 300px; /* Adjust the width as needed */
  overflow: hidden;
  word-wrap: break-word; /* Breaks long words to fit within the cell */
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  &:hover {
    overflow: visible;
    white-space: normal;
    background-color: #f8f9fa; /* Adjust the hover background color if needed */
    padding: 2px 5px; /* Adjust the padding if needed */
    border-radius: 3px; /* Adjust the border radius if needed */
  }
`;

const CheckboxCell = ({ checked, onCheck, value }) => {
  return (
    <TableCell>
      <Checkbox style={{ width: 20, height: 20 }} checked={checked} onClick={onCheck} value={value} />
    </TableCell>
  )
}

const StyledTableRow = styled(TableRow)(({ theme  }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#1976D214"
  },
}));

const UrlRow = ({
                  onCheck,
                  onSave,
                  checkable = false,
                  checked = false,
                  urlInfo,
                  loading
                }) => {
  const { id, link, rejection_reason, status, url_content_rating_id, url_type } = urlInfo;
  const [state, setState] = useState({ id, link, rejection_reason, status, url_content_rating_id, url_type });
  const isStale = checkable ? (
      [null, undefined, ""].includes(state.url_content_rating_id) ||
      [null, undefined, "", "pending"].includes(state.status) ||
      ([null, undefined, ""].includes(state.rejection_reason) && state.status === "rejected")
    ) : (url_content_rating_id * 1 === state.url_content_rating_id * 1 && status === state.status && (rejection_reason ?? "") === (state.rejection_reason ?? "")) ||
    ([null, undefined, ""].includes(state.rejection_reason) && state.status === "rejected");

  const handleStateChange = (newState) => setState((oldState) => ({ ...oldState, ...newState }))

  useEffect(() => {
    const { id, link, rejection_reason, status, url_content_rating_id , url_type } = urlInfo;
    setState({ id, link, rejection_reason, status, url_content_rating_id, url_type });
  }, [loading, urlInfo])

  return (
    <>
    <StyledTableRow key={id} sx={{"&:last-child td, &:last-child th": {border: 0,  height: 48}}}>
      {checkable && (<CheckboxCell checked={checked} onCheck={onCheck} value={id} sx={{ width: "20px", height: "20px",
        flexShrink: 0 }} />)}
      <StyledTableCell>
        <StyledLink href={link} rel="noreferrer" target="_blank">{link}</StyledLink>
      </StyledTableCell>
      <TableCell sx={{ padding: "6px 10px" }}>{urlType(url_type)}</TableCell>
      <TableCell sx={{ padding: "6px 10px 6px 10px" }}>{title(status)}</TableCell>
      <TableCell sx={{ width: 295, padding: "6px 10px 6px 10px" }}>
        <Select
          disabled={status === "live"}
          size="small"
          fullWidth
          style={{ width: 302, height: 32, fontSize: 14 }}
          onChange={(e) => handleStateChange({ url_content_rating_id: e.target.value })}
          value={state.url_content_rating_id ?? ""}
        >
          <MenuItem sx={{ fontSize: 14 }} value={1}>G</MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value={2}>PG</MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value={3}>PG-13</MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value={4}>R</MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value={5}>Alcohol</MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value={6}>Exclude Auto Placement Targeting</MenuItem>
        </Select>
      </TableCell>
      <TableCell sx={{ width: 110, padding: "6px 10px 6px 10px" }}>
        <Select
          size="small"
          style={{ width: 139, height: 32, fontSize: 14 }}
          onChange={(e) => {
            const currentStatus = e.target.value

            handleStateChange({
              status: currentStatus,
              ...(currentStatus === "live" && { rejection_reason: "" })
            })
          }}
          value={state.status !== "pending" ? state.status : ""}
        >
          <MenuItem sx={{ fontSize: 14 }} value="live">Live</MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value="rejected">Rejected</MenuItem>
        </Select>
      </TableCell>
      <TableCell sx={{padding: "6px 0px 6px 10px"}}>
        <Select
          fullWidth
          style={{ width: 222, height: 32, fontSize: 14 }}
          size="small"
          disabled={state.status !== "rejected"}
          onChange={(e) => handleStateChange({ rejection_reason: e.target.value })}
          value={state.rejection_reason ?? ""}
        >
          <MenuItem sx={{ fontSize: 14 }} value="Mature topics, language, or imagery">Mature topics, language, or imagery</MenuItem>
          <MenuItem sx={{ fontSize: 14 }} value="URL is broken">URL is broken</MenuItem>
        </Select>
      </TableCell>
      <TableCell>
        <IconButton sx={{width: "24px", height: "24px", flexShrink: 0}} onClick={(e) => onSave(state)} disabled={isStale} color={"primary"}>
          <SaveIcon />
        </IconButton>
      </TableCell>
    </StyledTableRow>
      </>
  )
}

export default UrlRow;
