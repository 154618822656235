import { Help as HelpIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import {
  Form,
  json,
  useActionData,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
} from "react-router-dom";
import { post } from "../../../apiClient";
import createFormErrors from "../../../utils/createFormErrors";
import humanize from "../utils/humanize";

function parseIdentifierColumns(string) {
  /* istanbul ignore if */
  if (string === "") {
    return [0];
  } else {
    return string.split(",").map((column) => Number(column) - 1);
  }
}

export async function action({ params, request }) {
  const formData = await request.formData();

  try {
    await post(
      "audience",
      `/v1/segments/${params.id}/targetings/${formData.get("action")}`,
      {
        identifier_columns: formData.getAll("identifierColumns"),
        s3_bucket: formData.get("s3Bucket"),
        s3_key: formData.get("s3Key"),
      },
      { signal: request.signal }
    );

    return json({ success: true });
  } catch (error) {
    switch (error.status) {
      case 422:
        return json(
          { errors: createFormErrors(error.details.errors) },
          { status: 422 }
        );
      case 503:
        return json(
          {
            errors: createFormErrors([
              {
                title:
                  "Couldn't schedule job. A job is likely already running for the segment.",
              },
            ]),
          },
          { status: 503 }
        );
      /* istanbul ignore next */
      default:
        throw error;
    }
  }
}

const SegmentTargetingJobDialog = ({ isAssociationJob }) => {
  const [identifierColumns, setIdentifierColumns] = useState([0]);
  const segment = useRouteLoaderData("segment");
  const form = useActionData();
  const navigate = useNavigate();
  const navigation = useNavigation();

  return (
    <Dialog onClose={() => navigate("..")} open>
      <Form method="post">
        <input
          type="hidden"
          name="action"
          value={isAssociationJob ? "associate" : "dissociate"}
        />
        {identifierColumns.map((column, index) => (
          <input
            key={`${index}-${column}`}
            type="hidden"
            name="identifierColumns"
            value={column}
          />
        ))}

        <DialogTitle>
          {isAssociationJob
            ? `Target identifiers for ${humanize(segment)}`
            : `Remove targeted identifiers for ${humanize(segment)}`}
        </DialogTitle>
        <DialogContent>
          {form?.success && (
            <Alert severity="success" sx={{ mb: 1 }}>
              A job has been scheduled.
            </Alert>
          )}
          {form?.errors?.hasOwnProperty("/") && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {form.errors["/"]}
            </Alert>
          )}
          <DialogContentText>
            {isAssociationJob
              ? "To schedule a job to add identifiers to the segment, provide a bucket and key pointing to a CSV with identifiers in S3."
              : "To schedule a job to remove identifiers from the segment, provide a bucket and key pointing to a CSV with identifiers in S3."}
          </DialogContentText>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                error={form?.errors?.hasOwnProperty("/s3_bucket")}
                fullWidth
                helperText={form?.errors?.["/s3_bucket"]}
                label="S3 bucket"
                name="s3Bucket"
                onChange={(event) => {
                  event.target.value = event.target.value.replace(/\s/g, "");
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={form?.errors?.hasOwnProperty("/s3_key")}
                fullWidth
                helperText={form?.errors?.["/s3_key"]}
                label="S3 key"
                name="s3Key"
                onChange={(event) => {
                  event.target.value = event.target.value.replace(/\s/g, "");
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={form?.errors?.hasOwnProperty("/identifier_columns")}
                fullWidth
                helperText={form?.errors?.["/identifier_columns"]}
                inputProps={{
                  pattern: "^^[1-9]\\d*(,[1-9]\\d*)*$",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          'Specify which column or columns in the CSV contain identifiers. For example, enter "2" to use the second column. Provide multiple columns by separating them with a comma (e.g. "1,2"). If multiple columns are provided, they will be concatenated with a dot to generate a unique identifier. If this field is left empty, the first column will be used.'
                        }
                      >
                        <HelpIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                label="Identifier columns"
                onChange={(event) => {
                  if (event.target.checkValidity()) {
                    setIdentifierColumns(
                      parseIdentifierColumns(event.target.value)
                    );
                  } else {
                    setIdentifierColumns([0]);
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={navigation.state === "submitting"}
            onClick={() => navigate("..")}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={navigation.state === "submitting"}
            variant="contained"
          >
            Schedule job
          </LoadingButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default SegmentTargetingJobDialog;
