import { title } from "radash";

const buildTargetingRuleInfoItems = (rules, entity = null) => {
  return rules.flatMap(({ id, value: ruleValue, priority }) => {
    const { action, value } = ruleValue;

    return [
      { key: `${id}_priority`, text: `Priority: ${priority}` },
      { key: `${id}_info`, text: `${title(action)}: ${value.join(", ")}`, sx: { pl: 2.75 } }
    ];
  });
}

export { buildTargetingRuleInfoItems };
