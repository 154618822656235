import { json, redirect, useLocation } from "react-router-dom";
import { post } from "../apiClient";
import CreditForm from "../features/managed_plus_credits/components/CreditForm";
import { convertToDate } from "../features/managed_plus_credits/utils/dateFormatUtils.js";
import useTitle from "../hooks/useTitle";

const action = async ({ request }) => {
  const formData = await request.formData();
  const formEntries = Object.fromEntries(formData);
  formEntries.date = convertToDate(formEntries.date).toSQLDate();

  try {
    await post("managed_plus", "/credits", formEntries);

    return redirect("/managed-plus/credits");
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
};

const NewManagedPlusCredit = () => {
  useTitle("M+ Campaign Credits");
  const location = useLocation();
  const credit = location.state?.creditCopiedValues || {};

  return <CreditForm action="/managed-plus/credits/new" credit={credit} />;
};

export { action };
export default NewManagedPlusCredit;
