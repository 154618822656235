import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const OptimizationViewBox = ({ title, children, panelId }) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        arial-controls={`${panelId}-content`}
        id={panelId}
      >
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ wordBreak: "break-all" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default OptimizationViewBox;
