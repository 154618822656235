import { Grid, Paper } from "@mui/material";

import { json, redirect, useParams, useRouteLoaderData } from "react-router-dom";

import { get, patch } from "../apiClient";
import AdvertiserForm from "../features/advertisers/AdvertiserForm";
import useTitle from "../hooks/useTitle";

const action = async ({ params, request }) => {
  const formData = await request.formData();
  const advertiserCategoryIds = formData.get("advertiser_category_ids")?.length > 0
    ? formData.get("advertiser_category_ids").split(",")
    : [];

  try {
    await patch(
      "player",
      `/api/v1/advertisers/${params.id}`, {
        name: formData.get("name"),
        domain: formData.get("domain"),
        type: formData.get("type"),
        advertiser_category_ids: advertiserCategoryIds
      },
      { signal: request.signal }
    )

    return redirect("/advertisers");
  } catch (error) {
    switch(error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
}

const loader = async ({ params, request }) => {
  try {
    const advertiser = await get("player", `/api/v1/advertisers/${params.id}`, {
      signal: request.signal
    });
    return json(advertiser);
  } catch(error) {
    switch (error.status) {
      case 404:
        throw new json(
          {
            title: "Advertiser not found",
            text: `The Advertiser with ID ${params.id} could not be found.`,
          },
          { status: 404 }
        );
      default:
        throw error;
    }
  }
}

const EditAdvertiser = () => {
  useTitle("Edit Advertiser");
  const { id } = useParams();
  const advertiser = useRouteLoaderData("advertiser");
  const advertiserCategories = useRouteLoaderData("advertiserCategories");

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container justifyContent="center">
        <Grid item xl={5} lg={8} md={10} sm={12} xs={12} sx={{ minWidth: 750 }}>
          <AdvertiserForm
            action={`/advertisers/${id}/edit`}
            advertiser={advertiser}
            advertiserCategories={advertiserCategories}
            isEditing={true}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export { action, loader };
export default EditAdvertiser;
