import {
  Box,
  Grid,
  IconButton,
  Typography
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "./TextField"

import { useOptimizationChangesDispatch } from "../OptimizationsContext";

const CreativeFormSection = ({ on_page_load_js }) => {
  const dispatch = useOptimizationChangesDispatch();

  const updateOnPageLoadJs = (value) =>
    dispatch({ action: "offer_attribute_changed", creative: { on_page_load_js: value } });

  const hideSection = [null, undefined].includes(on_page_load_js);

  if (hideSection) {
    return;
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>Creative</Typography>
      <Grid
        container
        justifyContent="space-between"
        columnSpacing={2}
        direction="row"
        sx={{ mb: 2 }}
      >
        <Grid item xs={11}>
          <TextField
            size="small"
            label="On Page Load JS"
            variant="standard"
            value={on_page_load_js}
            multiline
            fullWidth
            onChange={(event) => updateOnPageLoadJs(event.target.value)}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="delete" onClick={() => updateOnPageLoadJs(null)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CreativeFormSection;
