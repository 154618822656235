import { Box, Container, LinearProgress } from "@mui/material";
import { useEffect } from "react";
import { useNavigation, useRouteError } from "react-router-dom";
import Copyright from "./components/Copyright";
import RouteError from "./components/RouteError";

export const AppError = () => {
  const navigation = useNavigation();
  const error = useRouteError();

  useEffect(() => {
    document.title = "Something went wrong | Player UI";
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {navigation.state === "loading" && <LinearProgress />}
        <RouteError error={error} />
        <Copyright sx={{ pt: 4 }} />
      </Container>
    </Box>
  );
};

export default AppError;
