import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { useState } from "react";
import {
  Form,
  useActionData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { fromSQLToDate } from "../utils/dateFormatUtils.js";

const MONTH_LIMIT_TO_LOOK_BACK = 1;

const CreditForm = ({
  action,
  credit = {
    id: null,
    date: null,
    offer_id: null,
    account_id: null,
    account_name: null,
    amount: null,
    reason: null,
    campaign_id: null,
  },
}) => {
  const navigation = useNavigation();
  const { errors = {}, ...savedRecord } = useActionData() || {};
  const [date, setDate] = useState(fromSQLToDate(credit.date));
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const minDateToLookBack = DateTime.now()
    .plus({ month: -MONTH_LIMIT_TO_LOOK_BACK })
    .set({ day: 1 })
    .toJSDate();

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: 7.875,
      }}
    >
      <Form
        action={action}
        name="creditForm"
        method={credit.id ? "put" : "post"}
      >
        <Box fullwidth="true" sx={{ mb: 3.875 }}>
          <Typography variant="h5">
            {!!credit.id ? "Edit" : "New"} Credit
          </Typography>
        </Box>
        {savedRecord?.id && (
          <Alert severity="success" sx={{ mb: 3 }}>
            Credit was updated successfully!
          </Alert>
        )}
        <Box fullwidth="true">
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              inputFormat={"MM/dd/yy"}
              value={date}
              open={open}
              disableFuture={true}
              minDate={minDateToLookBack}
              onClose={(e) => setOpen(false)}
              onChange={(value) => setDate(value)}
              sx={{ padding: 0, height: 32 }}
              renderInput={({ inputRef, inputProps }) => (
                <TextField
                  id="creditDate"
                  name="date"
                  label="Credit Date"
                  required
                  color={"primary"}
                  error={"date" in errors}
                  helperText={errors["date"]}
                  variant="outlined"
                  ref={inputRef}
                  size="small"
                  sx={{
                    width: 220,
                  }}
                  onClick={(e) => setOpen(true)}
                  inputProps={{ ...inputProps }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        {credit.account_id && (
          <Box fullwidth="true" sx={{ pt: 3.75 }}>
            <TextField
              label="Account ID"
              defaultValue={credit.account_id}
              required
              size="small"
              sx={{ width: 220 }}
              InputProps={{ disabled: true }}
              variant="outlined"
            />
          </Box>
        )}
        {credit.account_name && (
          <Box fullwidth="true" sx={{ pt: 3.75 }}>
            <TextField
              label="Account Name"
              defaultValue={credit.account_name}
              required
              size="small"
              sx={{ width: 220 }}
              InputProps={{ disabled: true }}
              variant="outlined"
            />
          </Box>
        )}
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            id="offerId"
            name="offer_id"
            label="Offer ID"
            error={"offer_id" in errors}
            helperText={errors["offer_id"]}
            defaultValue={credit.offer_id}
            required
            sx={{ width: 220 }}
            variant="standard"
          />
        </Box>
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            id="amount"
            name="amount"
            label="Credit Amount"
            error={"amount" in errors}
            helperText={errors["amount"]}
            inputProps={{
              inputMode: "numeric",
              pattern: "^\\d+([.]\\d{1,6})?$",
              title: "use a decimal value. e.g 100.56",
            }}
            sx={{ width: 220 }}
            defaultValue={credit.amount}
            required
            variant="standard"
          />
        </Box>
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            id="reason"
            name="reason"
            error={"reason" in errors}
            helperText={errors["reason"]}
            label="Credit Reason"
            sx={{ width: 734 }}
            defaultValue={credit.reason}
            required
            variant="standard"
            type="text"
          />
        </Box>
        <Box
          sx={{
            pt: 5,
            pl: 7.25,
            pr: 7.25,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LoadingButton
            loading={navigation.state === "submitting"}
            type="submit"
            variant="contained"
            sx={{ width: 288 }}
          >
            {credit.id ? "SAVE" : "CREATE"}
          </LoadingButton>
          <Button
            onClick={() => navigate("/managed-plus/credits")}
            variant="outlined"
            sx={{ width: 288 }}
          >
            Cancel
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default CreditForm;
