import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link
} from "@mui/material";

import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

import CloseIcon from "@mui/icons-material/Close";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";

import { uid } from "radash";

import { useOptimizationChangesDispatch, useOptimizationChanges } from "../OptimizationsContext";

import {
  useState
} from "react";

const NEW_RULE_ITEMS = [
  "pacing.slow_down",
  "pacing.frequency_capping",
  "pacing.completion_capping",
  "inventory.custom_site_management",
  "inventory.device_type",
  "audience",
  "geography"
];

const OFFER_ATTRIBUTES = {
  "bid.custom_max_bid_per_step": { bid: { custom_max_bid_per_step: "" } },
  "creative.on_page_load_js": { creative: { on_page_load_js: "" } } ,
};

const MODAL_TREE_SECTIONS = [
  "pacing_section",
  "inventory_section",
  "geography_section",
  "audience_section",
  "bid_section",
  "creative_section",
]

const buildRuleEvent = (itemId) => {
  return {
    action: "new_rule_added", section: itemId,
    id: `new_${uid(7)}`, priority: "", editable: true
  };
}

const NewOptimizationModal = ({ onClose }) => {
  const [items, setItems] = useState([]);
  const dispatch = useOptimizationChangesDispatch();
  const changes = useOptimizationChanges();

  const handleSelect = (itemId) => {
    if (NEW_RULE_ITEMS.includes(itemId)) {
      dispatch(buildRuleEvent(itemId));
      onClose();
      return;
    }

    if (itemId in OFFER_ATTRIBUTES && [null, undefined].includes(changes[itemId])) {
      dispatch({ action: "offer_attribute_changed", ...OFFER_ATTRIBUTES[itemId] })
      onClose();
      return;
    }

    setItems((items) => {
      return items.includes(itemId) ? items.filter((item) => item !== itemId) : [itemId]
    });
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 2.5 }}}
    >
      <DialogTitle>
        <Link
          variant="text.primary"
          underline="none"
          sx={{ fontWeight: 400, color: "rgb(0,0,0,0.54)", fontSize: "0.815rem", alignItems: "center", display: "flex" }}
          onClick={() => setItems((items) => items.length === MODAL_TREE_SECTIONS.length ? [] : MODAL_TREE_SECTIONS )}
        >
          {items.length === MODAL_TREE_SECTIONS.length ?
            (<>Collapse All {<ExpandLess />}</>) : (<>Expand All {<ExpandMore />}</>)
          }
        </Link>
      </DialogTitle>
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: 400 }}>
        <SimpleTreeView
          multiSelect={false}
          expandedItems={items}
          onSelectedItemsChange={(event, item) => handleSelect(item)}
        >
          <TreeItem itemId="pacing_section" label="Pacing">
            <TreeItem itemId="pacing.frequency_capping" label="User Ad View Time Interval" />
            <TreeItem itemId="pacing.completion_capping" label="User Frequency Capping (Completions)" />
            <TreeItem itemId="pacing.slow_down" label="Slow Down (Modular Division)" />
          </TreeItem>
          <TreeItem itemId="geography_section" label="Geography">
            <TreeItem itemId="geography" label="Country" />
          </TreeItem>
          <TreeItem itemId="audience_section" label="Audience">
            <TreeItem itemId="audience" label="3rd Party & Custom Audience" />
          </TreeItem>
          <TreeItem itemId="creative_section" label="Creative">
            <TreeItem itemId="creative.on_page_load_js" label="On Page Load JS" />
          </TreeItem>
          <TreeItem itemId="inventory_section" label="Inventory">
            <TreeItem itemId="inventory.custom_site_management" label="Custom Site Management" />
            <TreeItem itemId="inventory.device_type" label="Device Type" />
          </TreeItem>
          <TreeItem itemId="bid_section" label="Bid">
            <TreeItem itemId="bid.custom_max_bid_per_step" label="Custom Max Bid per Step" />
          </TreeItem>
        </SimpleTreeView>
      </DialogContent>
    </Dialog>
  );
}

export default NewOptimizationModal;
