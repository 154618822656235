import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import ContentCategoriesSelector from "./components/ContentCategoriesSelector";

import { Form, useActionData } from "react-router-dom";

const ADVERTISER_TYPES = [
  { value: "brand_shopper", text: "Brand / Shopper" },
  { value: "publisher", text: "Publisher" },
  { value: "test_internal", text: "N/A (Test / Internal)" },
];

const AdvertiserForm = ({
  action,
  isEditing = false,
  advertiserCategories,
  advertiser = {
    advertiser_categories: [],
    domain: null,
    id: null,
    name: null,
  },
}) => {
  const { errors = {}, ...record } = useActionData() || {};

  return (
    <>
      <Typography variant="h5" sx={{ mb: 3.875 }}>
        {isEditing ? "Edit" : "New"} Advertiser
      </Typography>

      {record?.id && (
        <Alert severity="success" sx={{ mb: 3 }}>
          Advertiser was updated successfully!
        </Alert>
      )}

      <Form action={action} method="post" name="advertiser-form">
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="center">
          <Grid item xs={12} style={{ paddingTop: 21 }}>
            <TextField
              name="name"
              fullWidth
              label="Advertiser Name"
              required
              error={"name" in errors}
              helperText={errors["name"]}
              defaultValue={advertiser.name}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 21 }}>
            <FormControl required error={"type" in errors}>
              <InputLabel id="advertiser-label">Advertiser Type</InputLabel>
              <Select
                name="type"
                labelId="advertiser-label"
                label="Advertiser Type *"
                required
                sx={{ minWidth: 220 }}
                defaultValue={advertiser.type ?? ""}
              >
                {ADVERTISER_TYPES.map(({ value, text }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
              {"type" in errors && (
                <FormHelperText aria-describedby="mui-component-select-type">
                  {errors.type}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 21 }}>
            <TextField
              name="domain"
              label="Domain"
              required
              variant="standard"
              error={"domain" in errors}
              helperText={errors["domain"]}
              sx={{ minWidth: 220 }}
              defaultValue={advertiser.domain}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 21 }}>
            <ContentCategoriesSelector
              defaultValue={advertiser.advertiser_categories}
              options={advertiserCategories}
              error={"advertiser_category_ids" in errors}
              helperText={errors["advertiser_category_ids"]}
            />
          </Grid>
          <Grid
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            sx={{ textAlign: "center" }}
          >
            <Button type="submit" variant="contained" sx={{ minWidth: 350 }}>
              {isEditing ? "SAVE" : "CREATE"}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default AdvertiserForm;
