import {
  Typography
} from "@mui/material";

import { useOptimizationChanges } from "../OptimizationsContext";

const OptimizationFieldGroup = ({ rules, RuleField, title, currentPriorities }) => {
  const { errors } = useOptimizationChanges();

  if (rules.length === 0) {
    return;
  }

  return (
    <>
      {title && <Typography variant="body1" sx={{ mb: 1.75 }}>{title}</Typography>}
      {rules.map(rule => <RuleField rule={rule} key={rule.id} errors={errors[rule.id]} currentPriorities={currentPriorities} />)}
    </>
  )
}

export default OptimizationFieldGroup;
