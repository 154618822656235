import {
  Autocomplete as MuiAutocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  autocompleteClasses
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import { styled } from "@mui/material/styles";

import TextField from "./TextField"
import OptimizationFieldGroup from "./OptimizationFieldGroup";

import { useOptimizationChangesDispatch } from "../OptimizationsContext";

import {
  RULE_PRIORITIES_OPTIONS, DEVICE_TYPE_OPTIONS, INVENTORY_DEVICE_TYPE_SECTION, INVENTORY_CUSTOM_MANAGEMENT_SECTION, formFieldBuilder
} from "../utils/optimizationFormUtils.js";

import { listify } from "radash";

const Autocomplete = styled(MuiAutocomplete)({
  [`& :hover .${autocompleteClasses.input}, .${autocompleteClasses.focused} .${autocompleteClasses.input}`]: {
    minWidth: "30px"
  }
});

const TargetingField = ({ rule, errors = {} }) => {
  const dispatch = useOptimizationChangesDispatch();
  const { priority, value = { value: [], target: "", action: "" }, editable } = rule;

  const handleUpdate = (value) =>
    dispatch({ action: "rule_updated", section: INVENTORY_CUSTOM_MANAGEMENT_SECTION, ...rule, ...value });

  const handleRemove = (_event) =>
    dispatch({ action: "rule_removed", section: INVENTORY_CUSTOM_MANAGEMENT_SECTION, ...rule });

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      sx={{ mb: 2 }}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        columnSpacing={2}
        sx={{ mb: 1 }}
        direction="row"
      >
        <Grid item xs={4}>
          <TextField
            select
            size="small"
            label="Priority"
            value={priority}
            disabled={!editable}
            fullWidth
            error={"priority" in errors}
            helperText={errors.priority ?? ""}
            onChange={(event) => handleUpdate({ priority: event.target.value })}
          >
          <MenuItem value="">Select...</MenuItem>
            {(editable ? RULE_PRIORITIES_OPTIONS : [priority]).map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            size="small"
            label="Target/Block"
            value={value.action ?? ""}
            disabled={!editable}
            fullWidth
            error={"action" in errors}
            helperText={errors.action ?? ""}
            onChange={(event) => handleUpdate({ value: { ...value, action: event.target.value } })}
          >
            <MenuItem value="" key="default">Select...</MenuItem>
            <MenuItem value="target" key="target">Target</MenuItem>
            <MenuItem value="block" key="block">Block</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            select
            size="small"
            label="Type"
            value={value.target}
            disabled={!editable}
            fullWidth
            error={"target" in errors}
            helperText={errors.target ?? ""}
            onChange={(event) => handleUpdate({ value: { ...value, target: event.target.value } })}
          >
            <MenuItem value="" key="default">Select...</MenuItem>
            <MenuItem value="ad_network" key="ad_network">Ad Network</MenuItem>
            <MenuItem value="placement" key="placement">Placement</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={1}>
          {editable && (
            <IconButton aria-label="delete" onClick={handleRemove}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <TextField
          fullWidth
          variant="standard"
          label="List"
          size="small"
          disabled={!editable}
          onChange={(event) => {
            const currentValue = event.target.value;
            const newValue = currentValue === "" ? [] : currentValue.split(",")

            handleUpdate({ value: { ...value, value: newValue } })
          }}
          value={value.value.join(",")}
          error={"value" in errors}
          helperText={errors.value ?? ""}
        />
      </Grid>
    </Grid>
  )
}

const DeviceTypeTargetingField = ({ rule, errors = {} }) => {
  const dispatch = useOptimizationChangesDispatch();
  const { priority, value = { value: [], action: "" }, editable } = rule;
  const selectedOptions = DEVICE_TYPE_OPTIONS.filter((option) => value?.value?.includes(option.value))

  const handleUpdate = (value) =>
    dispatch({ action: "rule_updated", section: INVENTORY_DEVICE_TYPE_SECTION, ...rule, ...value });

  const handleRemove = (_event) =>
    dispatch({ action: "rule_removed", section: INVENTORY_DEVICE_TYPE_SECTION, ...rule });

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      columnSpacing={2}
      sx={{ mb: 2 }}
    >
      <Grid item xs={4}>
        <TextField
          select
          size="small"
          label="Priority"
          value={priority}
          disabled={!editable}
          fullWidth
          onChange={(event) => handleUpdate({ priority: event.target.value })}
          error={"priority" in errors}
          helperText={errors.priority ?? ""}
        >
        <MenuItem value="">Select...</MenuItem>
          {RULE_PRIORITIES_OPTIONS.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          select
          size="small"
          label="Target/Block"
          value={value.action ?? ""}
          disabled={!editable}
          fullWidth
          onChange={(event) => handleUpdate({ value: { ...value, action: event.target.value } })}
          error={"action" in errors}
          helperText={errors.action ?? ""}
        >
          <MenuItem value="">Select...</MenuItem>
          <MenuItem value="target" key="target">Target</MenuItem>
          <MenuItem value="block" key="block">Block</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          value={selectedOptions}
          disabled={!editable}
          size="small"
          options={DEVICE_TYPE_OPTIONS}
          multiple
          fullWidth
          getOptionLabel={({ label }) => label}
          isOptionEqualToValue={({ value }, { value: otherValue }) => value === otherValue}
          renderInput={(params) => <TextField {...params} label="Device Type" variant="standard" error={"value" in errors} helperText={errors.value ?? ""} />}
          onChange={(event, newValue) => handleUpdate({ value: { ...value, value: newValue.map(({ value }) => value) }})}
          renderTags={(value, getTagProps) =>
            value.map(({ label }, index) => (
              <Chip
                label={label}
                variant="outlined"
                color="primary"
                size="small"
                {...getTagProps({ index })}
                sx={{ color: "text.primary", fontSize: "0.75rem", lineSpacing: 0.4, lineLength: 1.7 }}
              />
            ))
          }
        />
      </Grid>
      <Grid item xs={1}>
        {editable && (
          <IconButton aria-label="delete" onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

const FIELDS_COMPONENT = {
  custom_site_management: (field, rules) => formFieldBuilder(OptimizationFieldGroup, field, { RuleField: TargetingField, rules }),
  device_type: (field, rules) => formFieldBuilder(OptimizationFieldGroup, field, { RuleField: DeviceTypeTargetingField, rules })
}

const InventoryFormSection = (changes) => {
  const { custom_site_management = [], device_type = [] } = changes;

  if (custom_site_management.length === 0 && device_type.length === 0) {
    return;
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>Inventory</Typography>
      {listify(changes, (field, props) => FIELDS_COMPONENT[field](field, props))}
    </Box>
  )
}

export default InventoryFormSection;
