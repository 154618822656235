import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  json,
  redirect,
  useActionData,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
  useSubmit,
} from "react-router-dom";
import { post } from "../../../apiClient";
import createFormErrors from "../../../utils/createFormErrors";
import humanize from "../utils/humanize";

export async function action({ params, request }) {
  try {
    await post("audience", `/v1/segments/${params.id}/deactivate`, {
      signal: request.signal,
    });

    return redirect(`/segments/${params.id}`);
  } catch (error) {
    switch (error.status) {
      case 503:
        return json(
          {
            errors: createFormErrors([
              {
                title:
                  "Couldn't mark the segment for deletion. A deactivation job for the segment is likely already running.",
              },
            ]),
          },
          { status: 503 }
        );
      /* istanbul ignore next */
      default:
        throw error;
    }
  }
}

const SegmentDeactivationDialog = () => {
  const form = useActionData();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const segment = useRouteLoaderData("segment");
  const submit = useSubmit();

  return (
    <Dialog onClose={() => navigate("..")} open>
      <DialogTitle>Mark {humanize(segment)} for deletion</DialogTitle>
      <DialogContent>
        {form?.errors?.hasOwnProperty("/") && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {form.errors["/"]}
          </Alert>
        )}
        <DialogContentText>
          Are you sure you want to mark the segment for deletion? This will
          inactivate the segment and dissociate it from all targeted
          identifiers.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={navigation.state === "submitting"}
          onClick={() => navigate("..")}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadingButton
          loading={navigation.state === "submitting"}
          onClick={() => submit(null, { method: "post" })}
          type="submit"
          variant="contained"
        >
          Mark for deletion
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SegmentDeactivationDialog;
