import { ActivityLogsTab } from "../features/managed_plus_credits/ActivityLogsTab";
import { ContactsTab } from "../features/managed_plus_credits/ContactsTab";
import { CreditsTab } from "../features/managed_plus_credits/CreditsTab";
import useTitle from "../hooks/useTitle";

import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Box, Button, ButtonGroup } from "@mui/material";

const NewRecordButton = ({ tab }) => {
  const navigateTo = tab === "contacts" ? "contacts/new" : "new";
  const navigate = useNavigate();

  if (!isCreditOrContactTab(tab)) {
    return null;
  }

  return (
    <ButtonGroup>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={() => navigate(`/managed-plus/credits/${navigateTo}`)}
      >
        NEW {tab === "contacts" ? "CONTACT" : "CREDIT"}
      </Button>
    </ButtonGroup>
  );
};

const isCreditOrContactTab = (tab) => ["credits", "contacts"].includes(tab);

const ManagedPlusCredits = () => {
  useTitle("M+ Campaign Credits");
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") ?? "credits";

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <ButtonGroup>
          <Button
            variant={tab === "credits" ? "contained" : "outlined"}
            onClick={(e) => {
              e.preventDefault();
              setSearchParams({ tab: "credits" });
            }}
          >
            CREDITS
          </Button>
          <Button
            variant={tab === "contacts" ? "contained" : "outlined"}
            onClick={(e) => {
              e.preventDefault();
              setSearchParams({ tab: "contacts" });
            }}
          >
            BUSINESS CONTACTS
          </Button>
          <Button
            variant={tab === "activity_logs" ? "contained" : "outlined"}
            onClick={(e) => {
              e.preventDefault();
              setSearchParams({ tab: "activity_logs" });
            }}
          >
            ACTIVITY LOG
          </Button>
        </ButtonGroup>
        <NewRecordButton tab={tab} />
      </Box>
      {tab === "credits" && <CreditsTab />}
      {tab === "contacts" && <ContactsTab />}
      {tab === "activity_logs" && <ActivityLogsTab />}
    </>
  );
};

export default ManagedPlusCredits;
