import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";

import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";

const columnsWithActions = (
  columns,
  {
    actions: { edit, remove, copy },
    onEditActionClick,
    onCopyActionClick,
    onDeleteActionClick,
  }
) => {
  if (!edit && !copy && !remove) return columns;

  const renderCell = (params) => {
    const disabled = !(
      params.row.editable === undefined || params.row.editable
    );
    return (
      <>
        {edit && (
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            onClick={() => onEditActionClick(params)}
            label="edit"
            disabled={disabled}
          />
        )}
        {copy && (
          <GridActionsCellItem
            icon={<ContentCopyOutlinedIcon />}
            onClick={() => onCopyActionClick(params)}
            label="copy"
          />
        )}
        {remove && (
          <GridActionsCellItem
            icon={<DeleteOutlineOutlinedIcon />}
            onClick={() => onDeleteActionClick(params)}
            label="delete"
            disabled={disabled}
          />
        )}
      </>
    );
  };

  return [...columns, { field: "actions", type: "actions", renderCell }];
};

const SearchDataGrid = ({
  columns,
  DataGridProps,
  sortModel,
  pageSize,
  page,
  onPaginationChange,
  onSortChange,
  searchResult,
  onConfirm,
  InitialState,
  actions = {
    edit: false,
    remove: false,
  },
  onEditActionClick,
  onCopyActionClick,
  onDeleteConfirm,
  onDeleteComplete,
  customFooter,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const gridColumns = columnsWithActions(columns, {
    actions,
    onEditActionClick,
    onCopyActionClick,
    onDeleteActionClick: (params) => {
      setCurrentRow(params);
      setDialogOpen(true);
    },
  });

  let customComponents = {};

  if (!!customFooter) {
    customComponents.Footer = () => customFooter(searchResult);
  }
  const currentPage = searchResult.totalCount === 0 && page !== 0 ? 0 : page;

  return (
    <>
      <DataGrid
        columns={gridColumns}
        columnBuffer={gridColumns.length + 1}
        rowHeight={32}
        columnHeaderHeight={36}
        components={customComponents}
        localeText={{
          noRowsLabel: searchResult.error ?? "No rows",
        }}
        sx={{
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
          "& .MuiCheckbox-root svg": {
            width: 15,
            height: 15,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: 0,
          },
          border: "none",
        }}
        sortingOrder={["desc", "asc"]}
        sortModel={sortModel}
        paginationModel={{ pageSize, page: currentPage }}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        onPaginationModelChange={onPaginationChange}
        sortingMode="server"
        onSortModelChange={onSortChange}
        paginationMode="server"
        pageSizeOptions={[25, 50, 100]}
        checkboxSelection
        {...DataGridProps}
        rows={searchResult.result}
        rowCount={searchResult.totalCount}
      />
      <ConfirmDeleteDialog
        open={dialogOpen}
        onConfirm={async (currentRow) => {
          await onDeleteConfirm(currentRow);
          setCurrentRow(null);
          onDeleteComplete(currentRow);
        }}
        onClose={() => {
          setDialogOpen(false);
          setCurrentRow(null);
        }}
        currentRow={currentRow}
      />
    </>
  );
};

export { SearchDataGrid };
