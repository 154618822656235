import { createContext, useContext, useEffect } from "react";

export const TitleContext = createContext({
  title: "",
  setTitle: () => {},
});

const useTitle = (newTitle) => {
  const { setTitle, title } = useContext(TitleContext);

  useEffect(() => {
    if (newTitle !== undefined) {
      setTitle(newTitle);
    }
  }, [setTitle, newTitle]);

  return title;
};

export default useTitle;
