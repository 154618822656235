import { LoadingButton } from "@mui/lab";
import { Box, InputAdornment, TextField } from "@mui/material";
import { isNumber } from "radash";
import { useState } from "react";
import { Form, useActionData, useNavigation } from "react-router-dom";
import createFormErrors from "../../../utils/createFormErrors";
import humanize from "../utils/humanize";

function SegmentForm({
  action,
  isEditing = false,
  segment = {
    active: true,
    cpm_in_cents: null,
    human_readable_name: null,
    name: null,
    provider: null,
    segment_type: null,
    source: null,
  },
  segmentTypes,
}) {
  const submissionErrors = useActionData();
  const formErrors = createFormErrors(submissionErrors);
  const navigation = useNavigation();
  const [cpmInCents, setCpmInCents] = useState(segment.cpm_in_cents);
  const [selectedSegmentType, setSelectedSegmentType] = useState(
    segment.segment_type?.id
  );

  return (
    <Box
      action={action}
      component={Form}
      name="segment-form"
      method="post"
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      <input type="hidden" name="active" value={segment.active} />
      <input type="hidden" name="cpm_in_cents" value={cpmInCents ?? ""} />

      <TextField
        defaultValue={segment.name}
        disabled={isEditing}
        error={"/name" in formErrors}
        helperText={formErrors["/name"]}
        id="key"
        label="Segment key"
        name="name"
        required
      />

      <TextField
        defaultValue={segment.human_readable_name}
        error={"/human_readable_name" in formErrors}
        helperText={formErrors["/human_readable_name"]}
        id="name"
        label="Segment name"
        name="human_readable_name"
      />

      <TextField
        InputLabelProps={{ shrink: true }}
        SelectProps={{ native: true }}
        disabled={isEditing}
        error={"/segment_type_id" in formErrors}
        helperText={formErrors["/segment_type_id"]}
        id="segment-type-id"
        label="ID Type"
        name="segment_type_id"
        onChange={(e) => setSelectedSegmentType(e.target.value)}
        required
        select
        value={!selectedSegmentType ? "" : selectedSegmentType}
      >
        {segmentTypes.map((segmentType) => (
          <option key={segmentType.id} value={segmentType.id}>
            {humanize(segmentType)}
          </option>
        ))}
      </TextField>

      <TextField
        defaultValue={segment.source}
        error={"/source" in formErrors}
        helperText={formErrors["/source"]}
        id="source"
        label="Source"
        name="source"
        required
      />

      <TextField
        defaultValue={isNumber(cpmInCents) ? cpmInCents / 100 : ""}
        error={"/cpm_in_cents" in formErrors}
        helperText={formErrors["/cpm_in_cents"]}
        id="cpm_in_cents"
        inputProps={{
          inputMode: "numeric",
          pattern: "^\\d+([.]\\d+)?$",
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        label="CPM"
        onChange={(e) => {
          if (e.target.checkValidity() && e.target.value !== "") {
            setCpmInCents(Math.round(parseFloat(e.target.value) * 100));
          } else {
            setCpmInCents(null);
          }
        }}
      />

      <TextField
        defaultValue={segment.provider}
        error={"/provider" in formErrors}
        helperText={formErrors["/provider"]}
        id="provider"
        label="Provider"
        name="provider"
      />

      <LoadingButton
        loading={navigation.state === "submitting"}
        sx={{ justifySelf: "left" }}
        type="submit"
        variant="contained"
      >
        {segment.id ? "Save Changes" : "Create Segment"}
      </LoadingButton>
    </Box>
  );
}

export default SegmentForm;
