import { Alert, Button, Grid, Paper, Snackbar } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { pick } from "radash";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchPlacementPrebidConfigurations } from "../features/placement_prebid_configurations/apiService";
import BulkCreateDialog from "../features/placement_prebid_configurations/BulkCreateDialog";
import PlacementPrebidConfigurationFiltering from "../features/placement_prebid_configurations/components/PlacementPrebidConfigurationFiltering";
import PlacementPrebidConfigurationsDataGrid from "../features/placement_prebid_configurations/components/PlacementPrebidConfigurationsDataGrid";
import { fetchPrebidAccount } from "../features/prebid_accounts/apiService";
import useTitle from "../hooks/useTitle";

const FILTER_PARAMS = ["search"];

const PAGINATION_DEFAULTS = {
  per_page: "25",
  page: "1",
  order_by: "id",
  order_direction: "asc",
};

function searchParamsToApiParams(searchParams) {
  const params = Object.fromEntries(searchParams);

  return {
    ...pick(params, FILTER_PARAMS),
    ...PAGINATION_DEFAULTS,
    ...pick(params, Object.keys(PAGINATION_DEFAULTS)),
  };
}

const PlacementPrebidconfigurations = () => {
  useTitle("");

  const [searchParams] = useSearchParams(PAGINATION_DEFAULTS);
  const { prebid_account_id } = useParams();
  const params = searchParamsToApiParams(searchParams);
  const filters = pick(params, FILTER_PARAMS);

  params["prebid_account_id"] = prebid_account_id;

  const query = useQuery({
    queryKey: ["PlacementPrebidConfigurations", params],
    queryFn: fetchPlacementPrebidConfigurations,
  });

  const { data } = useQuery({
    queryKey: ["Publisher", prebid_account_id],
    queryFn: fetchPrebidAccount,
  });

  useTitle(
    data
      ? "Prebid Placement Configurations > " + data?.publisher_name
      : "Prebid Placement Configurations"
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openBulkCreateDialog = (placement, isEdit) => {
    setIsDialogOpen(true);
  };

  const closeBulkCreateDialog = () => {
    setIsDialogOpen(false);
  };

  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={openBulkCreateDialog}
              style={{ float: "right" }}
            >
              ADD PLACEMENTS
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: 0, marginTop: 0 }}>
            <PlacementPrebidConfigurationFiltering filters={filters} />
            <PlacementPrebidConfigurationsDataGrid
              records={query.data ?? []}
              loading={query.isLoading}
              setSnackbar={setSnackbar}
            />
          </Grid>
        </Grid>
      </Paper>
      <BulkCreateDialog
        open={isDialogOpen}
        onClose={closeBulkCreateDialog}
        setSnackbar={setSnackbar}
        prebidAccountId={prebid_account_id}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
          data-testid="form-snackbar"
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default PlacementPrebidconfigurations;
