import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import GroupsIcon from "@mui/icons-material/Groups";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import WebIcon from "@mui/icons-material/Web";
import { styled } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import { Link } from "react-router-dom";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) =>
    prop !== "isDrawerOpen" && prop !== "drawerWidth",
})(({ drawerWidth, theme, isDrawerOpen }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!isDrawerOpen && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(9),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Navigation = ({ drawerWidth, isDrawerOpen, toggleDrawer }) => {
  const [openMenu, setOpenMenu] = useState({});
  const toggleMenu = (menu) => {
    setOpenMenu((openedMenus) => {
      if (!isDrawerOpen) {
        toggleDrawer();
      }
      return { ...openedMenus, [menu]: !openedMenus[menu] };
    });
  };

  const handleDrawerButtonClick = () => {
    toggleDrawer();
    setOpenMenu({});
  };

  return (
    <Drawer
      variant="permanent"
      drawerWidth={drawerWidth}
      isDrawerOpen={isDrawerOpen}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={handleDrawerButtonClick}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />

      <List component="nav">
        <ListItemButton
          component="h4"
          onClick={() => toggleMenu("AdvertiserSettings")}
        >
          <ListItemIcon>
            <AdUnitsIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: 14 }}
            primary="Advertiser Settings"
          />
        </ListItemButton>

        <Collapse in={openMenu.AdvertiserSettings} timeout="auto" unmountOnExit>
          <Divider />

          <ListItemButton component={Link} to="/advertisers" sx={{ pl: 9 }}>
            <ListItemText
              primaryTypographyProps={{ fontSize: 14 }}
              primary="Advertisers"
            />
          </ListItemButton>
        </Collapse>

        <ListItemButton
          component="h4"
          onClick={() => toggleMenu("AudienceTargeting")}
        >
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: 14 }}
            primary=" Audience Targeting"
          />
        </ListItemButton>

        <Collapse in={openMenu.AudienceTargeting} timeout="auto" unmountOnExit>
          <Divider />

          <ListItemButton component={Link} to="/audiences" sx={{ pl: 9 }}>
            <ListItemText
              primaryTypographyProps={{ fontSize: 14 }}
              primary="Audiences"
            />
          </ListItemButton>
          <ListItemButton component={Link} to="/" sx={{ pl: 9 }}>
            <ListItemText
              primaryTypographyProps={{ fontSize: 14 }}
              primary="Segments"
            />
          </ListItemButton>
        </Collapse>

        <ListItemButton component="h4" onClick={() => toggleMenu("TechTools")}>
          <ListItemIcon>
            <QueryBuilderIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: 14 }}
            primary="Tech Tools"
          />
        </ListItemButton>

        <Collapse in={openMenu.TechTools} timeout="auto" unmountOnExit>
          <Divider />

          <ListItemButton component={Link} to="/targeting_jobs" sx={{ pl: 9 }}>
            <ListItemText
              primaryTypographyProps={{ fontSize: 14 }}
              primary="Targeting jobs"
            />
          </ListItemButton>
        </Collapse>

        <ListItemButton
          component="h4"
          onClick={() => toggleMenu("ManagedPlus")}
        >
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: 14 }}
            primary="Managed+"
          />
        </ListItemButton>

        <Collapse in={openMenu.ManagedPlus} timeout="auto" unmountOnExit>
          <Divider />

          <ListItemButton
            component={Link}
            to="/managed-plus/credits"
            sx={{ pl: 9 }}
          >
            <ListItemText
              primaryTypographyProps={{ fontSize: 14 }}
              primary="Managed+ credits"
            />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/managed-plus/podcast-campaigns"
            sx={{ pl: 9 }}
          >
            <ListItemText
              primaryTypographyProps={{ fontSize: 14 }}
              primary="Podcast campaigns"
            />
          </ListItemButton>
        </Collapse>

        <ListItemButton
          component="h4"
          onClick={() => toggleMenu("PrebidConfiguration")}
        >
          <ListItemIcon>
            <WebIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: 14 }}
            primary="Prebid Configuration"
          />
        </ListItemButton>

        <Collapse
          in={openMenu.PrebidConfiguration}
          timeout="auto"
          unmountOnExit
        >
          <Divider />

          <ListItemButton component={Link} to="/publishers" sx={{ pl: 9 }}>
            <ListItemText
              primaryTypographyProps={{ fontSize: 14 }}
              primary="Publishers"
            />
          </ListItemButton>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default Navigation;
