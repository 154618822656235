import { get, patch } from "../../apiClient";

export const fetchPublishers = async ({ queryKey }) => {
  let params = queryKey[1];

  try {
    const {
      data: publishers,
      pagination: { total_count },
    } = await get("player", "/api/v1/publishers.json", {
      params,
    });

    return { params, publishers, total_count };
  } catch (error) {
    throw error;
  }
};

export const updateAssociatedPrebidAccount = async (attributes) => {
  try {
    const data = await patch(
      "player",
      `/api/v1/publishers/${attributes.id}/prebid_account`,
      {
        publisher: attributes,
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};
