import CalendarMonth from "@mui/icons-material/CalendarMonth";
import { Box, Input, InputAdornment } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";

const DEFAULT_DATE_FORMAT = "MM/dd/yy";
const defaultStyle = { width: 100, fontSize: 13 };

const DateRangeInput = ({ startProps, endProps, ...props }) => {
  const [isStartDateOpen, setStartDateOpen] = useState(false);
  const [isEndDateOpen, setEndDateOpen] = useState(false);
  const { onChange: handleStartDateChange, value: startDateValue } = startProps;
  const { onChange: handleEndDateChange, value: endDateValue } = endProps;
  const { sx, ...Props } = props;

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", ...sx }} {...Props}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          open={isStartDateOpen}
          inputFormat={DEFAULT_DATE_FORMAT}
          onChange={handleStartDateChange}
          value={startDateValue}
          onClose={() => {
            setStartDateOpen(false);
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Input
              name="startDate"
              inputProps={{ ...inputProps, "aria-label": "startDate" }}
              sx={{ ...defaultStyle, ...inputProps?.sx }}
              ref={inputRef}
              onClick={() => setStartDateOpen(true)}
              startAdornment={
                <InputAdornment position="start">
                  <CalendarMonth />
                </InputAdornment>
              }
              placeholder={"MM/DD/YY"}
            />
          )}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          open={isEndDateOpen}
          inputFormat={DEFAULT_DATE_FORMAT}
          onChange={handleEndDateChange}
          value={endDateValue}
          onClose={() => setEndDateOpen(false)}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Input
              name="endDate"
              inputProps={{ ...inputProps, "aria-label": "endDate" }}
              sx={{ ml: 1, ...defaultStyle, ...inputProps?.sx }}
              ref={inputRef}
              onClick={() => setEndDateOpen(true)}
              startAdornment={
                <InputAdornment position="start">
                  <CalendarMonth />
                </InputAdornment>
              }
              placeholder={"MM/DD/YY"}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateRangeInput;
