import { Box, Grid, Typography } from "@mui/material";
import { DateTime, Info } from "luxon";
import formatNumber from "./formatNumber";

export function parseWeekDays(value) {
  return value.length === 7
    ? "All"
    : value
        .map((dayIndex) => Info.weekdays()[dayIndex === 0 ? 6 : dayIndex - 1])
        .map((day) => day.slice(0, 3))
        .join(", ");
}

// https://moment.github.io/luxon/#/formatting
// h -> hour in 12-hour time, no padding
// a -> meridiem (AM/PM)
function convertToAMPM(hour) {
  // unfortunately we have to pad values from 0 to 9 otherwise the .fromISO will not parse the date
  return DateTime.fromISO(hour.toString().padStart(2, "0"))
    .toFormat("h a")
    .toLowerCase();
}

export function parseHourTags(value) {
  return value.map(convertToAMPM).join(" - ");
}

export function isCampaignComplete(endDate) {
  const campaignEnd = DateTime.fromISO(endDate)
    .setZone("America/New_York")
    .toISODate();
  const currentDate = DateTime.now().setZone("America/New_York").toISODate();
  return campaignEnd < currentDate;
}

export const Header = ({ value }) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ alignItems: "center", display: "flex" }}>
        <Typography sx={{ mr: 1 }} variant="h5">
          {value}
        </Typography>
        <Grid item xs={6}>
          &nbsp;
        </Grid>
      </Box>
    </Box>
  );
};

export const CampaignProperty = ({ name, value, xs = 6 }) => {
  return (
    <Grid item xs={xs}>
      <Typography color="text.primary" variant="subtitle2">
        {name}
      </Typography>
      {value === "" && name !== "" ? "-" : value}
    </Grid>
  );
};

const TARGETING_COUNTRIES = [
  ["United States", "us"],
  ["Australia", "au"],
  ["Austria", "at"],
  ["Belgium", "be"],
  ["Canada", "ca"],
  ["France", "fr"],
  ["Germany", "de"],
  ["Greece", "gr"],
  ["Italy", "it"],
  ["Portugal", "pt"],
  ["Spain", "es"],
  ["United Kingdom", "gb"],
];

export function parseCountryNames(countryCode) {
  const country = TARGETING_COUNTRIES.find(
    ([name, code]) => code === countryCode
  );
  return country ? country[0] : null;
}

export const formatDate = (value) =>
  value &&
  DateTime.fromISO(value).setZone("America/New_York").toFormat("M/dd/yyyy");
export const formatBudget = (value, options = {}) =>
  parseFloat(value, 10)
    ? formatNumber(value, { currency: "USD", style: "currency", ...options })
    : null;
