import { LoadingButton } from "@mui/lab";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import {
  Form,
  json,
  redirect,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { post } from "../apiClient";

const idsFromQueryParams = (request) => {
  return Array.from(new URL(request.url).search.matchAll(/ids\[\]=\d+/g)).map(
    ([item]) => item.split("=")[1]
  );
};

const action = async ({ request }) => {
  const formData = await request.formData();
  const formEntries = Object.fromEntries(formData);
  formEntries.ids = idsFromQueryParams(request);

  try {
    await post("managed_plus", "/contacts/bulk_edit", formEntries);
    return redirect("/managed-plus/credits?tab=contacts");
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
};

const BulkEditManagedPlusContact = () => {
  const navigation = useNavigation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: 7.875,
      }}
    >
      <Form
        action={`/managed-plus/credits/contacts/bulk-edit${window.location.search}`}
        name="contactBulkEditForm"
        method={"post"}
      >
        <Box fullwidth="true" sx={{ mb: 3.875 }}>
          <Typography variant="h5">Bulk Edit Contact</Typography>
        </Box>
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            size="small"
            label="Status"
            select
            defaultValue={""}
            name="deactivated"
            sx={{ width: 220 }}
          >
            <MenuItem value="true" key="true">
              Inactive
            </MenuItem>
            <MenuItem value="false" key="false">
              Active
            </MenuItem>
          </TextField>
        </Box>
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            label="Account ID"
            name="account_id"
            size="small"
            sx={{ width: 220 }}
            variant="standard"
          />
        </Box>
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            id="name"
            name="name"
            label="Contact Name"
            sx={{ width: 220 }}
            variant="standard"
          />
        </Box>
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            id="email"
            name="email"
            label="Contact Email"
            sx={{ width: 734 }}
            variant="standard"
          />
        </Box>
        <Box
          sx={{
            pt: 5,
            pl: 7.25,
            pr: 7.25,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LoadingButton
            loading={navigation.state === "submitting"}
            type="submit"
            variant="contained"
            sx={{ width: 288 }}
          >
            Save
          </LoadingButton>
          <Button
            onClick={() => navigate("/managed-plus/credits?tab=contacts")}
            variant="outlined"
            sx={{ width: 288 }}
          >
            Cancel
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export { action };
export default BulkEditManagedPlusContact;
