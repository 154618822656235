import { useState, useMemo, useRef } from "react";

import FilterAltOffRoundedIcon from "@mui/icons-material/FilterAltOffRounded";

import {
  Grid,
  Link,
} from "@mui/material";

import AutocompleteFilter from "./AutocompleteFilter.js";

const createApiEndpoint = (path, query) => ({ service: "managed_plus", path, query })

const dispatchAutocompleteResetEvent = element =>
  element.dispatchEvent(new CustomEvent("autocompleteReset", { bubbles: true }))

const resetAllAutocompleteFields = (container) =>
  container.querySelectorAll("[aria-autocomplete=list]").forEach(el => dispatchAutocompleteResetEvent(el))

const CampaignFilters = ({
  onFilterChange,
  searchOptions = {},
  onClearAllFilters
}) => {
  const [toggle, setToggle] = useState(false);
  const filterSection = useRef(null);

  const handleClick = (event) => {
    event.preventDefault();
    setToggle(!toggle);
    onFilterChange({ showCompleted: !toggle });
  }

  const handleReset = (contextFilters = {}) => {
    resetAllAutocompleteFields(filterSection.current)
    setToggle(false);
    onClearAllFilters({})
  }

  const accountsApiEndpoint = useMemo(() => {
    return createApiEndpoint("/autocomplete/campaign_accounts", { ...searchOptions, showCompleted: toggle })
  }, [searchOptions, toggle])

  const offerIdsApiEndpoint = useMemo(() => {
    return createApiEndpoint("/autocomplete/offer_ids", { ...searchOptions, showCompleted: toggle })
  }, [searchOptions, toggle])

  const campaignNamesApiEndpoint = useMemo(() => {
    return createApiEndpoint("/autocomplete/campaign_names", { ...searchOptions, showCompleted: toggle })
  }, [searchOptions, toggle])

  const hideOrShowText = toggle ? "Hide" : "Show"

  return (
    <Grid container sx={{ py: 3, flexFlow: "nowrap" }} spacing={2} ref={filterSection}>
      <Grid item xs={3}>
        <AutocompleteFilter
          defaultOptionLabel="All Accounts"
          onChange={(_e, option) => {
            onFilterChange({ accountId: option.map(({ value }) => value) })
          }}
          inputProps={{ label: "Company Name" }}
          apiEndpoint={accountsApiEndpoint}
        />
      </Grid>
      <Grid item xs={3}>
        <AutocompleteFilter
          defaultOptionLabel="All Offers"
          onChange={(_e, option) => {
            onFilterChange({ fromOfferId: option.map(({ value }) => value) })
          }}
          inputProps={{ label: "Offer ID" }}
          apiEndpoint={offerIdsApiEndpoint}
        />
      </Grid>
      <Grid item xs={3}>
        <AutocompleteFilter
          defaultOptionLabel="All Campaign Names"
          onChange={(_e, option) => {
            onFilterChange({ campaignId: option.map(({ value }) => value) })
          }}
          inputProps={{ label: "Campaign Name" }}
          apiEndpoint={campaignNamesApiEndpoint}
        />
      </Grid>
      <Grid item sx={{ alignSelf: "center" }} xs={1}>
        <Link
          underline="none"
          component="button"
          href="#"
          variant="small"
          onClick={handleReset}
          sx={{ display: "flex", alignItems: "center", color: "primary1.main", fontSize: "0.8125rem" }}
        >
          {<FilterAltOffRoundedIcon />} Clear All Filters
        </Link>
      </Grid>
      <Grid item sx={{ alignSelf: "flex-end", display: "flex", justifyContent: "flex-end" }} xs={2}>
        <Link
          underline="none"
          component="button"
          href="#"
          variant="small"
          sx={{ textAlign: "right", pt: 1, color: "rgba(0, 0, 0, 0.54)", fontSize: "13px", lineHeight: "15.25px" }}
          onClick={handleClick}
        >
          {hideOrShowText} Completed Campaigns
        </Link>
      </Grid>
    </Grid>
  )
}

export default CampaignFilters;
