import { Box } from "@mui/material";

import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { SearchDataGrid } from "./components/SearchDataGrid";
import {
  filterResourceBy,
  filtersFromSearchParams,
} from "./utils/searchUtils.js";

const ActivityLogsSearchColumns = [
  { headerName: "Record Type", field: "item_type", flex: 1, sortable: false },
  { headerName: "Record ID", field: "item_id", flex: 1, sortable: false },
  {
    headerName: "Changes",
    field: "object_changes",
    sortable: false,
    flex: 1,
    valueGetter: ({ row }) => JSON.stringify(row.object_changes),
  },
  { headerName: "Action", field: "event", flex: 1, sortable: false },
  { headerName: "Changed By", field: "whodunnit", flex: 1, sortable: false },
  {
    headerName: "Changed At",
    field: "created_at",
    flex: 1,
    valueFormatter: ({ value }) => {
      return DateTime.fromISO(value)
        .setZone("America/New_York")
        .toLocaleString(DateTime.DATETIME_FULL);
    },
  },
];

const DEFAULT_RESULT = { result: [], totalCount: 0 };

const ActivityLogsTab = () => {
  const [result, setResult] = useState(DEFAULT_RESULT);
  const [filters, setFilters] = useState(filtersFromSearchParams());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    filterResourceBy("activity_logs", filters)
      .onSuccess(({ result: records, total_count: totalCount }) => {
        setResult({ result: records, totalCount });
      })
      .onError((error) => {
        setResult({
          ...DEFAULT_RESULT,
          error: "Failed to fetch activity logs from server",
        });
      })
      .done(() => setIsLoading(false))
      .run();
  }, [filters]);

  return (
    <div aria-label="Activity logs list">
      <Box sx={{ height: 640, pt: 4 }}>
        <SearchDataGrid
          DataGridProps={{ checkboxSelection: false, loading: isLoading }}
          sortModel={filters.order}
          onSortChange={(model) =>
            setFilters((params) => ({ ...params, order: model }))
          }
          onPaginationChange={({ pageSize: perPage, page }) => {
            const currentPage = filters.perPage === perPage ? page : 0;
            setFilters((params) => ({ ...params, perPage, page: currentPage }));
          }}
          searchResult={result}
          page={filters.page}
          pageSize={filters.perPage}
          columns={ActivityLogsSearchColumns}
        />
      </Box>
    </div>
  );
};

export { ActivityLogsTab };
