import { Grid, Paper } from "@mui/material";
import { json, redirect, useRouteLoaderData } from "react-router-dom";
import { post } from "../apiClient";
import SegmentForm from "../features/segments/components/SegmentForm";
import useTitle from "../hooks/useTitle";

export async function action({ request }) {
  const formData = await request.formData();
  const formEntries = Object.fromEntries(formData);

  // We need to explicitly set cpm_in_cents to `null` in case it's an
  // empty string (`""`).
  formEntries["cpm_in_cents"] = formEntries["cpm_in_cents"] || null;

  try {
    const segment = await post("audience", "/v1/segments", formEntries);
    return redirect(`/segments/${segment.id}`);
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details.errors, { status: error.status });
      }
      default:
        throw error;
    }
  }
}

export default function NewSegment() {
  const segmentTypes = useRouteLoaderData("segmentTypes");

  useTitle("New Segment");

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <SegmentForm action="/segments/new" segmentTypes={segmentTypes} />
        </Paper>
      </Grid>
    </Grid>
  );
}
