import { Box, LinearProgress, Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGrid, GridEditInputCell } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { isNumber } from "radash";
import StyledDataGrid from "../../../components/StyledDataGrid";
import formatNumber from "../../../utils/formatNumber";
import { updatePlacementPrebidConfiguration } from "../apiService";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const shouldPreventKeyPress = (key, currentValue) => {
  if (isNaN(key) || key === " ") return true;

  return !!key.match(/^[0-9]$/) && parseInt([currentValue, key].join(""), 10) > 100;
}

const NumberEditCell = (props) => {
  const { error } = props;

  const handleKeyPress = (event) => {
    shouldPreventKeyPress(event.key, event.target.value) && event.preventDefault();
  }

  return (
    <StyledTooltip open={!!error} title={"value should be a number between 0 and 100"}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <GridEditInputCell
          onKeyPress={handleKeyPress}
          inputProps={{
            min: 0,
            max: 100,
            step: 1
          }}
          {...props}
        />
      </Box>
    </StyledTooltip>
  )
}

const isPercentage = (rev_share) =>
  typeof rev_share === "number" && rev_share >= 0 && rev_share <= 1;


const PlacementPrebidConfigurationsDataGrid = ({
  records,
  loading,
  setSnackbar,
}) => {
  const columns = [
    {
      field: "placement_id",
      headerName: "Placement ID",
      width: 150,
    },
    {
      field: "prebid_rev_share",
      headerName: "Prebid Hypr Rev Share",
      type: "number",
      width: 250,
      editable: true,
      valueGetter: ({ value }) => {
        return typeof value == "number" ? (value * 100).toFixed(0) : value;
      },
      valueFormatter: ({ value }) => value && `${value.toLocaleString()} %`,
      valueSetter: ({ row, value }) => {
        return { ...row, prebid_rev_share: value / 100 };
      },
      preProcessEditCellProps: ({ id, row, props }) => {
        const value = parseInt(props.value, 10);

        return {
          ...props,
          error: isNaN(value) || !isPercentage(value / 100),
        };
      },
      renderEditCell: (params) => <NumberEditCell {...params} />,
    },
    {
      field: "weight",
      headerName: "Traffic Allocation to Prebid",
      type: "number",
      width: 250,
      editable: true,
      renderEditCell: (params) => <NumberEditCell {...params} />,
      valueGetter: ({ value }) => {
        if (typeof value == "number") {
          return (value * 100).toFixed(0);
        } else {
          return value;
        }
      },
      valueFormatter: ({ value }) => {
        if (isNumber(parseInt(value, 10))) {
          return formatNumber(value / 100, { style: "percent" });
        }

        return "";
      },
      valueSetter: (params) => {
        const value = !!params.value ? params.value / 100 : params.value;
        return { ...params.row, weight: value };
      },
      preProcessEditCellProps: (params) => {
        let isValid;
        let value = parseInt(params.props.value, 10);

        if (isNaN(value)) {
          isValid = false;
        } else {
          isValid = isPercentage(value / 100);
        }

        return { ...params.props, error: !isValid };
      },
    },
  ];

  const updatePlacementPrebidConfigurationMutation = useMutation({
    mutationFn: updatePlacementPrebidConfiguration,
    onSuccess: (params) => {
      setSnackbar({
        children: "Configuration successfully updated",
        severity: "success",
      });
    },
    onError: (error) => {
      setSnackbar({
        children:
          "Configuration failed to be updated. If the error is persistent, contact support.",
        severity: "error",
      });
    },
  });

  const processRowUpdate = (row) => {
    return new Promise((resolve, reject) => {
      if (isPercentage(row.prebid_rev_share) && isPercentage(row.weight)) {
        updatePlacementPrebidConfigurationMutation
          .mutateAsync(row)
          .then(({ data: result }) => resolve(result))
          .catch((error) => reject(error))
      } else {
        resolve(row)
      }
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <StyledDataGrid>
        <DataGrid
          autoHeight
          data-testid="placement-prebid-configurations-table"
          rows={records}
          columns={columns}
          getRowId={({ placement_id }) => placement_id}
          processRowUpdate={processRowUpdate}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
        />
      </StyledDataGrid>
    </div>
  );
};

export default PlacementPrebidConfigurationsDataGrid;
