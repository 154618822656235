import { get } from "../../../apiClient";

const AUTOCOMPLETE_DEFAULT_LIMIT = 15;

const createAutocompleteOptions = (segments) =>
  segments.map(({ id, human_readable_name, name }) => ({
    id,
    label: human_readable_name || name,
  }));

const getServerOptions = async (searchTerm) =>
  get("audience", "/v1/segments", {
    params: { pagination: { limit: AUTOCOMPLETE_DEFAULT_LIMIT }, searchTerm },
  }).then(({ result }) => createAutocompleteOptions(result));

export { createAutocompleteOptions, getServerOptions };
