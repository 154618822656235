import { DateTime } from "luxon";

const DEFAULT_DATE_FORMAT = "MM/dd/yy";

export const convertToDate = (date, format = DEFAULT_DATE_FORMAT) => {
  if ([undefined, null, ""].includes(date)) {
    return null;
  }

  return DateTime.fromFormat(date, format);
};

export const fromSQLToDate = (date) => {
  if ([undefined, null, ""].includes(date)) {
    return null;
  }

  return DateTime.fromSQL(date).toFormat(DEFAULT_DATE_FORMAT);
};
