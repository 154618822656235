import { createContext, useContext, useReducer } from "react";

import { shake, isEmpty } from "radash";

export const OptimizationChangesContext = createContext(null);
export const OptimizationsDispatchContext = createContext(null);

const initialState = (props) => {
  const { pacing, bid, creative, geography, audience, inventory } = props;

  return {
    errors: {},
    removedRuleIds: [],
    ...shake({
      "pacing.ramp_down": pacing?.ramp_down?.toString() || "true",
      "pacing.frequency_capping": pacing?.frequency_capping || [],
      "pacing.completion_capping": pacing?.completion_capping || [],
      "pacing.slow_down": pacing?.slow_down || [],
      "bid": bid,
      "audience": audience || [],
      "geography": geography || [],
      "creative": creative,
      "inventory.custom_site_management": inventory?.custom_site_management || [],
      "inventory.device_type": inventory?.device_type || []
    }, item => typeof item !== "boolean" && isEmpty(item))
  };
};

const changesReducer = (changes, { action, ...payload }) => {
  switch (action) {
    case "reset": {
      return { errors: {}, ...initialState(payload), removedRuleIds: [] }
    }
    case "update_failed": {
      return { ...changes, errors: payload };
    }
    case "offer_attribute_changed": {
      return { ...changes, ...payload };
    }
    case "new_rule_added": {
      const { section, ...rule } = payload;
      rule.changed = true;

      return {
        ...changes,
        [section]: [...(changes[section] || []), rule]
      }
    }
    case "rule_removed": {
      const { section, id: ruleId } = payload;
      const removedId = ruleId.toString().startsWith("new_") ? [] : [ruleId]

      return {
        ...changes,
        [section]: [...changes[section].filter(rule => rule.id !== ruleId)],
        removedRuleIds: [...changes.removedRuleIds, ...removedId]
      }
    }
    case "rule_updated": {
      const { section, ...rule } = payload;
      rule.changed = true;

      return {
        ...changes,
        [section]: [...(changes[section] || []).map(r => r.id === rule.id ? rule : r)]
      }
    }
    default: {
      throw Error("Unkown optimization action " + action);
    }
  }
}

export const useOptimizationChanges = () => useContext(OptimizationChangesContext);

export const useOptimizationChangesDispatch = () => useContext(OptimizationsDispatchContext);

export const OptimizationChangesProvider = ({ children, optimizations = {} }) => {
  const [changes, dispatch] = useReducer(changesReducer, optimizations, initialState);

  return (
    <OptimizationChangesContext.Provider value={changes}>
      <OptimizationsDispatchContext.Provider value={dispatch}>
        {children}
      </OptimizationsDispatchContext.Provider>
    </OptimizationChangesContext.Provider>
  );
};
