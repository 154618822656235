import { title } from "radash";

import { styled } from "@mui/material/styles";
import {
  Grid,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import FilterAltOffRoundedIcon from "@mui/icons-material/FilterAltOffRounded";
import TableHeadCell from "../../components/TableHeadCell";
import AutocompleteFilter from "./components/AutocompleteFilter";
import autocompleteVirtualizedComponents from "./components/autocompleteVirtualizedComponents";
import ForbiddenAlert from "../../components/ForbiddenAlert";

import { useState, useEffect, useMemo } from "react";

import { useParams } from "react-router-dom";

import { get } from "../../apiClient";

const DEFAULT_PAGINATION = { page: 0, perPage: 20 };
const DEFAULT_SEARCH_RESULT = { records: [], totalCount: 0, ...DEFAULT_PAGINATION };
const DEFAULT_SORT = { orderDirection: "asc", orderBy: "id" }

const StyledTableRow = styled(TableRow)(({ theme  }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#1976D214"
  },
}));

const ErrorRow = ({ error }) => {
  return (
    <StyledTableRow>
      <TableCell colSpan={6} sx={{ textAlign: "center" }}>{error}</TableCell>
    </StyledTableRow>
  )
}

const AD_NETWORK_PLATFORM_OPTIONS = [
  { value: "ios", label: "iOS" },
  { value: "android", label: "Android" }
]

const PLACEMENT_TYPE_OPTIONS = [
  { value: "interstitial", label: "Interstitial" },
  { value: "rewarded", label: "Rewarded" }
]

const AutoTargetingTab = () => {
  const [filters, setFilter] = useState({ ...DEFAULT_PAGINATION, ...DEFAULT_SORT });
  const [searchResult, setSearchResult] = useState(DEFAULT_SEARCH_RESULT);
  const [loading, setLoading] = useState(false);
  const { page, perPage, records, error, totalCount } = searchResult;
  const { id: campaignId } = useParams();
  const [showForbiddenAlert, setShowForbiddenAlert] = useState(false);

  useEffect(() => {
    const {
      page, perPage, id: ids, adNetworkId: adNetworkIds,
      ...otherFilters
    } = filters;

    const params = {
      page: page + 1,
      perPage,
      id: ids?.join(","),
      adNetworkId: adNetworkIds?.join(","),
      ...otherFilters
    }

    setLoading(true)
    get("managed_plus", `/campaigns/${campaignId}/autotargeted_placements`, { params })
      .then(({ result, total_count }) => {
        setSearchResult({ records: result, totalCount: total_count, page, perPage })
      })
      .catch((error) => {
        if (error.status === 403) {
          setShowForbiddenAlert(true)
        }

        setSearchResult({ error: "An error occurred while fetching the data. Please, contact support if the error is persistent."})
      })
      .finally(() => setLoading(false))
  }, [filters, campaignId])

  const onFilterChange = (filter) => setFilter((prev) => ({ ...prev, ...filter, page: 0 }));
  const handleSortChange = (_event, order) => setFilter((prev) => ({ ...prev, ...order }));
  const handleClearAllFiltersClick = (_event) => {
    document.querySelectorAll(".autotargeted-placements-autocomplete")?.forEach(el => el.dispatchEvent(new CustomEvent("autocompleteReset", { bubbles: true })))
    setFilter({ ...DEFAULT_PAGINATION, ...DEFAULT_SORT })
  }

  const placementsAutocompleteEndpoint = useMemo(() => {
    return { service: "managed_plus", path: "/autocomplete/autotargeted_placements", query: { campaignId } }
  }, [campaignId])

  const distributorsAutocompleteEndpoint = useMemo(() => {
    return { service: "managed_plus", path: "/autocomplete/autotargeted_distributors", query: { campaignId } }
  }, [campaignId])

  return (
    <Grid container rowSpacing={2} direction="column">
      <Grid item>
        <Typography variant="h6">Auto Targeted Placements</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2} direction="row">
          <Grid item xs={10} sx={{ display: "flex", justifyContent: "space-between" }}>
            <AutocompleteFilter
              className={"autotargeted-placements-autocomplete"}
              {...autocompleteVirtualizedComponents}
              defaultOptionLabel="All Placements"
              onChange={(_e, option) => onFilterChange({ id: option.map(({ value }) => value) })}
              inputProps={{ label: "Placement ID" }}
              apiEndpoint={placementsAutocompleteEndpoint}
              sx={{ width: 275 }}
            />
            <AutocompleteFilter
              defaultOptionLabel="All Ad Networks"
              className={"autotargeted-placements-autocomplete"}
              {...autocompleteVirtualizedComponents}
              onChange={(_e, option) => onFilterChange({ adNetworkId: option.map(({ value }) => value) })}
              inputProps={{ label: "Ad Network ID" }}
              apiEndpoint={distributorsAutocompleteEndpoint}
              sx={{ width: 275 }}
            />
            <AutocompleteFilter
              defaultOptionLabel="All Platforms"
              className={"autotargeted-placements-autocomplete"}
              multiple={false}
              renderInput={(params) => <TextField {...params} label="Ad Network Platform" />}
              onChange={(_e, option) => onFilterChange({ adNetworkPlatform: option?.value })}
              options={AD_NETWORK_PLATFORM_OPTIONS}
              sx={{ width: 215, mt: 0.125 }}
            />
            <AutocompleteFilter
              defaultOptionLabel="All Placement Types"
              className={"autotargeted-placements-autocomplete"}
              multiple={false}
              renderInput={(params) => <TextField {...params} label="Placement Type" />}
              onChange={(_e, option) => onFilterChange({ type: option?.value })}
              options={PLACEMENT_TYPE_OPTIONS}
              sx={{ width: 215, mt: 0.125 }}
            />
          </Grid>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Link
              underline="none"
              component="button"
              href="#"
              onClick={handleClearAllFiltersClick}
              sx={{ display: "flex", color: "primary1.main", fontSize: "0.8125rem", mt: 0.75 }}
            >
              {<FilterAltOffRoundedIcon />} Clear All Filters
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TableContainer>
          {loading && <LinearProgress />}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableHeadCell
                  title="Placement ID"
                  name="id"
                  onSortOrderChange={handleSortChange}
                  orderDirection={filters.orderDirection}
                  orderBy={filters.orderBy}
                />
                <TableHeadCell
                  title="Placement Name"
                  name="name"
                  onSortOrderChange={handleSortChange}
                  orderDirection={filters.orderDirection}
                  orderBy={filters.orderBy}
                />
                <TableHeadCell
                  title="Placement Type"
                  name="type"
                  onSortOrderChange={handleSortChange}
                  orderDirection={filters.orderDirection}
                  orderBy={filters.orderBy}
                />
                <TableHeadCell
                  title="Ad Network ID"
                  name="ad_network_id"
                  onSortOrderChange={handleSortChange}
                  orderDirection={filters.orderDirection}
                  orderBy={filters.orderBy}
                />
                <TableHeadCell
                  title="Ad Network Name"
                  name="ad_network_name"
                  onSortOrderChange={handleSortChange}
                  orderDirection={filters.orderDirection}
                  orderBy={filters.orderBy}
                />
                <TableHeadCell
                  title="Ad Network Platform"
                  name="ad_network_platform"
                  onSortOrderChange={handleSortChange}
                  orderDirection={filters.orderDirection}
                  orderBy={filters.orderBy}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                error ?
                <ErrorRow error={error}/> :
                records.map(({ id, name, ad_network_id, type, ad_network_name, ad_network_platform }) => (
                <StyledTableRow key={id}>
                  <TableCell>
                    <Link
                      underline="hover"
                      href={`/admin/cache_analyzer/${id}`}
                      sx={{ color: "primary1.main" }}
                    >
                      {id}
                    </Link>
                  </TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{title(type)}</TableCell>
                  <TableCell>{ad_network_id}</TableCell>
                  <TableCell>{ad_network_name}</TableCell>
                  <TableCell>{ad_network_platform}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {!error && (
            <TablePagination
              component="div"
              count={totalCount}
              onPageChange={(_e, page) => setFilter((filters) => ({ ...filters, page }))}
              onRowsPerPageChange={(event) => {
                setFilter((filters) => ({ ...filters, perPage: event.target.value }))
              }}
              page={page}
              rowsPerPage={perPage}
              rowsPerPageOptions={[10,20,50]}
            />
          )}
        </TableContainer>
      </Grid>
      <ForbiddenAlert open={showForbiddenAlert} onClose={() => setShowForbiddenAlert(false)} />
    </Grid>
  )
}

export default AutoTargetingTab;
