import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import {
  json,
  useActionData,
  useFormAction,
  useLocation,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";

import { useEffect } from "react";

import { post } from "../../apiClient";

const action = async ({ request, ...props }) => {
  const data = await request.formData();
  const audience_ids = data.get("audience_ids").split(",");

  try {
    await post(
      "audience",
      "/v1/audiences/bulk_delete",
      { audience_ids },
      { signal: request.signal, responseFormat: "text" }
    );

    return json({
      success: true,
      message: "Selected Audiences has been deleted.",
    });
  } catch (_error) {
    return json({
      success: false,
      message:
        "Something went wrong. If the error is persistent, contact support.",
    });
  }
};

const noIdToDelete = (location) =>
  (location?.state?.audience_ids || []).length === 0;

const AudienceBulkDeleteDialog = () => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const location = useLocation();
  const submit = useSubmit();
  const result = useActionData();
  const action = useFormAction();
  const handleDelete = (_event) => {
    submit(
      { audience_ids: location.state.audience_ids },
      { method: "post", action }
    );
  };

  useEffect(() => {
    if (!result && noIdToDelete(location)) {
      navigate("..");
    }
  }, [result, navigate, location]);

  return (
    <Dialog onClose={() => navigate("..")} PaperProps={{ square: true }} open>
      <Box sx={{ p: 3 }}>
        <DialogContent>
          {result ? (
            <Alert severity={result.success ? "success" : "error"}>
              {result.message}
            </Alert>
          ) : (
            <Typography
              variant="subtitle2"
              sx={{ lineHeight: 1.5, letterSpacing: 0.4 }}
            >
              ARE YOU SURE YOU WANT TO DELETE THE SELECTED AUDIENCES?
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-evenly" }}>
          {!result && (
            <LoadingButton
              onClick={handleDelete}
              variant="contained"
              loading={navigation.state === "submitting"}
            >
              Yes, I'm sure
            </LoadingButton>
          )}
          <Button variant="contained" onClick={() => navigate("..")}>
            {result ? "Close" : "No"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export { action };
export default AudienceBulkDeleteDialog;
