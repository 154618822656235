import {
  Typography,
} from "@mui/material";

const ListTitle = ({ title }) =>
  <Typography variant="subtitle2" component="p">{title}</Typography>;

const ListItem = ({ text, sx, ...props }) =>
  <Typography {...props} variant="body1" component="p" sx={{ pl: 1, color: "text.primary", ...sx }}>{text}</Typography>;

const OptimizationBoxItemsList = ({ title, items }) => {
  return (
    <>
      <ListTitle title={title} />
      {items.map((props) => <ListItem {...props} />)}
    </>
  )
}


export default OptimizationBoxItemsList;
