import { isNumber } from "radash";
import formatNumber from "../../../utils/formatNumber";

function formatCPM(cpm_in_cents) {
  return isNumber(cpm_in_cents)
    ? formatNumber(cpm_in_cents / 100, { currency: "USD", style: "currency" })
    : "-";
}

export default formatCPM;
