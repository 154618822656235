import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PlacementPrebidConfigurationsAddPlacementForm from "./components/PlacementPrebidConfigurationsAddPlacementForm";

export default function BulkCreateDialog({ open, ...otherProps }) {
  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>Add Placements</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <PlacementPrebidConfigurationsAddPlacementForm {...otherProps} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
