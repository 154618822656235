import { Box, Button, ButtonGroup, Grid, Paper } from "@mui/material";
import { isEmpty, pick } from "radash";
import {
  json,
  Link,
  useLoaderData,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import { createUrl, get } from "../apiClient";
import SegmentFiltering from "../features/segments/components/SegmentFiltering";
import SegmentList from "../features/segments/components/SegmentList";
import useTitle from "../hooks/useTitle";

const FILTER_PARAMS = ["searchTerm", "segmentTypeId"];

const PAGINATION_DEFAULTS = {
  limit: "25",
  offset: "0",
  orderBy: "name",
  orderDirection: "asc",
};

function searchParamsToApiParams(searchParams) {
  const params = Object.fromEntries(searchParams);

  return {
    ...pick(params, FILTER_PARAMS),
    pagination: {
      ...PAGINATION_DEFAULTS,
      ...pick(params, Object.keys(PAGINATION_DEFAULTS)),
    },
  };
}

export async function loader({ request }) {
  const params = searchParamsToApiParams(new URL(request.url).searchParams);

  try {
    const { result: segments, total_count: totalCount } = await get(
      "audience",
      "/v1/segments",
      {
        params,
        signal: request.signal,
      }
    );
    return { params, segments, totalCount };
  } catch (error) {
    throw json(
      {
        title: "There was an error fetching the segments from the server",
        text: " Please try again. If the error is persistent, contact support.",
      },
      { status: error.status }
    );
  }
}

const Segments = () => {
  useTitle("Segments");
  const segmentTypes = useRouteLoaderData("segmentTypes");
  const { params, segments, totalCount } = useLoaderData();
  const filters = pick(params, FILTER_PARAMS);
  const [searchParams, setSearchParams] = useSearchParams(PAGINATION_DEFAULTS);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
            <ButtonGroup variant="contained">
              <Button
                disabled={Object.values(filters).every(isEmpty)}
                href={createUrl("audience", "/v1/segments/export", {
                  ...params,
                  pagination: pick(params.pagination, [
                    "orderBy",
                    "orderDirection",
                  ]),
                })}
                rel="noopener noreferrer"
              >
                Export
              </Button>
              <Button LinkComponent={Link} to="/segments/new">
                New Segment
              </Button>
            </ButtonGroup>
          </Box>
          <SegmentFiltering filters={filters} segmentTypes={segmentTypes} />
          <SegmentList
            onPageChange={(_event, page) => {
              setSearchParams((prev) => ({
                ...Object.fromEntries(prev),
                offset: Math.max(page * Number(prev.get("limit")), 0),
              }));
            }}
            onRowsPerPageChange={(event) => {
              setSearchParams((prev) => ({
                ...Object.fromEntries(prev),
                limit: event.target.value,
              }));
            }}
            onSortOrderChange={(_event, sortOrder) => {
              setSearchParams((prev) => ({
                ...Object.fromEntries(prev),
                ...sortOrder,
              }));
            }}
            orderBy={searchParams.get("orderBy")}
            orderDirection={searchParams.get("orderDirection")}
            page={
              Number(searchParams.get("offset")) /
              Number(searchParams.get("limit"))
            }
            rowsPerPage={Number(searchParams.get("limit"))}
            segments={segments}
            totalCount={totalCount}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Segments;
