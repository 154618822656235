import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import StyledDataGrid from "../../../components/StyledDataGrid";
import { deletePrebidAccount } from "../../prebid_accounts/apiService";
import { updateAssociatedPrebidAccount } from "../apiService";

const PublisherDataGrid = ({
  publishers,
  pageSize,
  page,
  rowCount,
  setSearchParams,
  paginationModel,
  onPaginationModelChange,
}) => {
  /////////// Column definition ///////////

  const columns = [
    {
      field: "id",
      headerName: "Publisher ID",
      width: 150,
    },
    {
      field: "name",
      headerName: "Publisher Name",
      width: 250,
      // Renders a Link if there is a Prebid Account associated
      renderCell: (params) => {
        //only render link if the row is valid
        if (isRowValid(params.row)) {
          return (
            <Link
              to={`/prebid_accounts/${params.row.prebid_account_id}/placement_prebid_configurations`}
            >
              {params.value}
            </Link>
          );
        } else {
          return params.value;
        }
      },
    },
    {
      field: "prebid_account_id",
      headerName: "Prebid Server Account ID",
      width: 250,
      editable: true,
      preProcessEditCellProps: (params) => {
        let hasError =
          !isAlphaNumeric(params.props.value) && params.props.value !== "";
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "ad_unit_config_id",
      headerName: "Ad Unit Config ID",
      width: 250,
      editable: true,
      preProcessEditCellProps: (params) => {
        let hasError =
          !isAlphaNumeric(params.props.value) && params.props.value !== "";
        return { ...params.props, error: hasError };
      },
    },
  ];

  /////////// Sorting Logic ///////////

  const onSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      setSearchParams((prev) => ({
        ...Object.fromEntries(prev),
        order_by: sortModel[0].field,
        order_direction: sortModel[0].sort,
      }));
    } else {
      setSearchParams((prev) => ({
        ...Object.fromEntries(prev),
        order_by: "",
        order_direction: "",
      }));
    }
  };

  /////////// Row Edition Logic ///////////

  const updateAssociatedPrebidAccountMutation = useMutation({
    mutationFn: updateAssociatedPrebidAccount,
    onSuccess: (params) => {
      setSnackbar({
        children: `Publisher ${params.data.name} successfully updated`,
        severity: "success",
      });
    },
    onError: (error) => {
      if (error.status === 422 || error.status === 500) {
        setSnackbar({
          children: "Publisher failed to be updated",
          severity: "error",
        });
      }
    },
  });

  const deletePrebidAccountMutation = useMutation({
    mutationFn: deletePrebidAccount,
    onSuccess: (params) => {
      setSnackbar({
        children: "Prebid account successfully deleted",
        severity: "success",
      });
    },
    onError: (error) => {
      if (error.status === 422 || error.status === 500) {
        setSnackbar({
          children: "Prebid Account failed to be deleted",
          severity: "error",
        });
      }
    },
  });

  const processRowUpdate = (row, originalValues) => {
    // Deletes or updates the prebid account associated to the publisher
    if (row.ad_unit_config_id === "" && row.prebid_account_id === "") {
      deletePrebidAccountMutation.mutate(originalValues.prebid_account_id);
    }

    if (isRowValid(row)) {
      // Detects if the prebid_account_id is being updated
      if (
        originalValues.prebid_account_id !== "" &&
        row.prebid_account_id !== originalValues.prebid_account_id
      ) {
        row["original_prebid_account_id"] = originalValues.prebid_account_id;
      }

      updateAssociatedPrebidAccountMutation.mutate(row);
    }

    return row;
  };

  const processRowUpdateError = (error) => {
    // eslint-disable-next-line no-console
    console.error("Error Updating Row", error);
  };

  /////////// Snackbar Logic ///////////

  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  /////////// Utils ///////////

  const isAlphaNumeric = (str) => {
    if (typeof str !== "string") {
      return false; // Check if str is a string
    }
    const regex = /^[a-zA-Z0-9_]+$/;
    return regex.test(str);
  };

  // Row is valid if both prebid_account_id and prebid_ad_unit_config_id
  // are numeric values
  const isRowValid = ({ prebid_account_id, ad_unit_config_id }) => {
    return (
      isAlphaNumeric(prebid_account_id) &&
      isAlphaNumeric(ad_unit_config_id) &&
      prebid_account_id !== "" &&
      ad_unit_config_id !== ""
    );
  };

  /////////// Rendering Section ///////////

  return (
    <div style={{ width: "100%" }}>
      <StyledDataGrid>
        <DataGrid
          // Data definition
          rows={publishers}
          columns={columns}
          // Pagination
          initialState={{
            pagination: { paginationModel: { pageSize: pageSize } },
          }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          rowCount={rowCount}
          // Sorting
          sortingMode="server"
          onSortModelChange={onSortModelChange}
          // Edition
          editMode="row"
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={processRowUpdateError}
          slots={{
            loadingOverlay: LinearProgress,
            noResultsOverlay: () => null,
          }}
          loading={updateAssociatedPrebidAccountMutation.isLoading}
        />
      </StyledDataGrid>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
};

export default PublisherDataGrid;
