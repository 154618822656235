import FilterAltOffRoundedIcon from "@mui/icons-material/FilterAltOffRounded";
import { Box, Button, Link as MUILink } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactsFilter from "./components/ContactsFilter";
import { SearchDataGrid } from "./components/SearchDataGrid";
import {
  filterResourceBy,
  filtersFromSearchParams,
  updateFilter,
} from "./utils/searchUtils.js";

const Link = styled(MUILink)(
  () => `
    color: #64B5C5;
  `
);

const DEFAULT_RESULT = { result: [], totalCount: 0 };

const ContactsSearchColumns = [
  {
    headerName: "Account ID",
    field: "account_id",
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Link
          underline="hover"
          href={`/admin/managed_plus_accounts/${row.account_id}`}
        >
          {row.account_id}
        </Link>
      );
    },
  },
  { headerName: "Account Name", field: "account_name", flex: 1 },
  { headerName: "Contact Name", field: "name", flex: 1 },
  { headerName: "Contact Email", field: "email", flex: 1 },
  {
    headerName: "Status",
    field: "deactivated",
    valueFormatter: ({ value }) => (value ? "Inactive" : "Active"),
  },
];

const ContactsTab = () => {
  const [result, setResult] = useState(DEFAULT_RESULT);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(filtersFromSearchParams());
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const onEditActionClick = useCallback(
    (params) => {
      navigate(`/managed-plus/credits/contacts/${params.id}/edit`);
    },
    [navigate]
  );

  useEffect(() => {
    setIsLoading(true);
    filterResourceBy("contacts", filters)
      .onSuccess(({ result, total_count: totalCount }) => {
        setResult({ result, totalCount });
      })
      .onError((error) => {
        setResult({
          ...DEFAULT_RESULT,
          error: "Failed to fetch business contacts from server",
        });
      })
      .done(() => setIsLoading(false))
      .run();
  }, [filters]);

  return (
    <div aria-label="Contacts list">
      <ContactsFilter
        filters={filters}
        onFilterChange={(updatedParams) =>
          setFilters((params) => updateFilter(params, updatedParams))
        }
      />
      <Box
        sx={{ height: 640, pt: 4, flexDirection: "column", display: "flex" }}
        data-testid="contact-search-grid"
      >
        <Box sx={{ display: "flex", flexDirection: "row", pb: 2 }}>
          <Button
            variant="contained"
            disabled={selectedRows.length < 2}
            onClick={(event) => {
              event.preventDefault();
              const ids = selectedRows.map((id) => `ids[]=${id}`).join("&");
              navigate(`/managed-plus/credits/contacts/bulk-edit?${ids}`);
            }}
          >
            Bulk Edit
          </Button>
          <Link
            underline="none"
            component="button"
            href="#"
            onClick={() => setFilters({ page: 0, perPage: 25, order: [] })}
            sx={{ pl: 2, display: "flex", alignItems: "center" }}
          >
            {<FilterAltOffRoundedIcon />} Clear All Filters
          </Link>
        </Box>
        <SearchDataGrid
          DataGridProps={{
            onRowSelectionModelChange: (model) => setSelectedRows(model),
            loading: isLoading,
          }}
          onEditActionClick={onEditActionClick}
          sortModel={filters.order}
          onSortChange={(model) =>
            setFilters((params) => ({ ...params, order: model }))
          }
          onPaginationChange={({ pageSize: perPage, page }) => {
            const currentPage = filters.perPage === perPage ? page : 0;
            setFilters((params) => ({ ...params, perPage, page: currentPage }));
          }}
          onCopyActionClick={({ row }) => {
            const { name, email, account_id } = row;
            const contactCopiedValues = { name, email, account_id };
            navigate("/managed-plus/credits/contacts/new", {
              state: { contactCopiedValues },
            });
          }}
          searchResult={result}
          page={filters.page}
          pageSize={filters.perPage}
          actions={{ edit: true, copy: true }}
          columns={ContactsSearchColumns}
        />
      </Box>
    </div>
  );
};

export { ContactsTab };
