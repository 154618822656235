import { json, useParams, useRouteLoaderData } from "react-router-dom";
import { get, patch } from "../apiClient";
import ContactForm from "../features/managed_plus_credits/components/ContactForm";
import useTitle from "../hooks/useTitle";

const action = async ({ params, request }) => {
  const formData = await request.formData();
  const formEntries = Object.fromEntries(formData);

  try {
    const contact = await patch(
      "managed_plus",
      `/contacts/${params.id}`,
      formEntries
    );

    return json(contact);
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
};

const loader = async ({ params, request }) => {
  try {
    const contact = await get("managed_plus", `/contacts/${params.id}`, {
      signal: request.signal,
    });
    return json(contact);
  } catch (error) {
    switch (error.status) {
      case 404:
        throw new json(
          {
            title: "Contact not found",
            text: `The contact with ID ${params.id} could not be found.`,
          },
          { status: 404 }
        );
      default:
        throw error;
    }
  }
};

const EditManagedPlusContact = () => {
  useTitle("M+ Campaign Credits");
  const { id } = useParams();
  const contact = useRouteLoaderData("managedPlusContact");

  return (
    <ContactForm
      action={`/managed-plus/credits/contacts/${id}/edit`}
      contact={contact}
    />
  );
};

export { loader, action };
export default EditManagedPlusContact;
