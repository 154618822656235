import {
  Grid,
} from "@mui/material";

import OptimizationBox from "./OptimizationBox";
import OptimizationBoxItemsList from "./OptimizationBoxItemsList";

const CreativeDetail = ({ details: { on_page_load_js } }) => {
  return on_page_load_js && (
    <OptimizationBox panelId={"creative"} title={"Creative"}>
      <Grid container direction="row">
        <Grid item>
          <OptimizationBoxItemsList
            title={"On Page Load JS"}
            items={[{ key: "on_page_load_js", text: on_page_load_js }]} />
        </Grid>
      </Grid>
    </OptimizationBox>
  );
}

export default CreativeDetail;
