import { json, useParams, useRouteLoaderData } from "react-router-dom";
import { get, patch } from "../apiClient";
import CreditForm from "../features/managed_plus_credits/components/CreditForm";
import { convertToDate } from "../features/managed_plus_credits/utils/dateFormatUtils.js";
import useTitle from "../hooks/useTitle";

const action = async ({ params, request }) => {
  const formData = await request.formData();
  const formEntries = Object.fromEntries(formData);
  formEntries.date =
    formEntries.date && convertToDate(formEntries.date).toSQLDate();

  try {
    const credit = await patch(
      "managed_plus",
      `/credits/${params.id}`,
      formEntries
    );
    return json(credit);
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
};

const loader = async ({ params, request }) => {
  try {
    const credit = await get("managed_plus", `/credits/${params.id}`, {
      signal: request.signal,
    });
    return json(credit);
  } catch (error) {
    switch (error.status) {
      case 404:
        throw new json(
          {
            title: "Credit not found",
            text: `The credit with ID ${params.id} could not be found.`,
          },
          { status: 404 }
        );
      default:
        throw error;
    }
  }
};

const EditManagedPlusCredit = () => {
  useTitle("M+ Campaign Credits");
  const { id } = useParams();
  const credit = useRouteLoaderData("managedPlusCredit");

  return (
    <CreditForm action={`/managed-plus/credits/${id}/edit`} credit={credit} />
  );
};

export { loader, action };
export default EditManagedPlusCredit;
