import { get as searchRecords } from "../../../apiClient";

const isOrderParam = (key) => key.match(/^order\[(.*)\]$/);
const fetchFiltersFromUrl = (params) => {
  return Object.keys(params).reduce(
    (acc, key) => {
      const [searchKey, fieldName] = isOrderParam(key) || [];
      if (searchKey && fieldName) {
        acc.order.push({ field: fieldName, sort: params[searchKey] });
      } else {
        acc.filters[key] = params[key];
      }

      return acc;
    },
    { filters: {}, order: [] }
  );
};

const sortParamsFromModel = (orderModel) => {
  if (orderModel === undefined || orderModel.length < 1) return {};

  const model = orderModel[0];
  return { [`order[${model.field}]`]: model.sort };
};

const DEFAULT_PAGE = 0;
const DEFAULT_PER_PAGE = 25;

const filtersFromSearchParams = (defaultOrder = []) => {
  const { tab, page, perPage, ...params } = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const { filters, order } = fetchFiltersFromUrl(params);
  const orderModel = order.length > 0 ? order : defaultOrder;

  return {
    ...filters,
    page: Number(page) || DEFAULT_PAGE,
    perPage: Number(perPage) || DEFAULT_PER_PAGE,
    order: orderModel,
  };
};

const updateFilter = (params, updatedParams) => {
  if (params.lastSignal) {
    params.lastSignal.abort();
  }
  const newSignal = new AbortController();

  return {
    ...params,
    lastSignal: newSignal,
    page: DEFAULT_PAGE,
    ...updatedParams,
  };
};

class AsyncFilterResult {
  constructor(path, params) {
    this.aborted = false;
    this.path = path;
    this.params = params;
    this.errorCallback = (...args) => {};
    this.successCallback = (...args) => {};
    this.finallyCallback = (...args) => {};
  }
  onSuccess(callback) {
    this.successCallback = callback;
    return this;
  }
  onError(callback) {
    this.errorCallback = callback;
    return this;
  }
  done(callback) {
    this.finallyCallback = callback;
    return this;
  }
  run() {
    const that = this;
    return searchRecords("managed_plus", this.path, this.params)
      .then(that.successCallback)
      .catch((error) => {
        if (error.name !== "AbortError") {
          that.errorCallback(error);
        } else {
          that.aborted = true;
        }
      })
      .finally(() => {
        if (!that.aborted) {
          that.finallyCallback();
        }
      });
  }
}

const filterResourceBy = (resource, params) => {
  const { order, lastSignal, ...filters } = params;
  const orderParams = sortParamsFromModel(order);
  const searchParams = new URLSearchParams({
    tab: resource,
    ...filters,
    ...orderParams,
  });
  window.history.pushState(
    {},
    null,
    `${window.location.pathname}?${searchParams.toString()}`
  );
  const signal = lastSignal?.signal;

  return new AsyncFilterResult(`/${resource}`, {
    signal,
    params: { ...filters, ...orderParams },
  });
};

export { filterResourceBy, filtersFromSearchParams, updateFilter };
