import * as apiClient from "../apiClient";
import App from "../App";
import AppError from "../AppError";
import AudienceBulkDeleteDialog, {
  action as deleteAudiences,
} from "../features/audiences/AudienceBulkDeleteDialog";
import SegmentDeactivationDialog, {
  action as deactivateSegment,
} from "../features/segments/components/SegmentDeactivationDialog";
import SegmentTargetingJobDialog, {
  action as createSegmentTargetingJob,
} from "../features/segments/components/SegmentTargetingJobDialog";
import Advertisers, { loader as advertisersLoader } from "../pages/Advertisers";
import Audience, {
  action as updateAudience,
  loader as loadAudience,
} from "../pages/Audience";
import Audiences, { loader as loadAudiences } from "../pages/Audiences";
import { loader as loadPodcastCampaignSettings} from "../features/managed_plus_podcasts/SettingsTab";
import ManagedPlusPodcastCampaigns from "../pages/ManagedPlusPodcastCampaigns.js";
import ManagedPlusPodcastCampaign from "../pages/ManagedPlusPodcastCampaign.js"
import BulkEditManagedPlusContact, {
  action as contactBulkEditAction,
} from "../pages/BulkEditManagedPlusContact";
import BulkEditManagedPlusCredit, {
  action as creditBulkEditAction,
} from "../pages/BulkEditManagedPlusCredit";
import EditAdvertiser, {
  action as updateAdvertiser,
  loader as loadAdvertiser,
} from "../pages/EditAdvertiser";
import EditManagedPlusContact, {
  action as updateContactAction,
  loader as managedPlusContactLoader,
} from "../pages/EditManagedPlusContact";
import EditManagedPlusCredit, {
  action as updateCreditAction,
  loader as ManagedPlusCreditLoader,
} from "../pages/EditManagedPlusCredit";
import Error from "../pages/Error";
import ManagedPlusCredits from "../pages/ManagedPlusCredits";
import NewAdvertiser, {
  action as createAdvertiser,
} from "../pages/NewAdvertiser";
import NewAudience, { action as createNewAudience } from "../pages/NewAudience";
import NewManagedPlusContact, {
  action as createContactAction,
} from "../pages/NewManagedPlusContact";
import NewManagedPlusCredit, {
  action as NewCreditAction,
} from "../pages/NewManagedPlusCredit";
import NewSegment, { action as createNewSegment } from "../pages/NewSegment";
import PlacementPrebidConfigurations from "../pages/PlacementPrebidConfigurations";
import Publishers from "../pages/Publishers";
import Segment, {
  action as updateSegment,
  loader as loadSegment,
} from "../pages/Segment";
import Segments, { loader as loadSegments } from "../pages/Segments";
import TargetingJobs, {
  loader as loadTargetingJobs,
} from "../pages/TargetingJobs";

const routes = [
  {
    path: "/",
    element: <App />,
    errorElement: <AppError />,
    children: [
      {
        id: "advertisers",
        path: "advertisers",
        children: [
          {
            loader: advertisersLoader,
            element: <Advertisers />,
            index: true,
          },
          {
            id: "advertiserCategories",
            loader: async ({ request }) => {
              const { data: advertiserCategories } = await apiClient.get(
                "player",
                "/api/v1/advertiser_categories",
                {
                  signal: request.signal,
                }
              );

              return advertiserCategories;
            },
            children: [
              {
                action: createAdvertiser,
                path: "new",
                element: <NewAdvertiser />,
              },
              {
                action: updateAdvertiser,
                loader: loadAdvertiser,
                path: ":id/edit",
                element: <EditAdvertiser />,
                id: "advertiser",
              },
            ],
          },
        ],
      },
      {
        errorElement: <Error />,
        id: "segmentTypes",
        loader: async ({ request }) => {
          const { result: segmentTypes } = await apiClient.get(
            "audience",
            "/v1/segment_types",
            {
              signal: request.signal,
            }
          );

          return segmentTypes;
        },
        shouldRevalidate: () => false,
        children: [
          {
            element: <Segments />,
            index: true,
            loader: loadSegments,
          },
          {
            action: createNewSegment,
            element: <NewSegment />,
            path: "segments/new",
          },
          {
            id: "segment",
            loader: loadSegment,
            children: [
              {
                element: <Segment />,
                path: "segments/:id",
                children: [
                  {
                    action: createSegmentTargetingJob,
                    element: <SegmentTargetingJobDialog isAssociationJob />,
                    path: "associate",
                  },
                  {
                    action: createSegmentTargetingJob,
                    element: <SegmentTargetingJobDialog />,
                    path: "dissociate",
                  },
                  {
                    action: deactivateSegment,
                    element: <SegmentDeactivationDialog />,
                    path: "deactivate",
                  },
                ],
              },
              {
                action: updateSegment,
                element: <Segment isEditing />,
                path: "segments/:id/edit",
              },
            ],
          },
          {
            element: <TargetingJobs />,
            loader: loadTargetingJobs,
            path: "targeting_jobs",
          },
          {
            path: "audiences",
            loader: loadAudiences,
            element: <Audiences />,
            shouldRevalidate: ({ actionResult, defaultShouldRevalidate }) => {
              return actionResult
                ? actionResult.success
                : defaultShouldRevalidate;
            },
            children: [
              {
                path: "bulk_delete",
                action: deleteAudiences,
                element: <AudienceBulkDeleteDialog />,
              },
            ],
          },
          {
            path: "audiences/new",
            element: <NewAudience />,
            action: createNewAudience,
          },
          {
            id: "audience",
            loader: loadAudience,
            children: [
              {
                path: "audiences/:id",
                element: <Audience />,
              },
              {
                path: "audiences/:id/edit",
                action: updateAudience,
                element: <Audience isEditing />,
              },
            ],
          },
        ],
      },
      {
        id: "accountsList",
        loader: async ({ request }) => {
          const accounts = await apiClient.get("managed_plus", "/accounts", {
            signal: request.signal
          });

          return accounts;
        },
        children: [
          {
            element: <ManagedPlusCredits />,
            path: "/managed-plus/credits",
          },
          {
            action: NewCreditAction,
            element: <NewManagedPlusCredit />,
            path: "/managed-plus/credits/new",
          },
          {
            id: "managedPlusCredit",
            loader: ManagedPlusCreditLoader,
            action: updateCreditAction,
            element: <EditManagedPlusCredit />,
            path: "/managed-plus/credits/:id/edit",
          },
          {
            action: createContactAction,
            path: "/managed-plus/credits/contacts/new",
            element: <NewManagedPlusContact />,
          },
          {
            id: "managedPlusContact",
            loader: managedPlusContactLoader,
            action: updateContactAction,
            path: "/managed-plus/credits/contacts/:id/edit",
            element: <EditManagedPlusContact />,
          },
          {
            path: "/managed-plus/credits/bulk-edit",
            action: creditBulkEditAction,
            element: <BulkEditManagedPlusCredit />,
          },
          {
            path: "/managed-plus/credits/contacts/bulk-edit",
            action: contactBulkEditAction,
            element: <BulkEditManagedPlusContact />,
          },
        ],
      },
      {
        element: <ManagedPlusPodcastCampaigns />,
        path: "/managed-plus/podcast-campaigns"
      },
      {
        loader: loadPodcastCampaignSettings,
        element: <ManagedPlusPodcastCampaign />,
        path: "/managed-plus/podcast-campaigns/:id"
      },
      {
        path: "publishers",
        element: <Publishers />,
      },
      {
        element: <PlacementPrebidConfigurations />,
        path: "prebid_accounts/:prebid_account_id/placement_prebid_configurations",
      }
    ],
  },
];

export default routes;
