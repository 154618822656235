import { Grid, TextField } from "@mui/material";
import { debounce } from "radash";
import { useEffect, useMemo, useState } from "react";
import { Form, useNavigation, useSubmit } from "react-router-dom";

const PublisherFiltering = ({ filters }) => {
  const submit = useSubmit();
  const submitDebounced = useMemo(
    () => debounce({ delay: 300 }, submit),
    [submit]
  );
  const navigation = useNavigation();
  const [searchTerm, setSearchTerm] = useState(filters.searchTerm ?? "");

  useEffect(() => {
    if (navigation.state === "loading") {
      const searchParams = new URLSearchParams(navigation.location.search);
      setSearchTerm(searchParams.get("searchTerm") ?? "");
    }
  }, [navigation.state, navigation.location]);

  return (
    <Form
      method="get"
      onChange={(e) => {
        const isFirstSearch = filters.searchTerm === undefined;
        e.target.name === "search"
          ? submitDebounced(e.currentTarget, { replace: !isFirstSearch })
          : submit(e.currentTarget, { replace: !isFirstSearch });
      }}
    >
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <TextField
            value={searchTerm}
            fullWidth
            name="search"
            label="Search Publishers"
            onChange={(e) => setSearchTerm(e.target.value)}
            type="search"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default PublisherFiltering;
