import { Button, Grid, TextField } from "@mui/material";

import { Form, useActionData } from "react-router-dom";

import { useState } from "react";

import Autocomplete from "./components/Autocomplete";

import {
  createAutocompleteOptions,
  getServerOptions,
} from "./utils/autocompleteUtils.js";

const AudienceForm = ({
  action,
  isEditing = false,
  audience = {
    id: null,
    human_readable_name: null,
    name: null,
    segments: [],
  },
}) => {
  const [value, setValue] = useState(
    createAutocompleteOptions(audience?.segments || [])
  );
  const form = useActionData();

  return (
    <Form method="post" action={action} role="form" name="audience-form">
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Audience Key"
            name="name"
            error={form?.errors?.hasOwnProperty("/name")}
            helperText={form?.errors?.["/name"]}
            required
            defaultValue={audience.name}
            inputProps={{
              title: "only letters and underscore are allowed",
              pattern: "^[a-zA-Z_]+$",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Audience Name"
            name="human_readable_name"
            defaultValue={audience.human_readable_name}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Autocomplete
            data-testid="my-autocomplete"
            getServerOptions={getServerOptions}
            onValueChange={setValue}
            defaultValue={value}
            renderInput={(params) => (
              <TextField
                error={form?.errors?.hasOwnProperty("/segment_ids")}
                helperText={form?.errors?.["/segment_ids"]}
                {...params}
                label="Search Segments"
              />
            )}
          />
        </Grid>
        <input
          type="hidden"
          name="segment_ids"
          value={value.map(({ id }) => id).join(",")}
        />
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button type="submit" variant="contained">
            {isEditing ? "Save Changes" : "Create Audience"}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AudienceForm;
