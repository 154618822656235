import {
  Grid,
} from "@mui/material";

import formatNumber from "../../../utils/formatNumber";

import OptimizationBox from "./OptimizationBox";
import OptimizationBoxItemsList from "./OptimizationBoxItemsList";

const BidDetail = ({ details }) => {
  const { custom_max_bid_per_step } = details;
  const items = [{
    key: "custom_max_bid_per_step",
    text: formatNumber(custom_max_bid_per_step, { maximumFractionDigits: 6, minimumFractionDigits: 6 })
  }];

  return custom_max_bid_per_step && (
    <OptimizationBox panelId={"bid"} title={"Bid"}>
      <Grid container direction="row">
        <Grid item>
          <OptimizationBoxItemsList
            title={"Custom Max Bid Per Step"}
            items={items}/>
        </Grid>
      </Grid>
    </OptimizationBox>
  );
}

export default BidDetail;
