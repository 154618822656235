import qs from "qs";
import { isObject, snake } from "radash";
import { audienceUrl, managedPlusUrl } from "./config";

export class APIError extends Error {
  constructor(message, status, details) {
    super(message);
    this.name = "APIError";
    this.status = status;
    this.details = details;
  }
}

function mapParams(params) {
  return Object.fromEntries(
    Object.entries(params)
      .filter(([_, value]) => (value ?? "") !== "")
      .map(([key, value]) => {
        return [snake(key), isObject(value) ? mapParams(value) : value];
      })
  );
}

export function createUrl(service, path, params) {
  const base = {
    player: "",
    audience: audienceUrl,
    managed_plus: managedPlusUrl,
  }[service];

  return `${base}${path}?${qs.stringify(mapParams(params || {}))}`;
}

async function handleResponse(response, options = {}) {
  if (!response.ok) {
    let details;

    try {
      details = await response.json();
    } catch (e) {
      details = { errors: [] };
    }

    throw new APIError(
      "An error occured while fetching the data.",
      response.status,
      details
    );
  }

  return options.responseFormat === "text" ? response.text() : response.json();
}

export async function get(service, path, options = {}) {
  const res = await fetch(createUrl(service, path, options.params), {
    signal: options.signal,
  });

  return handleResponse(res);
}

export async function post(service, path, body, options = {}) {
  const res = await fetch(createUrl(service, path, options.params), {
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    signal: options.signal,
  });

  return handleResponse(res, options);
}

export async function patch(service, path, body, options = {}) {
  const res = await fetch(createUrl(service, path, options.params), {
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
    method: "PATCH",
    signal: options.signal,
  });

  return handleResponse(res);
}

export async function destroy(service, path, options = {}) {
  const response = await fetch(createUrl(service, path, options.params), {
    method: "DELETE",
    signal: options.signal,
  });

  if (!response.ok) {
    throw new APIError(
      "An error occured while fetching the data.",
      response.status,
      { errors: [] }
    );
  }
}
