import {
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RRDLink } from "react-router-dom";
import TableHeadCell from "../../../components/TableHeadCell";
import formatNumber from "../../../utils/formatNumber";
import formatCPM from "../utils/formatCPM";
import humanize from "../utils/humanize";

const TableCell = styled(MUITableCell)({
  overflowWrap: "anywhere",
  wordBreak: "normal",
});

const Link = styled(RRDLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
}));

const SegmentList = ({
  onPageChange: handlePageChange,
  onRowsPerPageChange: handleRowsPerPageChange,
  onSortOrderChange,
  orderBy,
  orderDirection,
  page,
  rowsPerPage,
  segments,
  totalCount,
}) => {
  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableHeadCell
                onSortOrderChange={onSortOrderChange}
                orderBy={orderBy}
                orderDirection={orderDirection}
                name="name"
                title="Segment Key"
              />
              <TableHeadCell
                onSortOrderChange={onSortOrderChange}
                orderBy={orderBy}
                orderDirection={orderDirection}
                name="source"
                title="Source"
              />
              <TableHeadCell
                onSortOrderChange={onSortOrderChange}
                orderBy={orderBy}
                orderDirection={orderDirection}
                name="human_readable_name"
                title="Segment Name"
              />
              <TableHeadCell
                onSortOrderChange={onSortOrderChange}
                orderBy={orderBy}
                orderDirection={orderDirection}
                name="cpm_in_cents"
                title="CPM"
              />
              <TableHeadCell
                onSortOrderChange={onSortOrderChange}
                orderBy={orderBy}
                orderDirection={orderDirection}
                name="provider"
                title="Provider"
              />
              <TableHeadCell
                onSortOrderChange={onSortOrderChange}
                orderBy={orderBy}
                orderDirection={orderDirection}
                name="targeting_count"
                title="Size"
              />
              <TableHeadCell
                onSortOrderChange={onSortOrderChange}
                orderBy={orderBy}
                orderDirection={orderDirection}
                name="segment_type.name"
                title="ID Type"
                helpTooltip="App User ID: Identifier + Ad Network ID"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {segments.map((segment) => (
              <TableRow key={segment.id}>
                <TableCell>
                  <Link to={`/segments/${segment.id}`}>{segment.name}</Link>
                </TableCell>
                <TableCell>{segment.source}</TableCell>
                <TableCell>{segment.human_readable_name}</TableCell>
                <TableCell>{formatCPM(segment.cpm_in_cents)}</TableCell>
                <TableCell>{segment.provider ?? "-"}</TableCell>
                <TableCell>{formatNumber(segment.targeting_count)}</TableCell>
                <TableCell>{humanize(segment.segment_type)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
        SelectProps={{ native: true }}
      />
    </>
  );
};

export default SegmentList;
