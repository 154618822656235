import { Paper } from "@mui/material";
import CampaignsTable from "../features/managed_plus_podcasts/CampaignsTable.js";
import useTitle from "../hooks/useTitle";

const ManagedPlusPodcastCampaigns = () => {
  useTitle("M+ Campaigns > Podcast Campaigns");

  const pendingCampaignsSearchOptions = { kind: "podcast", campaignState: "pending" };
  const allCampaignsSearchOptions = { kind: "podcast" };

  return (
    <>
      <Paper sx={{ p: 3 }}>
        <CampaignsTable
          searchOptions={pendingCampaignsSearchOptions}
          detailColumn={"pending_urls"}
          tableTitle={"Pending Campaigns"}
        />
        <CampaignsTable
          searchOptions={allCampaignsSearchOptions}
          detailColumn={"max_bid"}
          tableTitle={"All Campaigns"}
        />
      </Paper>
    </>
  )
}

export default ManagedPlusPodcastCampaigns;
