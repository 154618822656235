import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import { diff, toggle } from "radash";
import { useState } from "react";

const ItemsList = ({
  items,
  onItemClick: handleItemClick,
  selected,
  testId,
}) => {
  return (
    <Paper sx={{ width: 216, height: 230, overflow: "auto" }}>
      <List dense component="div" role="list" data-testid={testId}>
        {items.map((item) => {
          const labelId = `transfer-list-item-${item.id}-label`;

          return (
            <ListItem
              key={item.id}
              role="listitem"
              button
              onClick={() => handleItemClick(item)}
              id={labelId}
            >
              <ListItemIcon>
                <Checkbox
                  checked={selected.indexOf(item) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

const ContentCategoryTooltip = () => (
  <Tooltip
    title={
      "Select all categories that apply. Content categories will determine which apps and placement types the campaign is eligible to deliver on."
    }
  >
    <Box component={"span"} sx={{ display: "inline-block", height: "100%" }}>
      <InfoIcon
        sx={{ verticalAlign: "middle", color: "rgb(0,0,0, 0.6)", fontSize: 24 }}
      />
    </Box>
  </Tooltip>
);

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);
const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

const ContentCategoriesSelector = ({
  defaultValue: leftList,
  options: rightList,
  error = null,
  helperText = null,
}) => {
  const [selected, setSelected] = useState([]);
  const [left, setLeft] = useState(leftList || []);
  const [right, setRight] = useState(diff(rightList, leftList, (f) => f.id));

  const leftSelected = intersection(selected, left);
  const rightSelected = intersection(selected, right);

  const handleToggle = (value) => setSelected((list) => toggle(list, value));

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleSelectedRight = () => {
    setRight(right.concat(leftSelected));
    setLeft(not(left, leftSelected));
    setSelected(not(selected, leftSelected));
  };

  const handleSelectedLeft = () => {
    setLeft(left.concat(rightSelected));
    setRight(not(right, rightSelected));
    setSelected(not(selected, rightSelected));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  return (
    <FormControl>
      <Typography variant="body2">
        Content Categories <ContentCategoryTooltip />
      </Typography>
      {helperText && (
        <FormHelperText error={error} sx={{ ml: 0 }}>
          {helperText}
        </FormHelperText>
      )}
      <Paper
        variant="outlined"
        sx={{ px: 3, pb: 2, pt: 3, mt: 2, borderRadius: 2.5 }}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <ItemsList
              items={left}
              onItemClick={handleToggle}
              selected={selected}
              testId={"left-list"}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllRight}
                disabled={left.length === 0}
                aria-label="move all right"
              >
                ≫
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleSelectedRight}
                disabled={leftSelected.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleSelectedLeft}
                disabled={rightSelected.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllLeft}
                disabled={right.length === 0}
                aria-label="move all left"
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <ItemsList
              items={right}
              onItemClick={handleToggle}
              selected={selected}
              testId={"right-list"}
            />
          </Grid>
        </Grid>
      </Paper>
      <input
        data-testid="selected-categories"
        name="advertiser_category_ids"
        value={left.map(({ id }) => id).join(",")}
        type="hidden"
      />
    </FormControl>
  );
};

export default ContentCategoriesSelector;
