import { DateTime } from "luxon";

// There's currently ongoing work to make luxon's API better when it
// comes to creating human representations of a `Duration`. Until then,
// we're just going to deal with it ourselves.
// See https://github.com/moment/luxon/issues/1134
function shiftAndHumanize(executionTime) {
  let shiftedDuration;

  if (executionTime.length("minutes") >= 60) {
    shiftedDuration = executionTime.toDuration(["hours", "minutes"]);
  } else if (executionTime.length("seconds") >= 60) {
    shiftedDuration = executionTime.toDuration(["minutes", "seconds"]);
  } else if (executionTime.length("milliseconds") >= 1000) {
    shiftedDuration = executionTime.toDuration(["seconds"]);
  } else {
    shiftedDuration = executionTime.toDuration(["milliseconds"]);
  }

  return shiftedDuration.toHuman({ maximumFractionDigits: 0 });
}

function formatExecutionTime(targetingJob) {
  if (["cancelled", "completed", "discarded"].includes(targetingJob.state)) {
    const time = targetingJob[`${targetingJob.state}_at`];
    return time
      ? shiftAndHumanize(
          DateTime.fromISO(targetingJob.attempted_at).until(
            DateTime.fromISO(time)
          )
        )
      : "Unknown";
  } else if (targetingJob.state === "executing") {
    return shiftAndHumanize(
      DateTime.fromISO(targetingJob.attempted_at).until(DateTime.utc())
    );
  } else {
    return "N/A";
  }
}

export default formatExecutionTime;
