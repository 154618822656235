import {
  Box,
  Grid,
  IconButton,
  Typography
} from "@mui/material";

import { NumericFormat } from "react-number-format";

import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "./TextField"

import { useOptimizationChangesDispatch, useOptimizationChanges } from "../OptimizationsContext";

const BidFormSection = ({ custom_max_bid_per_step }) => {
  const { errors } = useOptimizationChanges();
  const dispatch = useOptimizationChangesDispatch();

  const updateCustomMaxBidPerStep = (value) =>
    dispatch({ action: "offer_attribute_changed", bid: { custom_max_bid_per_step: value }});

  const hideSection = [null, undefined].includes(custom_max_bid_per_step);

  if (hideSection) {
    return;
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>Bid</Typography>
      <Grid
        container
        justifyContent="space-between"
        columnSpacing={2}
        direction="row"
        sx={{ mb: 2 }}
      >
        <Grid item xs={4}>
          <NumericFormat
            customInput={TextField}
            size="small"
            label="Custom Max Bid per Step"
            variant="standard"
            value={custom_max_bid_per_step}
            error={"custom_max_bid_per_step" in errors}
            helperText={errors.custom_max_bid_per_step ?? ""}
            fullWidth
            thousandSeparator
            onValueChange={(values, _sourceInfo) => updateCustomMaxBidPerStep(values.value)}
          />
        </Grid>
        <Grid item xs={7}></Grid>
        <Grid item xs={1}>
          <IconButton aria-label="delete" onClick={() => updateCustomMaxBidPerStep(null)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BidFormSection;

