import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  Grid,
  Input,
  InputAdornment,
  Link,
  MenuItem,
  TextField,
} from "@mui/material";
import { Form, useRouteLoaderData } from "react-router-dom";

const ContactsFilter = ({ filters, onFilterChange }) => {
  const accounts = useRouteLoaderData("accountsList");

  return (
    <Form role={"searchform"}>
      <Grid container sx={{ pt: 4 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControl sx={{ width: 240 }} size={"small"}>
            <TextField
              size="small"
              label="Account"
              name="accountId"
              select
              onChange={(e) => onFilterChange({ accountId: e.target.value })}
              value={!filters.accountId ? "all" : filters.accountId}
            >
              <MenuItem value="all">ALL</MenuItem>
              {accounts.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
            <Link
              underline="none"
              component="button"
              href="#"
              sx={{ textAlign: "right", pt: 1, color: "rgba(0, 0, 0, 0.54)" }}
              onClick={(event) => {
                event.preventDefault();
                onFilterChange({ showArchived: !filters.showArchived });
              }}
            >
              {filters.showArchived ? "Hide" : "Show"} Archived Contacts
            </Link>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: "space-between" }}>
          <FormControl variant="standard">
            <Input
              name="searchTerm"
              value={filters.searchTerm ?? ""}
              onChange={(e) => {
                onFilterChange({ searchTerm: e.target.value });
              }}
              form="search"
              type="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ContactsFilter;
