import { useState, useEffect, useMemo, useCallback, useRef } from "react";

import { get } from "./../../../apiClient.js";

import {
  Chip,
  TextField as MUITextField
} from "@mui/material";

import { styled } from "@mui/material/styles";
import MuiAutocomplete, { createFilterOptions, autocompleteClasses } from "@mui/material/Autocomplete";
import { inputLabelClasses, outlinedInputClasses, inputBaseClasses } from "@mui/material";

// fix for hover issue, see: https://github.com/mui/material-ui/issues/42340
const Autocomplete = styled(MuiAutocomplete)({
  [`& :hover .${autocompleteClasses.input}, .${autocompleteClasses.focused} .${autocompleteClasses.input}`]: {
    minWidth: "30px"
  }
});

const TextField = styled(MUITextField)({
  [`& .${inputLabelClasses.root}.${inputLabelClasses.sizeSmall}`]: {
    fontSize: "1em",
    transform: "translate(14px, 13px) scale(1)"
  },
  [`& .${inputLabelClasses.root}.${inputLabelClasses.sizeSmall}.${inputLabelClasses.shrink}`]: {
    transform: "translate(14px, -8px) scale(0.75)"
  },
  [`& .${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
    lineHeight: "1.5em",
    [`& .${autocompleteClasses.input}`]: {
      lineHeight: "1.5em",
      height: "24px",
      padding: "4px 4px 4px 8px !important",
      margin: "2px 0 2px 0"
    },
  },
});

const DEFAULT_OPTION_VALUE = "all";
const filter = createFilterOptions();

const createOptions = (options) =>
  (options ?? []).map(({ value, label }) => ({ label: label?.toString(), value: value?.toString() }));

const filterSelectedOptions = (list) => {
  const isSelectAll = list.length !== 0 && list[list.length - 1].value === "all"

  return isSelectAll ? list.slice(-1) : list.filter((item) => item.value !== "all")
}

const AutocompleteFilter = ({
  inputProps,
  defaultOptionLabel = "all",
  apiEndpoint,
  onChange,
  multiple = true,
  value: providedValue,
  options: providedOptions,
  ...props
}) => {
  const selectAllOption = useMemo(() => {
    return { label: defaultOptionLabel, value: DEFAULT_OPTION_VALUE };
  }, [defaultOptionLabel]);
  const [value, setValue] = useState(providedValue ?? (multiple ? [selectAllOption] : selectAllOption));
  const [options, setOptions] = useState([]);
  const ref = useRef(null);

  const fetchOptions = useCallback(() => {
    if (providedOptions) {
      return Promise.resolve(
        setOptions([selectAllOption, ...createOptions(providedOptions)])
      )
    }

    const { service, path, query = {} } = apiEndpoint;

    return get(service, path, { params: query }).then((options) => {
      setOptions([selectAllOption, ...createOptions(options)])
    });
  }, [apiEndpoint, selectAllOption, providedOptions])

  useEffect(() => {
    fetchOptions()
  }, [fetchOptions])

  useEffect(() => {
    if (ref && ref.current) {
      const handleEvent = (event) => {
        setValue(multiple ? [selectAllOption] : selectAllOption)
      }

      ref.current.addEventListener("autocompleteReset", handleEvent)

      return () => {
        document.removeEventListener("autocompleteReset", handleEvent)
      }
    }

  }, [ref, selectAllOption, multiple])


  const handleValueChange = multiple ? filterSelectedOptions : (v) => v;
  const handleOpen = (_e) => {
    multiple ? setValue((value) => value.length === 1 && value[value.length - 1].value === "all" ? [] : value) :
               setValue(null)
  }

  return (
    <Autocomplete
      ref={ref}
      value={value}
      options={options}
      isOptionEqualToValue={({ value }, { value: otherValue }) => value === otherValue}
      clearOnBlur
      openOnFocus
      selectOnFocus
      multiple={multiple}
      size="small"
      freeSolo
      filterSelectedOptions
      disableCloseOnSelect
      renderTags={(options, getTagProps) =>
        options.map(({ value, label }, index) => {
          const { onDelete, ...chipProps } = getTagProps({ index });

          if (value === "all") {
            return <Chip {...chipProps} label={label} sx={{ backgroundColor: "transparent" }} />
          }

          return <Chip label={label} {...chipProps} onDelete={onDelete} variant="outlined" />
        })
      }
      onOpen={handleOpen}
      onChange={(e, newValue, reason) => {
        if (reason === "createOption") {
          return;
        }

        const selectedValue = handleValueChange(newValue)

        setValue(selectedValue)
        onChange(e, selectedValue)
      }}
      filterOptions={(options, params) => filter(options, params)}
      renderInput={(params) => {
        return <TextField {...params} {...inputProps} />;
      }}
      {...props}
    />
  )
};

export default AutocompleteFilter;
