import {
  Box,
  Button,
  Grid,
  Paper,
} from "@mui/material";

import { useParams } from "react-router-dom";

import { useState } from "react";

import ForbiddenAlert from "../../components/ForbiddenAlert";

import PacingFormSection from "./components/PacingFormSection";
import InventoryFormSection from "./components/InventoryFormSection";
import AudienceFormSection from "./components/AudienceFormSection";
import BidFormSection from "./components/BidFormSection";
import GeographyFormSection from "./components/GeographyFormSection";
import CreativeFormSection from "./components/CreativeFormSection";

import { useOptimizationChangesDispatch, useOptimizationChanges } from "./OptimizationsContext";

import { construct, listify } from "radash";
import { post } from "../../apiClient";

const SECTION_COMPONENTS = {
  pacing: (section, props) => <PacingFormSection key={section} {...props} />,
  bid: (section, props) => <BidFormSection key={section} {...props} />,
  audience: (section, rules) => <AudienceFormSection key={section} rules={rules} />,
  geography: (section, rules) => <GeographyFormSection key={section} rules={rules} />,
  creative: (section, props) => <CreativeFormSection key={section} {...props} />,
  inventory: (section, props) => <InventoryFormSection key={section} {...props} />
}

const buildSection = (section, props) => section in SECTION_COMPONENTS && SECTION_COMPONENTS[section](section, props)

const RULE_FIELDS = ["pacing.frequency_capping", "pacing.completion_capping", "pacing.slow_down",
                     "audience", "geography", "inventory.custom_site_management", "inventory.device_type"]

const buildChangePayload = ({ removedRuleIds, bid, creative, errors: _errors, ...changes }) => {
  const rules = RULE_FIELDS.reduce((acc, key) => {
    return { ...acc, ...(key in changes && { [key]: changes[key].filter(i => i.changed) }) }
  }, {})
  const ramp_down = changes["pacing.ramp_down"]

  return {
    ramp_down: ramp_down === "true",
    ...(bid && { custom_max_bid_per_step: bid.custom_max_bid_per_step ?? "" }),
    ...(creative && { on_page_load_js: creative.on_page_load_js?.trim() ?? "" }),
    rules: construct(rules),
    removed_rule_ids: removedRuleIds
  }
}

const OptimizationEditForm = ({ onHide }) => {
  const { id: campaignId } = useParams();
  const changes = useOptimizationChanges();
  const dispatch = useOptimizationChangesDispatch();
  const handleReset = (changes) => dispatch({ action: "reset", ...changes });
  const [showForbiddenAlert, setShowForbiddenAlert] = useState(false);
  const [optimizations,] = useState(construct(changes));

  const handleOptimizationEdit = (event) => {
    post("managed_plus", `/campaigns/${campaignId}/optimizations`, buildChangePayload(changes))
      .then(({ optimizations: updatedOptimizations }) => {
        onHide(() => handleReset(updatedOptimizations));
      })
      .catch(error => {
        if (error.status === 403) {
          setShowForbiddenAlert(true);
          return;
        }

        dispatch({ action: "update_failed", ...error.details });
      });
  }

  return (
    <Grid item container direction="column">
      <Paper sx={{ p: 2 }}>
        {listify(construct(changes), buildSection)}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mx: -1 }}>
          <Button
            variant="text"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={handleOptimizationEdit}
          >
            Save
          </Button>
          <Button variant="text" size="small" onClick={() => onHide(() => handleReset(optimizations))}>Cancel</Button>
        </Box>
      </Paper>
      <ForbiddenAlert open={showForbiddenAlert} onClose={() => setShowForbiddenAlert(false)} />
    </Grid>
  )
}

export default OptimizationEditForm;
