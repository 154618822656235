import { DateTime } from "luxon";

// Audience Service responds with timestamps without any time zone. We know that
// the time zone of those timestamps is UTC, so this utility can be used to
// parse such timestamps and convert them to the environment's time zone.
function parseNaiveDateTime(string, toZone = "default") {
  return DateTime.fromISO(string, { zone: "utc" }).setZone(toZone);
}

export default parseNaiveDateTime;
