import { useRouteError } from "react-router-dom";
import RouteError from "../components/RouteError";
import useTitle from "../hooks/useTitle";

export default function Error() {
  const error = useRouteError();
  useTitle("Something went wrong");

  return <RouteError error={error} />;
}
