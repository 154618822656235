import { json, redirect, useLocation } from "react-router-dom";
import { post } from "../apiClient";
import ContactForm from "../features/managed_plus_credits/components/ContactForm";
import useTitle from "../hooks/useTitle";

const action = async ({ request }) => {
  const formData = await request.formData();
  const formEntries = Object.fromEntries(formData);

  try {
    await post("managed_plus", "/contacts", formEntries);

    return redirect("/managed-plus/credits?tab=contacts");
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
};

const NewManagedPlusContact = () => {
  useTitle("M+ Campaign Credits");
  const location = useLocation();
  const contact = location.state?.contactCopiedValues || {};

  return (
    <ContactForm
      action="/managed-plus/credits/contacts/new"
      contact={contact}
    />
  );
};

export { action };
export default NewManagedPlusContact;
