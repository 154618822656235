import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  Form,
  useActionData,
  useNavigate,
  useNavigation,
} from "react-router-dom";

const ContactForm = ({
  contact = {
    id: null,
    account_id: null,
    name: null,
    email: null,
  },
  action,
}) => {
  const navigation = useNavigation();
  const { errors = {}, ...savedRecord } = useActionData() || {};
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: 7.875,
      }}
    >
      <Form
        action={action}
        name="contactForm"
        method={contact.id ? "put" : "post"}
      >
        <Box fullwidth="true" sx={{ mb: 3.875 }}>
          <Typography variant="h5">
            {!!contact.id ? "Edit" : "New"} Contact
          </Typography>
        </Box>
        {savedRecord?.id && (
          <Alert severity="success" sx={{ mb: 1 }}>
            Contact was updated successfully!
          </Alert>
        )}
        {contact.id && (
          <Box fullwidth="true">
            <input type="hidden" name="id" value={contact.id} />
          </Box>
        )}
        {!!contact.id && (
          <Box fullwidth="true" sx={{ pt: 3.75 }}>
            <TextField
              size="small"
              label="Status"
              select
              name="deactivated"
              defaultValue={contact.deactivated}
              sx={{ width: 220 }}
            >
              <MenuItem value="true" key="true">
                Inactive
              </MenuItem>
              <MenuItem value="false" key="false">
                Active
              </MenuItem>
            </TextField>
          </Box>
        )}
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            label="Account ID"
            name="account_id"
            error={"account" in errors}
            helperText={errors["account"]}
            defaultValue={contact.account_id}
            required
            size="small"
            sx={{ width: 220 }}
            variant="standard"
          />
        </Box>
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            id="name"
            name="name"
            label="Contact Name"
            error={"name" in errors}
            helperText={errors["name"]}
            defaultValue={contact.name}
            required
            sx={{ width: 220 }}
            variant="standard"
          />
        </Box>
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            id="email"
            name="email"
            label="Contact Email"
            error={"email" in errors}
            helperText={errors["email"]}
            sx={{ width: 734 }}
            defaultValue={contact.email}
            required
            variant="standard"
          />
        </Box>
        <Box
          sx={{
            pt: 5,
            pl: 7.25,
            pr: 7.25,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LoadingButton
            loading={navigation.state === "submitting"}
            type="submit"
            variant="contained"
            sx={{ width: 288 }}
          >
            {contact.id ? "SAVE" : "CREATE"}
          </LoadingButton>
          <Button
            onClick={() => navigate("/managed-plus/credits?tab=contacts")}
            variant="outlined"
            sx={{ width: 288 }}
          >
            Cancel
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default ContactForm;
