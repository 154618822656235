import {
  Grid,
} from "@mui/material";

import OptimizationBox from "./OptimizationBox";
import OptimizationBoxItemsList from "./OptimizationBoxItemsList";

import { buildTargetingRuleInfoItems } from "../utils/optimizationDetailsUtils";

const GeographyDetail = ({ details: rules }) => {
  return rules.length > 0 && (
    <OptimizationBox panelId={"geography"} title={"Geography"}>
      <Grid container direction="row">
        <Grid item>
          <OptimizationBoxItemsList
            title={"Country"}
            items={buildTargetingRuleInfoItems(rules)} />
        </Grid>
      </Grid>
    </OptimizationBox>
  );
}

export default GeographyDetail;
