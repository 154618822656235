import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const TableCell = styled(MUITableCell)({
  overflowWrap: "anywhere",
  wordBreak: "normal",
  paddingTop: 0,
  paddingBottom: 0,
});

const TableHeadCell = styled(MUITableCell)({
  paddingTop: 6,
  paddingRight: 0,
  paddingBottom: 6,
  paddingLeft: 16,
});

const AdvertiserRow = ({ id, name, type, domain, onEditClick }) => (
  <TableRow hover>
    <TableCell>{name}</TableCell>
    <TableCell>{type}</TableCell>
    <TableCell>{domain}</TableCell>
    <TableCell variant="body" align="right">
      <IconButton aria-label="edit" size="small" onClick={onEditClick(id)}>
        <EditOutlinedIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

const AdvertiserList = ({
  advertisers,
  onPageChange: handlePageChange,
  onRowsPerPageChange: handleRowsPerPageChange,
  page,
  rowsPerPage,
  totalCount,
}) => {
  const navigate = useNavigate();
  const onEditClick = (id) => () => navigate(`/advertisers/${id}/edit`);

  return (
    <Box style={{ style: "width: 100%" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell align="left">Advertiser Name</TableHeadCell>
              <TableHeadCell align="left">Type</TableHeadCell>
              <TableHeadCell align="left">Domain</TableHeadCell>
              <TableHeadCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {advertisers.map((advertiser) => (
              <AdvertiserRow
                onEditClick={onEditClick}
                {...advertiser}
                key={advertiser.id}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
        SelectProps={{ native: true }}
      />
    </Box>
  );
};

export default AdvertiserList;
