/* eslint-disable no-console */

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { setupWorker } from "msw";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import { basePath, routes, theme } from "./config";

// REACT QUERY INITIALIZATION //

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

// MSW INITIALIZATION //

if (process.env.NODE_ENV === "development") {
  if (process.env.REACT_APP_API_MOCK_ENABLED === "true") {
    const { createHandlers, createSeeds } = require("./fakeApi");
    createSeeds();
    const worker = setupWorker(...createHandlers({ delay: 250 }));

    console.log("Starting the worker...", worker);

    try {
      worker.start().then(() => {
        console.log("Worker started:", worker);
      });
    } catch (error) {
      console.error("Failed to start the worker:", error);
    }
  }
}

// REACT CONTAINER INITIALIZATION //

const container = document.getElementById("root");
const root = createRoot(container);
const router = createBrowserRouter(routes, { basename: basePath });

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router}>
          <App />
        </RouterProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  </StrictMode>
);
