import {
  TextField as MUITextField
} from "@mui/material";

import { createTheme, ThemeProvider  } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.body2
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === "small" && {
            fontSize: "0.875rem"
          })
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          "& > legend > span": {
            paddingLeft: "3px",
            paddingRight: "3px"
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === "small" && {
            fontSize: "0.875rem"
          })
        })
      }
    },
    MuiInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === "small" && {
            fontSize: "0.875rem"
          })
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.shrink && ownerState.variant !== "standard" && {
            transform: "translate(12px, -7px) scale(0.75)"
          }),
          ...(ownerState.size === "small" && {
            fontSize: "0.875rem",
          })
        })
      }
    }
  }
})

const TextField = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <MUITextField {...props} />
    </ThemeProvider>
  )
}

export default TextField;
