import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

let theme = createTheme({
  palette: {
    primary: {
      main: "#255C8E",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
});

theme = createTheme(theme, {
  palette: {
    primary1: theme.palette.augmentColor({
      color:  {
        main: "#64B5C5"
      },
      name: "primary1"
    })
  }
});

export default theme;
