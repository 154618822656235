import {
  Grid,
} from "@mui/material";

import OptimizationBox from "./OptimizationBox";
import OptimizationBoxItemsList from "./OptimizationBoxItemsList";

import { buildTargetingRuleInfoItems } from "../utils/optimizationDetailsUtils";

const InventoryDetail = ({ details: { custom_site_management = [], device_type = [] } }) => {
  const adNetworks = custom_site_management.filter(({ value }) => value.target === "ad_network");
  const placements = custom_site_management.filter(({ value }) => value.target === "placement");

  if (custom_site_management.length === 0 && device_type.length === 0) {
    return;
  }

  return (
    <OptimizationBox panelId={"inventory"} title={"Inventory"}>
      <Grid container direction="column">
        {adNetworks.length > 0 && (
          <Grid item>
            <OptimizationBoxItemsList
              title={"Ad Network"}
              items={buildTargetingRuleInfoItems(adNetworks)}
            />
          </Grid>
        )}
        {placements.length > 0 && (
          <Grid item>
            <OptimizationBoxItemsList
              title={"Placement"}
              items={buildTargetingRuleInfoItems(placements)}
            />
          </Grid>
        )}
        {device_type.length > 0 && (
          <Grid item>
            <OptimizationBoxItemsList
              title="Device Type"
              items={buildTargetingRuleInfoItems(device_type)}
            />
          </Grid>
        )}
      </Grid>
    </OptimizationBox>
  );
}

export default InventoryDetail;
