import {
  LinearProgress,
  Link as MUILink,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { get } from "../../apiClient.js";

import { DateTime } from "luxon";

import formatNumber from "../../utils/formatNumber";

import TableHeadCell from "../../components/TableHeadCell";

import { Link as RRDLink } from "react-router-dom";
import TablePagination from "../../components/TablePagination";
import CampaignFilters from "./components/CampaignFilters.js";

const DEFAULT_PAGINATION = { page: 0, perPage: 20 };
const DEFAULT_SEARCH_RESULT = {
  records: [],
  totalCount: 0,
  ...DEFAULT_PAGINATION,
};
const DEFAULT_SORT = { orderDirection: "asc", orderBy: "company_name" };

const formatBudget = (value) =>
  parseFloat(value, 10)
    ? formatNumber(value, { currency: "USD", style: "currency" })
    : "-";
const formatDate = (value) =>
  value &&
  DateTime.fromISO(value).setZone("America/New_York").toFormat("M/dd/yy");

const campaignSearch = ({
  page,
  perPage,
  orderDirection,
  orderBy,
  campaignId,
  accountId,
  fromOfferId,
  ...filters
}) => {
  return new Promise((resolve, reject) => {
    const params = {
      page,
      perPage,
      [`order[${orderBy}]`]: orderDirection,
      id: campaignId?.join(","),
      fromOfferId: fromOfferId?.join(","),
      accountId: accountId?.join(","),
      ...filters,
    };

    get("managed_plus", "/campaigns", { params })
      .then(({ result, total_count }) => {
        resolve({ records: result, totalCount: total_count, page, perPage });
      })
      .catch(reject);
  });
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#1976D214",
  },
}));

const ErrorRow = ({ error }) => {
  return (
    <StyledTableRow>
      <TableCell colSpan={7} sx={{ textAlign: "center" }}>
        {error}
      </TableCell>
    </StyledTableRow>
  );
};

const RLink = styled(MUILink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
}));

const Link = styled(RRDLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
}));

const CampaignsTable = ({
  tableTitle,
  detailColumn = "pending_urls",
  searchOptions = {},
}) => {
  const [filters, setFilter] = useState({
    ...DEFAULT_PAGINATION,
    ...DEFAULT_SORT,
  });
  const [isLoading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState(DEFAULT_SEARCH_RESULT);

  const { records, error, totalCount } = searchResult;

  const handleFilterReset = (contextFilters = {}) =>
    setFilter({ ...DEFAULT_PAGINATION, ...DEFAULT_SORT, ...contextFilters });

  useEffect(() => {
    setLoading(true);
    campaignSearch({ ...searchOptions, include: detailColumn, ...filters })
      .then((result) => setSearchResult(result))
      .catch((error) =>
        setSearchResult({
          error:
            "An error occurred while fetching the data. Please, contact support if the error is persistent.",
        })
      )
      .finally(() => setLoading(false));
  }, [filters, detailColumn, searchOptions]);

  const onFilterChange = (filter) =>
    setFilter((prev) => ({ ...prev, ...filter, page: 0 }));
  const handleSortChange = (_event, order) =>
    setFilter((prev) => ({ ...prev, ...order }));

  const columnTitle =
    detailColumn === "pending_urls" ? "Status" : "Max Bid per Step";
  const columnValue = ({ max_bid, pending_urls }) =>
    detailColumn === "pending_urls"
      ? `${pending_urls} Pending URLs`
      : formatNumber(max_bid, {
          maximumFractionDigits: 6,
          minimumFractionDigits: 6,
        });

  return (
    <>
      <Typography variant="h6">{tableTitle}</Typography>
      <CampaignFilters
        onFilterChange={onFilterChange}
        searchOptions={searchOptions}
        onClearAllFilters={handleFilterReset}
      />
      <TableContainer>
        {isLoading && <LinearProgress />}
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableHeadCell
                title="Company Name"
                name="company_name"
                onSortOrderChange={handleSortChange}
                orderDirection={filters.orderDirection}
                orderBy={filters.orderBy}
              />
              <TableCell>Offer ID</TableCell>
              <TableHeadCell
                title="Campaign Name"
                name="name"
                onSortOrderChange={handleSortChange}
                orderDirection={filters.orderDirection}
                orderBy={filters.orderBy}
              />
              <TableHeadCell
                title="Flight Start"
                name="start_date"
                onSortOrderChange={handleSortChange}
                orderDirection={filters.orderDirection}
                orderBy={filters.orderBy}
              />
              <TableHeadCell
                title="Flight End"
                name="end_date"
                onSortOrderChange={handleSortChange}
                orderDirection={filters.orderDirection}
                orderBy={filters.orderBy}
              />
              <TableCell>{columnTitle}</TableCell>
              <TableHeadCell
                title="Lifetime Budget"
                name="lifetime_budget"
                onSortOrderChange={handleSortChange}
                orderDirection={filters.orderDirection}
                orderBy={filters.orderBy}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {error ? (
              <ErrorRow error={error} />
            ) : (
              records.map((campaign) => (
                <StyledTableRow key={campaign.id}>
                  <TableCell>
                    <RLink
                      underline="hover"
                      href={`/admin/managed_plus_accounts/${campaign.account_id}`}
                    >
                      {campaign.company_name}
                    </RLink>
                  </TableCell>
                  <TableCell>{campaign.offer_id}</TableCell>
                  <TableCell>
                    <Link to={`/managed-plus/podcast-campaigns/${campaign.id}`}>
                      {campaign.name}
                    </Link>
                  </TableCell>
                  <TableCell>{formatDate(campaign.start_date)}</TableCell>
                  <TableCell>{formatDate(campaign.end_date)}</TableCell>
                  <TableCell>{columnValue(campaign)}</TableCell>
                  <TableCell>
                    {formatBudget(campaign.lifetime_budget)}
                  </TableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
        {!error && (
          <TablePagination
            totalCount={totalCount}
            filters={filters}
            setFilter={setFilter}
          />
        )}
      </TableContainer>
    </>
  );
};

export default CampaignsTable;
