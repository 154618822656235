import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { debounce } from "radash";
import { useEffect, useMemo, useState } from "react";
import { Form, useNavigate, useNavigation, useSubmit } from "react-router-dom";
import { SEARCH_DELAY } from "./constants";

const AdvertiserListHeader = ({ advertiserTypes, filters }) => {
  const navigate = useNavigate();
  const submit = useSubmit();
  const submitDebounced = useMemo(
    () => debounce({ delay: SEARCH_DELAY }, submit),
    [submit]
  );
  const navigation = useNavigation();
  const [searchTerm, setSearchTerm] = useState(filters.search ?? "");
  const [advertiserType, setAdvertiserType] = useState(filters.type ?? "");

  useEffect(() => {
    if (navigation.state === "loading") {
      const searchParams = new URLSearchParams(navigation.location.search);
      setSearchTerm(searchParams.get("search") ?? "");
      setAdvertiserType(searchParams.get("type") ?? "");
    }
  }, [navigation.state, navigation.location]);

  return (
    <Form
      data-testid={"advertiser-searchform"}
      method="get"
      onChange={(e) => {
        const isFirstSearch =
          filters.search === undefined && filters.type === undefined;

        e.target.name === "search"
          ? submitDebounced(e.currentTarget, { replace: !isFirstSearch })
          : submit(e.currentTarget, { replace: !isFirstSearch });
      }}
    >
      <Grid container sx={{ pt: 4 }}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box sx={{ alignSelf: "center", px: 2 }}>
            <Input
              name="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              type="search"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
          </Box>
          <FormControl size={"small"}>
            <Box sx={{ width: "100%", pb: 2 }}>
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => navigate("/advertisers/new")}
              >
                New Advertiser
              </Button>
            </Box>
            <TextField
              size="small"
              label="Type"
              name="type"
              select
              onChange={(e) => {
                setAdvertiserType(e.target.value);
                submit({ ...filters, type: e.target.value });
              }}
              value={advertiserType}
            >
              <MenuItem value="all">All</MenuItem>
              {advertiserTypes.map(({ value, text }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AdvertiserListHeader;
