import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField, Typography } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { useState } from "react";
import {
  Form,
  json,
  redirect,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { post } from "../apiClient";
import { convertToDate } from "../features/managed_plus_credits/utils/dateFormatUtils.js";

const idsFromQueryParams = (request) => {
  return Array.from(new URL(request.url).search.matchAll(/ids\[\]=\d+/g)).map(
    ([item]) => item.split("=")[1]
  );
};

const action = async ({ request }) => {
  const formData = await request.formData();
  const formEntries = Object.fromEntries(formData);
  if (formEntries.date) {
    formEntries.date = convertToDate(formEntries.date).toSQLDate();
  }
  formEntries.ids = idsFromQueryParams(request);

  try {
    await post("managed_plus", "/credits/bulk_edit", formEntries);

    return redirect("/managed-plus/credits?tab=credits");
  } catch (error) {
    switch (error.status) {
      case 400:
      case 422: {
        return json(error.details, { status: error.status });
      }
      default:
        throw error;
    }
  }
};

const MONTH_LIMIT_TO_LOOK_BACK = 2;

const BulkEditManagedPlusCredit = () => {
  const navigation = useNavigation();
  const [date, setDate] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const minDateToLookBack = DateTime.now()
    .plus({ month: -MONTH_LIMIT_TO_LOOK_BACK })
    .toISODate();

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: 7.875,
      }}
    >
      <Form
        action={`/managed-plus/credits/bulk-edit${window.location.search}`}
        name="creditBulkEditForm"
        method={"post"}
      >
        <Box fullwidth="true" sx={{ mb: 3.875 }}>
          <Typography variant="h5">Bulk Edit Credit</Typography>
        </Box>
        <Box fullwidth="true">
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              inputFormat={"MM/dd/yy"}
              value={date}
              disableFuture={true}
              open={open}
              onClose={(e) => setOpen(false)}
              onChange={(value) => setDate(value)}
              sx={{ padding: 0, height: 32 }}
              minDate={minDateToLookBack}
              renderInput={({ inputRef, inputProps }) => (
                <TextField
                  id="creditDate"
                  name="date"
                  label="Credit Date"
                  color={"primary"}
                  variant="outlined"
                  ref={inputRef}
                  size="small"
                  sx={{
                    width: 220,
                  }}
                  onClick={(e) => setOpen(true)}
                  inputProps={{ ...inputProps }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            id="amount"
            name="amount"
            label="Credit Amount"
            inputProps={{
              inputMode: "numeric",
              pattern: "^\\d+([.]\\d{1,2})?$",
              title: "use a decimal value. e.g 100.56",
            }}
            sx={{ width: 220 }}
            variant="standard"
          />
        </Box>
        <Box fullwidth="true" sx={{ pt: 3.75 }}>
          <TextField
            id="reason"
            name="reason"
            label="Credit Reason"
            sx={{ width: 734 }}
            variant="standard"
            type="text"
          />
        </Box>
        <Box
          sx={{
            pt: 5,
            pl: 7.25,
            pr: 7.25,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LoadingButton
            loading={navigation.state === "submitting"}
            type="submit"
            variant="contained"
            sx={{ width: 288 }}
          >
            Save
          </LoadingButton>
          <Button
            onClick={() => navigate("/managed-plus/credits")}
            variant="outlined"
            sx={{ width: 288 }}
          >
            Cancel
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export { action };
export default BulkEditManagedPlusCredit;
