import { DateTime } from "luxon";
import formatDateTime from "../../../utils/formatDateTime";

function formatStateDateTime(targetingJob) {
  switch (targetingJob.state) {
    case "cancelled":
      return formatDateTime(DateTime.fromISO(targetingJob.cancelled_at));
    case "completed":
      return formatDateTime(DateTime.fromISO(targetingJob.completed_at));
    case "discarded":
      return formatDateTime(DateTime.fromISO(targetingJob.discarded_at));
    case "executing":
      return formatDateTime(DateTime.fromISO(targetingJob.attempted_at));
    default:
      return "N/A";
  }
}

export default formatStateDateTime;
