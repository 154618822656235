import { Grid, Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { pick } from "radash";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import AppError from "../AppError";
import { fetchPublishers } from "../features/publishers/apiService";
import PublisherDataGrid from "../features/publishers/components/PublisherDataGrid";
import PublisherFiltering from "../features/publishers/components/PublisherFiltering";
import useTitle from "../hooks/useTitle";

const FILTER_PARAMS = ["search"];

const PAGINATION_DEFAULTS = {
  per_page: "25",
  page: "1",
  order_by: "id",
  order_direction: "asc",
};

function searchParamsToApiParams(searchParams) {
  const params = Object.fromEntries(searchParams);

  return {
    ...pick(params, FILTER_PARAMS),
    ...PAGINATION_DEFAULTS,
    ...pick(params, Object.keys(PAGINATION_DEFAULTS)),
  };
}

const Publishers = () => {
  useTitle("PreBid Placement Configuration");

  const [searchParams, setSearchParams] = useSearchParams(PAGINATION_DEFAULTS);
  const params = searchParamsToApiParams(searchParams);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: Number(params["per_page"]),
    page: Number(params["page"] - 1),
  });

  const queryKey = ["publishers", params];

  const query = useQuery({
    queryKey: queryKey,
    queryFn: fetchPublishers,
  });

  let publishers = query.data ? query.data["publishers"] : [];
  let total_count = query.data ? query.data["total_count"] : 0;
  const filters = pick(params, FILTER_PARAMS);

  if (query.isError) {
    return <AppError error={query.error} />;
  }

  /////////// Pagination Logic ///////////

  const onPaginationModelChange = (paginationModel) => {
    const newSearchParams = {
      ...Object.fromEntries(searchParams),
      per_page: paginationModel.pageSize,
      page: paginationModel.page + 1,
    };

    setSearchParams(newSearchParams);
    setPaginationModel(paginationModel);

    const newParams = {
      ...params,
      per_page: paginationModel.pageSize.toString(),
    };

    queryKey[1] = newParams;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <PublisherFiltering filters={filters} />
          <PublisherDataGrid
            publishers={publishers}
            page={paginationModel.page}
            pageSize={paginationModel.pageSize}
            rowCount={total_count}
            setSearchParams={setSearchParams}
            onPaginationModelChange={onPaginationModelChange}
            paginationModel={paginationModel}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Publishers;
